<script lang="ts" setup>
import { ref, reactive, type Ref } from 'vue';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Lazy, Pagination, Autoplay } from "swiper";
import 'swiper/scss';
import 'swiper/scss/pagination';
import { DEFAULT_CONFIG, type BANNER_ITEM } from "@/helper/banner"
import { getBanner } from "@/api/public";
import Loading from '@/components/common/Loading/Loading.vue';
import { gtagEventBanner } from '@/helper/global.method';

const modules = reactive([Pagination, Lazy, Autoplay]);
const $emit = defineEmits(["swiperChange"]);
const $props = defineProps<{ bannerType: 'community.banner' | 'main_page.banner' | 'task_center.banner' | 'mysterybox.banner' }>()
const swiperCount = ref(0); // # 当前swiper计数
const ControlledSwiper = ref(null); // # swiper实例
const inited = ref(false); // # 是否初始化
const swiperData: Ref<BANNER_ITEM[]> = ref([]);

// # 设置swiper的Contorller
const setControlledSwiper = (swiper: any) => {
    ControlledSwiper.value = swiper
};
// # swiper切换事件
const slideChange = (swiper: any) => {
    let index = swiper.activeIndex;
    swiperCount.value = index;
    $emit('swiperChange', index);
};
defineExpose({ controlledSwiper: ControlledSwiper });

async function init() {
    inited.value = false;
    try {
        const { data: { data: { items: bannerList } } } = await getBanner($props.bannerType);
        swiperData.value = bannerList;
    } finally {
        inited.value = true;
    }
}
function changeIndex(index: number) {
    ControlledSwiper.value.slideTo(index);
}

function gtagBannerItem(url: string) {
    if ($props.bannerType === 'mysterybox.banner') {
        gtagEventBanner('mysteryboxes_banner', swiperCount.value)
    } else if ($props.bannerType === 'community.banner') {
        gtagEventBanner('communities_banner', swiperCount.value)
    } else if  ($props.bannerType === 'task_center.banner') {
        gtagEventBanner('task_center_banner', swiperCount.value)
    } else if ($props.bannerType === 'main_page.banner') {
        gtagEventBanner('home_banner', swiperCount.value)
    }
}    

function bannerGoto(url: string, target: string) {
    gtagBannerItem(url)
    if(url){
        window.open(url, target ? `_${target}` : '_blank');
    }
}

init();
</script>

<template>
    <div v-if="inited" class="center_banner_view">
        <Swiper class="center_banner swiper-no-swiping" :modules="modules" v-bind="DEFAULT_CONFIG"
            @swiper="setControlledSwiper" @slideChange="slideChange">
            <SwiperSlide v-for="(obj, index) in swiperData" :key="index" class="banner_main" :class="{'pointer':obj.link_url}">
                <img :src="obj.image_url" alt="" @click="bannerGoto(obj.link_url, obj?.link_open_mode?.mode)">
            </SwiperSlide>
        </Swiper>
        <div class="pagination">
            <div class="pagination-item" v-for="(item, index) in swiperData" :key="index"
                :class="{ 'pagination-item-active': swiperCount === index }" @click="changeIndex(index)"></div>
        </div>
    </div>
    <div v-else class="center_banner_loading">
        <Loading></Loading>
    </div>
</template>

<style lang="scss" scoped>
.center_banner_view {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
}

.center_banner_loading {
    height: 502px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center_banner {
    width: 100%;

    :deep(.swiper-pagination) {
        position: absolute;
        bottom: 20px;
        top: unset;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        width: 100%;
        gap: 12px;
        justify-content: center;
    }

    :deep(.swiper-pagination-bullet) {
        width: 24px;
        height: 4px;
        background: #5F6672;
        opacity: 1;
        border-radius: 2px;
        transition: width 0.1s;

        &.swiper-pagination-bullet-active {
            width: 40px;
            background-color: #83F05F;
        }
    }
}


:deep(.swiper-slide) {
    position: relative;
    width: 100% !important;
}

.v-enter-active {
    transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}


.banner_main {
    img {
        background-color: #000;
        width: 100%;
        height: 502px;
        display: block;
        object-fit: cover;
    }
}

@media screen and (max-width: 750px) {
    .banner_main {
        img {
            height: auto;
        }
    }
}
</style>