<template>
    <div class="taskListWrap" v-if="filtersOptions.tabList.length">
        <PageTitle>{{ title }}</PageTitle>

        <div class="filterWrap">
            <div class="filter-item">
                <FilterTab :tab-list="filtersOptions.tabList" @on-change="onFilterTabChange"></FilterTab>
            </div>
            <div class="filter-item">
                <Search @search="onInputSearch" class="search" placeholder="Search Tasks"></Search>
            </div>
            
            <div class="filter-item">
                <Select :options="filtersOptions.rewardOptions" :value="rewardVal" @change="onRewardChange"></Select>
                <Select :options="filtersOptions.statusOptions" :value="statusVal" @change="onStatusChange"></Select>
                <Select :options="filtersOptions.sortOptions" :value="sortVal" @change="onSortChange"></Select>
            </div>
            
        </div>

        <LayoutList class="listWrap" :list="list" :loading="loading" @loadMore="onLoadMore" :moreLoading="moreLoading">
            <div class="taskList">
                <router-link :to="`/task/details/${item.quest_id}/${getFormatName(item.name)}`" v-for="(item, index) in list.list" :key="index" target="_blank">
                    <TaskCard :item="item" :countItem="countDownArr.find(o => o.id === item.quest_id)"
                        @update-count="onUpdateCount" :gtag-type="gtagType" @click="gtagEventItem(gtagType, index, item.quest_id)" />
                </router-link>
            </div>
        </LayoutList>
    </div>
    <div class="taskListWrap flex-center" v-else>
        <Loading />
    </div>
</template>
<script lang='ts' setup>
import PageTitle from '@/components/PageTitle.vue';
import FilterTab from '@/components/FilterTab.vue';
import Search from '@/components/Search.vue';
import Select from '@/components/Select.vue';
import LayoutList from '@/components/LayoutList.vue'
import TaskCard from './TaskCard.vue';
import {getFormatName} from '@/utils/common'

import { ICountItem, getFilters } from '../TaskCenter.help'
import { listDataFormat } from '@/utils/common';
import { onMounted, reactive, ref, watch, onBeforeUnmount } from 'vue';
import { IFilterBasic, getQuestsFilters, getQuestsList } from '@/api/taskCenter';
import { STATUS_ENDED, STATUS_ONGOING, STATUS_SCHEDULED } from '../Task.text';
import Loading from '@/components/common/Loading/Loading.vue'
import { countInterval } from './countDown';
import { gtagEvent, gtagEventItem, gtagSearch } from '@/helper/global.method';
import { useStore } from 'vuex';

const list = reactive(listDataFormat({ limit: 8, offset: 1 }))
const loading = ref(true)
const moreLoading = ref(false)
const filterTabVal = ref('')
const searchVal = ref('')
const rewardVal = ref('')
const statusVal = ref('')
const sortVal = ref('')
const countDownArr = ref([])
const timer = ref(null)
const store = useStore()
// 筛选 联动
let statusFilter: any = reactive({})
const filtersOptions = reactive({
    tabList: [],
    statusOptions: [],
    sortOptions: [],
    rewardOptions: []
})

const { title, id, gtagType } = defineProps({
    title: {
        type: String,
        default: ''
    },
    id: {
        type: Number,
        default: 0
    },
    gtagType: {
        type: String,
        default: ''
    }
})

// 可多选
const onFilterTabChange = (item) => {
    const realItems = item.filter(o => o.name !== 'All')
    filterTabVal.value = realItems.map(o => o.value).join(',')
    getList()
}

const onInputSearch = (val) => {
    searchVal.value = val
    gtagSearch('tasks', val)
    getList()
}

const onStatusChange = (item) => {
    setDefaultSortItem(item.value)
    statusVal.value = item.value
    getList()
}

const onSortChange = (item) => {
    sortVal.value = item.value
    getList()
}

const onRewardChange = (item) => {
    rewardVal.value = item.value
    getList()
}

const getList = (params?) => {
    if (!params && !params?.offset) {
        loading.value = true
        countDownArr.value.length = 0
    }
    getQuestsList({
        limit: list.limit,
        platform_code: filterTabVal.value,
        name_prefix: searchVal.value,
        status: statusVal.value,
        order_by: sortVal.value,
        community_id: id ? id : '',
        reward_method: rewardVal.value,
        ...params
    }).then(res => {
        moreLoading.value = false
        loading.value = false
        const data = res.data.data
        store.commit('globalLoading', false)
        // 如果不传参数，则代表是当前刚进入页面，则把数组清空

        if (!params || !params.offset) {
            list.list.length = 0
            list.offset = 1
        }
        if (data.items) list.list.push(...data.items)
        list.hasMore = data.has_more
    }).catch(err => {
        loading.value = false
        store.commit('globalLoading', false)
    })
}

// 获取筛选列表
const getFilter = () => {
    getQuestsFilters().then(res => {
        const filters = res.data.data.filters

        const platformFilter = filters.find(o => o.type === 'MULTIPLE')
        statusFilter = filters.find(o => o.type === 'SINGLE' && o.code === 'status')
        const rewardFilter = filters.find(o => o.type === 'SINGLE' && o.code === 'reward_method')

        // 设置reward options
        filtersOptions.rewardOptions.push(...getFilters(rewardFilter.items, rewardFilter.code))
        // 设置tablist options
        filtersOptions.tabList.push(...getFilters(platformFilter.items, platformFilter.code))
        // 设置状态 options
        filtersOptions.statusOptions.push(...getFilters(statusFilter.items, statusFilter.code))
        // 设置默认选中的status
        const defaultStatus = filtersOptions.statusOptions.find(o => o.isDefault)
        statusVal.value = defaultStatus.value
        // 设置默认选中的sort
        setDefaultSortItem(defaultStatus.value)

        getList()
    })
}

// 设置默认选中的sort
const setDefaultSortItem = (code: string) => {
    filtersOptions.sortOptions = getSortItems(code)

    const defaultSort = filtersOptions.sortOptions.find(o => o.isDefault)
    sortVal.value = defaultSort ? defaultSort.value : ''
}

// 获取sort的items
const getSortItems = (code: string) => {
    const filterSort = statusFilter.items.find(o => o.code === code)
    return getFilters(filterSort.child.items, filterSort.code)
}

const onLoadMore = () => {
    list.offset += 1
    moreLoading.value = true
    const params = {
        offset: (list.offset - 1) * list.limit,
    }
    gtagEvent('view_more_tasks')
    getList(params)
}

// 倒计时
const countDownFn = () => {
    if (timer.value) return
    timer.value = setInterval(() => {
        countDownArr.value = countDownArr.value.filter(o => o.count > 0)
        countInterval(countDownArr.value, list.list)
    }, 1000)
}

// 如果有符合倒计时条件的
const onUpdateCount = (item: ICountItem) => {
    if (!countDownArr.value.some(o => o.id === item.id)) {
        countDownArr.value.push(item)
    }
    countDownFn()
}

onMounted(() => {
    getFilter()
    // getList()
})
onBeforeUnmount(() => {
    timer.value = null
    clearInterval(timer.value)
})
</script>
<style lang='scss' scoped>
.listWrap {
    min-height: 500px;
}

.taskListWrap {
    min-height: 550px;
    width: 1280px;
    margin: 48px auto 0;

    .taskList {
        display: flex;
        flex-wrap: wrap;
        // padding-top: 8px;
        gap: 16px;
        justify-content: flex-start;
    }
}

.filterWrap {
    display: flex;
    gap: 14px;
    margin: 24px 0;

    .search {
        width: 100%;
    }
    
    .filter-item {
        display: flex;
        align-items: center;
        &:nth-child(2) {
            flex-grow: 1;
        }
        &:last-child {
            gap: 14px;
        }
    }
}

@media screen and (max-width: 750px) {
    .listWrap {
        padding: 0 16px;
    }
    .taskListWrap {
        width: 100%;
        margin: 16px auto 0;
    }
    .filterWrap {
        margin: 16px 0;
        padding-left: .426667rem /* 16/37.5 */;
        flex-direction: column;
        .filter-item{
            padding-right: 16px;
            &:first-child {
                width: 100%;
                overflow-x: auto;
            }
            &:nth-child(2) {
            }
            &:last-child {
                gap: 8px;
                justify-content: space-between;
            }
        }
    }
}
</style>