<template>
  <div class="logoWrap">
    <div class="sidebarSwitch" @click="onClick">
      <i class="iconSwitch" :class="{'close': props.type === 'close'}"></i>
    </div>
    <div class="logo" @click="onGoHome"></div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router"
const props = defineProps({
  type: {
    type: String,
    default: 'open'
  }
})
const emits = defineEmits(['switchClick']);

const router = useRouter()

const onClick = () => {
  emits('switchClick');
};
const onGoHome = () => {
  // router.push('/')
  window.location.href = `https://www.cassava.network/`
}
</script>

<style lang="scss" scoped>
.logoWrap {
  display: flex;
  align-items: center;
}
.slidebarSwitch {
  width: .64rem /* 24/37.5 */;
  height: .64rem /* 24/37.5 */;
  .iconSwitch {
    display: block;
    width: 100%;
    height: 100%;
    background: url('@/assets/images/mobile/slidebarIcon.svg') no-repeat center
      center / 100% 100%;
    &.close {
      background: url('@/assets/images/mobile/slidebarIconClose.svg') no-repeat center
      center / 100% 100%;
    }
  }
}
.logo {
  width: 0.533333rem /* 20/37.5 */;
  height: 0.533333rem /* 20/37.5 */;
  background: url('@/assets/images/mobile/logo.png') no-repeat center center /
    100% 100%;
  cursor: pointer;
}

@media screen and (min-width: 750px) {
  .sidebarSwitch {display: none;}
  .logo {
    width: 180px;
    height: 40px;
    background: url('@/assets/images/logo-header.png') no-repeat center center /
    100% 100%;
  }
}
</style>