import { RouteRecordRaw } from 'vue-router'
import { IsMobile } from '@/utils/common'
import { ADS_PAGE as ADS_PAGE_ROUTERS } from './ads.router'
import { RULES_PAGE } from "./rules.router"
import { BUSINESS_PARTNER_ROUTERS } from "./business_partner.routers"
import { MOBILE_BACKGROUND_COLOR } from '@/helper/global.text'
export const constantRouterMap: RouteRecordRaw[] = [
    // {
    //   path: '/',
    //   redirect: '/Home'
    // },
    {
        path: '/',
        name: 'Home',
        component: () => import('/src/views/homeNew/Home.vue'),
        meta: {
            title: 'Home',
            isRedirect: true, // 是否允许登录后重新跳转回当前页面
            hideHeaderPadding:true,
            analytics_view: 'home_view',
            opacity: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    },
    {
        path: '/email_login',
        name: 'EmailLogin',
        component: () => import('/src/views/user/login/Login.vue'),
        meta: {
            title: 'Login',
            mobileWhiteList: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('/src/views/user/register/Register.vue'),
        meta: {
            title: 'Register',
            mobileWhiteList: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    },
    {
        path: "/signup",
        name: "Signup",
        component: () => import("/src/views/user/signup/index.vue"),
        meta: {
            title: "Signup",
            analytics_view: 'setting_nickname_view',
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    },
    {
        path: '/sendCode/:code', // 修改密码
        name: 'SendCode',
        component: () => import('/src/views/user/sendCode/SendCode.vue'),
        meta: {
            title: 'Send code',
            analytics_view: 'send_code_view',
            mobileWhiteList: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    },
    {
        path: '/setPassword', // 注册 设置密码
        name: 'SetPassword',
        component: () => import('/src/views/user/password/SetPassword.vue'),
        meta: {
            title: 'Set Password',
            analytics_view: 'set_password_view',
            mobileWhiteList: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    },
    {
        path: '/resetPassword', // 重置密码
        children: [
            {
                path: '',
                name: 'ResetPassword',
                component: () => import('/src/views/user/password/reset/ResetPassword.vue'),
                meta: {
                    title: 'Reset Login Password',
                    analytics_view: 'reset_login_password_view',
                    mobileWhiteList: true,
                    setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
                },
            },
            {
                path: 'confirm', // 重置密码 确认密码
                name: 'ResetLoginPassword',
                component: () => import('/src/views/user/password/reset/ResetLoginPassword.vue'),
                meta: {
                    title: 'Reset Login Password',
                    analytics_view: 'reset_login_password_confirm_view',
                    mobileWhiteList: true,
                    setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
                },
            }
        ]
    },
    // avatar相关
    {
        path: '/myAvatar',
        name: 'MyAvatar',
        component: () => import('/src/views/avatars/myAvatar/MyAvatar.vue'),
        meta: {
            needManuallyCloseLoading: true,
            title: 'My Avatar',
            isRedirect: true, // 是否允许登录后重新跳转回当前页面
            analytics_view: 'my_avatar_view',
            hideHeaderPadding: IsMobile(),
        }
    },
    {
        path: '/collection',
        name: 'Collection',
        component: () => import('/src/views/collection/Collection.vue'),
        meta: {
            title: 'Collection',
            isRedirect: true, // 是否允许登录后重新跳转回当前页面
            analytics_view: 'collection_view'
        }
    },
    {
        path: '/task',
        redirect: '/task/taskCenter',
        children: [
            {
                path: 'taskCenter',
                name: 'TaskCenter',
                component: () => import('/src/views/taskCenter/TaskCenter.vue'),
                meta: {
                    title: 'Task Center',
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'tasks_center_view',
                    hideHeaderPadding: IsMobile(),
                },
            },
            {
                path: 'details/:id/:name?', // 任务详情页
                name: 'TaskDetails',
                component: () => import('/src/views/taskCenter/details/Details.vue'),
                meta: {
                    title: 'Task Details',
                    needManuallyCloseLoading: true,
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'task_detail_view',
                    is_app_analysis: true, // 是否需要app上报
                    hideHeaderPadding: IsMobile(),
                },
            },
            {
                path: 'app/details/:id/:name?',
                name: 'TaskDetailsMobile',
                component: () => import('/src/views/app/tasks/detailsV2/Details.vue'),
                meta: {
                    title: 'Task Details',
                    needManuallyCloseLoading: true,
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'task_detail_view',
                    is_app_analysis: true, // 是否需要app上报
                    hideHeaderPadding: true,
                }
            }
        ]
    },
    {
        path: '/howToPlay',
        name: 'HowToPlay',
        component: () => import('/src/views/avatars/howToPlay/HowToPlay.vue'),
        meta: {
            title: 'How To Play',
            needManuallyCloseLoading: true,
            analytics_view: 'how_to_play_view',
            is_app_analysis: true, // 是否需要app上报
        }
    },
    {
        path: '/mysteryBox',
        children: [
            {
                path: '',
                name: 'MysteryBoxes',
                component: () => import('/src/views/mysteryBox/MysteryBoxV2.vue'),
                meta: {
                    title: 'Mystery Boxes',
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    hideHeaderPadding: true,
                    analytics_view: 'mysteryboxes_view'
                },
            },
            {
                path: 'details/:id', // 盲盒详情页
                name: 'MysteryBoxesDetails',
                component: () => import('/src/views/mysteryBox/details/Details.vue'),
                meta: {
                    title: 'Mystery Boxes Details',
                    needManuallyCloseLoading: true,
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'mysterybox_detail_view',
                    is_app_analysis: true, // 是否需要app上报
                },
            },
            {
                path: 'activity/:id', // 盲盒详情页
                name: 'FIFA2022Activity',
                component: () => import('/src/views/mysteryBox/activity/Activity.vue'),
                meta: {
                    title: 'Activity',
                    needManuallyCloseLoading: true,
                    analytics_view: 'mysterybox_activity_view',
                    is_app_analysis: true, // 是否需要app上报
                },
            }
        ]
    },
    {
        path: '/boundResult',
        name: 'BoundResult',
        component: () => import('/src/views/common/BoundResult.vue'),
        meta: {
            title: 'Bound Result',
            analytics_view: 'bound_result_view',
            mobileWhiteList: true,
            noNeedLogin: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR
        },
    }, {
        path: "/communities",
        name: "Communities",
        component: () => import("/src/views/communityPage/communities/index.vue"),
        meta: {
            title: "Communities",
            isRedirect: true, // 是否允许登录后重新跳转回当前页面
            analytics_view: 'communities_view'
        }
    }, {
        path: "/community/:name?",
        name: "CommunityDetail",
        component: () => import("/src/views/communityPage/communityDetail/index.vue"),
        meta: {
            title: "Community",
            isRedirect: true, // 是否允许登录后重新跳转回当前页面
            analytics_view: 'community_detail_view'
        }
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: () => import('/src/views/common/LandingPage.vue'),
        meta: {
            title: 'Landing Page',
            hideHeaderPadding: true,
            analytics_view: 'landing_pange_view',
            noNeedLogin: true
        },
    },
    {
        path: '/invite',
        name: 'Invite',
        component: () => import('/src/views/common/Invite/index.vue'),
        meta: {
            title: 'invite',
            hideHeaderPadding: true,
            analytics_view: "h5_newbie_tasks_view",
            mobileWhiteList: true
        },
    },
    {
        path: '/invite_finish',
        name: 'InviteFinish',
        component: () => import('/src/views/common/Invite/InviteFinish.vue'),
        meta: {
            title: 'invite',
            hideHeaderPadding: true,
            analytics_view: "h5_download_page_view",
            mobileWhiteList: true
        },
    },
    {
        path: '/app_bind_telegram',
        name: 'appBindTelegram',
        component: () => import('/src/views/app/AppBindTelegram.vue'),
        meta: {
            title: 'Bind Telegram',
            hideHeaderPadding: true,
            clearLoginStatus: true,
            // setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    }, {
        path: '/app_leaderboard/:id?',
        name: 'AppLeaderboard',
        component: () => import('/src/views/app/AppLeaderboard/AppLeaderboard.vue'),
        meta: {
            title: 'Referral Competition',
            analytics_view: 'app_leader_board_view',
            needManuallyCloseLoading: true,
            is_app_analysis: true, // 是否需要app上报
            hideHeaderPadding: IsMobile()
        }
    }, {
        path: '/app_leaderboard_rules',
        name: 'AppLeaderboardRules',
        component: () => import('/src/views/app/Rules/AppLeaderboardRules.vue'),
        meta: {
            title: 'Referral Competition Rules',
            hideHeaderPadding: IsMobile(),
        }
    }, {
        path: '/app_trust_circle_rules',
        name: 'AppTrustCircleRules',
        component: () => import('/src/views/app/Rules/appTrustCircleRules.vue'),
        meta: {
            title: 'Trust Circle Rules',
            hideHeaderPadding: IsMobile(),
        }
    }, {
        path: '/app_invite_rules',
        name: 'appInviteRules',
        component: () => import('/src/views/app/Rules/appInviteRules.vue'),
        meta: {
            title: 'Invitation Rules',
            hideHeaderPadding: true,
            needManuallyCloseLoading: true,
        }
    }, {
        path: "/app_move_to_earn_rules",
        name: 'AppMoveToEarnRules',
        component: () => import('/src/views/app/Rules/AppMoveToEarnRules.vue'),
        meta: {
            title: 'Rules',
            hideHeaderPadding: true,
            analytics_view: "app_move_to_earn_rules",
        }
    }, {
        path: "/app_quiz_rules",
        name: "AppQuizRules",
        component: () => import('/src/views/app/Rules/AppQuizRules.vue'),
        meta: {
            title: "Rules",
            hideHeaderPadding: true,
            analytics_view: "app_quiz_rules",
        }
    }, {
        path: "/app_coins_leaderboard/:id?",
        name: "AppCoinsLeaderboard",
        component: () => import('/src/views/app/AppCoinsLeaderboard/AppCoinsLeaderboard.vue'),
        meta: {
            title: "Coins Leaderboard",
            hideHeaderPadding: true,
            analytics_view: "app_coins_leaderboard",
        }
    },
    {
        path: "/app_coins_leaderboard_rules",
        name: "AppCoinsLeaderboardRules",
        component: () => import('/src/views/app/Rules/AppCoinsLeaderboardRules.vue'),
        meta: {
            title: "Coins Leaderboard",
            hideHeaderPadding: true,
            analytics_view: "app_coins_leaderboard_rules",
        }
    },
    {
        path: "/quiz/landing",
        name: "QuizWebLanding",
        component: () => import('/src/views/app/Quiz/category/CategoryWeb.vue'),
        meta: {
            title: "Quiz",
            hideHeaderPadding: true,
            analytics_view: "quiz_landing_view"
        }
    },
    {
        path: "/move/landing",
        name: "MoveWebLanding",
        component: () => import('/src/views/app/AppMoveWeb.vue'),
        meta: {
            title: "Move to Earn",
            hideHeaderPadding: true,
            analytics_view: "move_landing_view"
        }
    },
    {
        path: "/avatar/landing",
        name: "AvatarOnChainWebLanding",
        component: () => import('/src/views/common/AvatarOnChainWebLanding.vue'),
        meta: {

            title: "Avatar",
            hideHeaderPadding: true,
            analytics_view: "avatar_on_chain_landing_view"
        }
    },
    {
        path: "/download_beta",
        name: "DownloadBeta",
        component: () => import('/src/views/downloadBeta/index.vue'),
        meta: {
            title: "Download",
            hideHeaderPadding: true,
            mobileWhiteList: true,
        }
    },
    {
        path: "/app/mysteryBox",
        name: "AppMysteryBox",
        component: () => import('/src/views/app/MysteryBox/Details.vue'),
        meta: {
            title: "Mystery Box",
            hideHeaderPadding: true,
            analytics_view: "app_mystery_box_view"
        }
    },
    {
        path: "/cassava_leaderboard",
        name: "CassavaLeaderboard",
        meta: {
            title: 'Cassava Leaderboard',
            hideHeaderPadding: IsMobile() ? true : false,
            mobileWhiteList: true,
            analytics_view: "cassava_leaderboard_view"
        },
        component: () => import("/src/views/cassavaLeaderboard/CassavaLeaderboard.vue")
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
            title: "Dashboard",
            // hideHeaderPadding: true,
            mobileWhiteList: true,
            analytics_view: "dashboard_view"
        },
        component:()=>import("/src/views/dashboard/index.vue")
    },
    ...BUSINESS_PARTNER_ROUTERS,
    ...ADS_PAGE_ROUTERS,
    ...RULES_PAGE
]

const getRouterName = (routers: RouteRecordRaw[]): string[] => {
    let names: string[] = []
    routers.forEach(router => {
        names.push(router.name as string)
        if (router.children) {
            const childrensName = getRouterName(router.children)
            names = names.concat(childrensName)
        }
    })

    return names
}
export const whiteList = getRouterName(constantRouterMap)