import storage from 'store'
import store from '@/store'

// 保存到本地 token
export const storageToken = {
    set(val: string) {
        storage.set('token', val)
    },
    get() {
        return storage.get('token')
    },
    remove() {
        storage.remove('token')
    }
}

// 保存到本地 token 过期时间
export const storageTokenEx = {
    set(val: number) {
        storage.set('tokenExpire', val)
    },
    get() {
        return storage.get('tokenExpire')
    },
    remove() {
        storage.remove('tokenExpire')
    }
}

// 保存到本地email
export const storageEmail = {
    set(val: string) {
        const userProfile = store.state.user.userProfile
        userProfile.email = val
        store.commit('user/setInfo', userProfile)
        storage.set('cassavaEmail', val)
    },
    get() {
        return storage.get('cassavaEmail')
    },
    remove() {
        storage.remove('cassavaEmail')
    }
}

export const storageCode = {
    set(val: string) {
        storage.set('verifyCode', val)
    },
    get() {
        return storage.get('verifyCode')
    },
    remove() {
        storage.remove('verifyCode')
    }
}

// 修改邮箱 保存新邮箱
export const storageNewEmail = {
    set(val: string) {
        storage.set('newEmail', val)
    },
    get() {
        return storage.get('newEmail')
    },
    remove() {
        storage.remove('newEmail')
    }
}

// 修改密码 保存旧密码
export const storagePwd = {
    set(val: string) {
        storage.set('oldPwd', val)
    },
    get() {
        return storage.get('oldPwd')
    },
    remove() {
        storage.remove('oldPwd')
    }
}

// 保存用户信息
export const storageUserProfile = {
    set(val: any) {
        storage.set('userProfile', val)
    },
    get() {
        return storage.get('userProfile')
    },
    remove() {
        storage.remove('userProfile')
    }
}

// 保存bannerlist
export const storageActiveBannerList = {
    set(val: any) {
        storage.set('activeBanner', val)
    },
    get() {
        return storage.get('activeBanner')
    },
    remove() {
        storage.remove('activeBanner')
    }
}

// 保存社交媒体信息
export const storageSocialMedias = {
    set(val: any) {
        storage.set('socialMedias', val)
    },
    get() {
        return storage.get('socialMedias')
    },
    remove() {
        storage.remove('socialMedias')
    }
}

// 保存正在操作的社交媒体code
export const storageOperateSocialCode = {
    name: 'operateSocialCode',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

// 保存路由地址 绑定社交媒体
export const storageRouterPath = {
    name: 'routerPath',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

// 保存全局的静态资源管理配置文件
export const storageGlobalStatic = {
    name: 'staticResourcesMeta',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

// 保存购买盲盒需要的参数，用于buy more
export const storageBuyMysteryBox = {
    name: 'buyMysteryBoxParams',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

// 保存发送验证码倒计时
export const storageCountDown = {
    name: 'sendCodeCountDown',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

// 保存做题结果
export const storageQuizResult = {
    name: 'quizResult',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}
// 保存文章题目
export const storageArticleTitle = {
    name: 'quizArticleTitle',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

export const storageReferralCode = {
    name: "_referralCode",
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}
enum storageLastLoginTypeItem {
    unipass,
    email,
    walletConnect,
    telegram,
}
export const storageLastLoginType = {
    enum: storageLastLoginTypeItem,
    name: "_lastLoginType",
    set(val: any) {
        storage.set(this.name, val)
    },
    get(): storageLastLoginTypeItem {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}
export const storageNewbieTaskId = {
    name: "_newbieTaskId",
    set(val: number) {
        storage.set(this.name, val)
    },
    get(): number {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}    

// 保存twitter任务点击次数
export const storageQuestTaskClickTimes = {
    name: '_questTaskClickTimes',
    set(val: any) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}

// 新答题 第一次进入页面是否显示弹窗
export const storageNewQuizModal = {
    name: '_newQuizModalFirst',
    set(val: number) {
        let saveData = storage.get(this.name) || []
        if (!Array.isArray(saveData)) {
            saveData = []
        }
        saveData.find((item: any) => item === val) || saveData.push(val)
        storage.set(this.name, saveData)
    },
    get() {
        const userInfo = storageUserProfile.get()
        let storageData = storage.get(this.name) || []
        if (!Array.isArray(storageData)) {
            storageData = []
        }
        return storageData.find((item: any) => item === userInfo.account_id)
    },
    remove() {
        storage.remove(this.name)
    }
}

export const storageNewQuizBalance = {
    name: '_newQuizBalance',
    set(val: number) {
        const userInfo = storageUserProfile.get()
        const params = { accountId: userInfo.account_id, balance: val }
        let saveData = storage.get(this.name) || []
        if (!Array.isArray(saveData)) {
            saveData = []
        }
        const findData = saveData.find((item: any) => item.accountId === params.accountId)
        if (findData) {
            findData.balance = val
        } else {
            saveData.push(params)
        }
        storage.set(this.name, saveData)
    },
    get() {
        const userInfo = storageUserProfile.get()
        let storageData = storage.get(this.name) || []
        if (!Array.isArray(storageData)) {
            storageData = []
        }
        const findData = storageData.find((item: any) => item.accountId === userInfo.account_id)
        return findData ? findData.balance : 0
    },
    remove() {
        storage.remove(this.name)
    }
}

export const storageCheckInsAnimated = {
    name: '_checkInsAnimated',
    set(val: number) {
        const userInfo = storageUserProfile.get()
        const params = { accountId: userInfo.account_id, step: val }
        let saveData = storage.get(this.name) || []
        if (!Array.isArray(saveData)) {
            saveData = []
        }
        const findData = saveData.find((item: any) => item.accountId === params.accountId)
        if (findData) {
            findData.step = val
        } else {
            saveData.push(params)
        }
        storage.set(this.name, saveData)
    },
    get() {
        const userInfo = storageUserProfile.get()
        let storageData = storage.get(this.name) || []
        if (!Array.isArray(storageData)) {
            storageData = []
        }
        const findData = storageData.find((item: any) => item.accountId === userInfo.account_id)
        return findData ? findData.step : 0
    },
    remove() {
        storage.remove(this.name)
    }
}

// 保存channel
export const storageChannel = {
    name: '_channel',
    set(val: any) {
        sessionStorage.setItem(this.name, val)
    },
    get() {
        return sessionStorage.getItem(this.name)
    },
    remove() {
        sessionStorage.removeItem(this.name)
    }
}

export const storageOperateSolana = {
    name: '_operateSolana',
    set(val: {address: string, name: string}) {
        storage.set(this.name, val)
    },
    get() {
        return storage.get(this.name)
    },
    remove() {
        storage.remove(this.name)
    }
}