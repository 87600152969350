<template>
  <div class="loadingPage" :class="{version2: uiVersion === 2}">
    <Loading />
  </div>
</template>
<script lang="ts" setup>
import Loading from '@/components/common/Loading/Loading.vue'
import { ref } from 'vue';
import { useRoute } from 'vue-router';
const uiVersion = ref(1)

// quiz页面的ui为新版本
const route = useRoute()

if (route.fullPath.indexOf('/quiz') > -1 || route.fullPath.indexOf('app') > -1) {
    uiVersion.value = 2
}
</script>

<style lang="scss" scoped>
.loadingPage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1021;
  &.version2 {
    background-color: rgb(255, 255, 255, 0.5);
  }
}
</style>