
import { ActionContext } from 'vuex';
export interface ICommunityState {
  communitiesRefresh: boolean
  tasksRefresh: boolean,
  taskTabRefresh: boolean,
}

const state: ICommunityState = {
  communitiesRefresh: false,
  tasksRefresh: false,
  taskTabRefresh: false,
}

export default {
  namespaced: true, // 该模块就成为命名空间模块了
  // state,
  state: state,
  mutations: {
    // 设置 社区管理 列表 是否需要刷新
    setRefresh(state: ICommunityState, isRefresh: boolean) {
      state.communitiesRefresh = isRefresh
    },
    // 设置 任务列表 是否需要刷新
    setTaskRefresh(state: ICommunityState, isRefresh: boolean) {
      state.tasksRefresh = isRefresh
    },
    setTaskTabRefresh(state: ICommunityState, isRefresh: boolean) {
      state.taskTabRefresh = isRefresh
    },
  }
}