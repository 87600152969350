import instance from '@/utils/request'
import { BASE_RESPONSE } from './types'

// 获取脸谱组件
export const getAvatarComponents = () => {
  return instance({
    url: 'v1/account/avatar/image_meta',
    method: 'GET',
  })
}

// 检查脸谱是否可用
export const checkAvatar = (data: any) => {
  return instance({
    url: 'v1/account/avatar/check',
    method: 'POST',
    data
  })
}

// 获取默认脸谱
export const getDefaultAvatar = () => {
  return instance({
    url: 'v1/account/avatar/default',
    method: 'GET',
  })
}

// 构造默认脸谱
export const buildDefaultAvatar = (data: any) => {
  return instance({
    url: 'v1/account/avatar/default',
    method: 'POST',
    data
  })
}
export const buildAndMintAvatar = (data: any) => {
  return instance({
    url:"v1/avatars/build_and_mint",
    method: 'POST',
    data
  })
}

// 获取用户的脸谱
export const getUserAvatar = (avatar_id: number) => {
  return instance({
    url: 'v1/account/avatar',
    method: 'GET',
    params: { avatar_id }
  })
}

// 未登录情况下请求默认story与图片
export const getPlaceholder = () => {
  return instance({
    url: 'v1/account/avatar/placeholder',
    method: 'GET',
  })
}


export interface IAvatarItem {
  avatar_id: number
  avatar_image_url: string
  status: 'built' | 'minted' | 'minting' | 'in_use'
  token_id: number
  explorer_url: string
}
export type IChain = "POLYGON" | "SOLANA" | "CASSAVA"
export interface IAvatarItems {
  chain: IChain,
  items: IAvatarItem[]
  wallet_address: string
  provider_name: string
}
export interface IAvatarsResp {
  items: IAvatarItems[]
}
// 获取用户所有的脸谱
export const getUserAllAvatars = (): Promise<BASE_RESPONSE<IAvatarsResp>> => {
  return instance({
    url: 'v1/avatars2',
    method: 'GET',
    params: {}
  })
}

export interface IMintResp {
  data: string
  block_hash: string
  last_valid_block_height: number
}
export const mintAvatar = (data: { wallet_address: string, avatar_id: number }): Promise<BASE_RESPONSE<IMintResp>> => {
  return instance({
    url: 'v1/avatars/solana/mint',
    method: 'POST',
    data
  })
}

export const migrateAvatar = (data: { wallet_address: string, token_id: number }): Promise<BASE_RESPONSE<IMintResp>>  => {
  return instance({
    url: 'v1/avatars/solana/mint',
    method: 'POST',
    data
  })
}

export const useAvatar = (data: {avatar_id: number}) => {
  return instance({
    url: 'v1/avatars/set_profile',
    method: 'POST',
    data
  })
}

// 上报结果
export const reportMintStatus = (data: {wallet_address: string, token_id?: number, avatar_id?: number, tx_hash: string}) => {
  return instance({
    url: 'v1/avatars/solana/mint/status',
    method: 'POST',
    data
  })
}
