<template>
    <div class="imageWrap">
        <img 
            :src="src" 
            class="image" 
            v-show="isLoading" 
            alt="" 
            v-bind="$attrs"
            @load="onLoad"
            :class="{'isCover': isCover}"
            :style="`${bgc ? 'background-color: ' + bgc : ''}`"
        />
        <div class="imgLoad" v-show="!isLoading">
            <Loading :small="isSmall" />
        </div>
    </div>
</template>
<script lang='ts' setup>
import Loading from '@/components/common/Loading/Loading.vue'
import { ref, watch } from 'vue';
const isLoading = ref(false)
const { src } = defineProps({
    src: {
        type: String,
        default: ''
    },
    isCover: {
        type: Boolean,
        default: false
    },
    isSmall: {
        type: Boolean,
        default: false
    },
    bgc: {
        type: String,
        default: ''
    }
})

const $emit = defineEmits(['onLoaded']);

watch(() => src, () => {
    isLoading.value = false
})

const onLoad = () => {
    isLoading.value = true
    $emit('onLoaded',true)
}

</script>
<style lang='scss' scoped>
  .imageWrap, .image {
    width: 100%;
    height: 100%;
    // border-radius: 20px;
    &.isCover {
      object-fit: cover;
    }
  }
  .imgLoad {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
