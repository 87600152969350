type TIME_ARRAY = [number, number, number, number];
export function timeFromNow(
    time: number | string | Date,
    options = {
        D: "Day", H: "Hour", M: "Minute", S: "Second"
    }
): string {
    if (typeof time === "string") time = new Date(time).getTime();
    else if (time instanceof Date) time = time.getTime();

    const { floor, abs } = Math;
    const now = Date.now();
    const diff = abs(now - time);
    const lastSeconds = Math.floor(diff / 1000);
    const D = floor(lastSeconds / 3600 / 24);
    const H = floor(lastSeconds / 3600 % 24);
    const M = floor(lastSeconds / 60 % 60);
    const S = floor(lastSeconds % 60);
    const res = timeLimit([D, H, M, S], 2)
    const optionsText = [options.D, options.H, options.M, options.S];
    return res.reduce((total: string, next: number, index: number) => total += timeIsExists(next, optionsText[index]), "");
}
export function timeLimit(timeArr: TIME_ARRAY, size = 2): TIME_ARRAY {
    let res: TIME_ARRAY = [0, 0, 0, 0], point = 0, flag = true;
    while (!timeArr[point] && flag) {
        if (++point === 4) flag = false;
    }
    if (point === 4) return res;
    for (let i = 0; i < size; i++) {
        res[point + i] = timeArr[point + i];
    }
    return res;
}
export function timeIsExists(time, exists, notExists = "") {
    if (!time) return notExists;
    else return time + " " + exists + " ";
}

export function timeAddZero(number) {
    return number < 10 ? '0' + number : number;
}

// 获取utc时区偏移量
export function getTimeOffset() {
    var MyDate = new Date();
    var MyOffset = (MyDate.getTimezoneOffset()) / -60;
    var result = timeAddZero(Math.abs(MyOffset))
    return MyOffset < 0 ? '-' + result : '+' + result
}


export const getUtcTimeStr = function () {
    return `(UTC${getTimeOffset()}:00)`
}

// return `02 Feb 2024`
export function getUTCString(time: Date): string {
    if (!time) {
        return ""
    }
    const utcArr = time.toUTCString().split(" ");
    return `${utcArr[1]} ${utcArr[2]} ${utcArr[3]}`
}

export function millisecond(ts: string | number): Date | undefined {
    ts = Number(ts);
    if (isNaN(ts)) {
        return void 0;
    }
    return new Date(ts * 1000)
}