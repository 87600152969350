<script lang="ts" setup>
import { computed, ref, onBeforeUnmount, onMounted } from 'vue';
const $emit = defineEmits(['change'])
const $props = withDefaults(defineProps<{
    options: Array<{
        label: string,
        value: string | number,
        icon?: string,
    }>,
    value: string | number,
    align?: "center" | "left"
}>(), {
    align: "center"
})
const showOptions = ref(false);
const showvalue = computed(() => {
    let v: any = {};
    for (let i = 0; i < $props.options.length; i++) {
        if ($props.options[i].value === $props.value) {
            v = $props.options[i];
            break
        }
    }
    return v;
})
function eventFn() {
    showOptions.value = false;
}
function optionsClick(item) {
    $emit("change", item);
}
onMounted(() => document.addEventListener('click', eventFn));
onBeforeUnmount(() => document.removeEventListener('click', eventFn));
</script>

<template>
    <div class="cassava_select_view" @click.stop="showOptions = !showOptions" :class="`cassava_select_view_align-${align}`">
        <div class="cassava_select_value">
            <img :src="showvalue.icon" alt="" v-if="showvalue.icon" class="cassava_select_icon">
            <slot>{{ showvalue.label }}</slot>
        </div>
        <div class="iconfont icon-triangle"></div>
        <div class="cassava_select_options_view" v-show="showOptions">
            <div class="cassava_select_options_item" v-for="item in options" :key="item.value"
                :class="{ 'selectd': value === item.value }" @click="optionsClick(item)">
                <img :src="item.icon" alt="" v-if="item.icon" class="cassava_select_icon">
                {{ item.label }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cassava_select_icon {
    width: 24px;
    height: 24px;
}

.cassava_select_view {
    background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
    border-radius: 10px;
    padding: 2px;
    height: 40px;
    min-width: 140px;
    position: relative;
    cursor: pointer;

    .cassava_select_value {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 1px solid #83F05F;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        padding: 0 30px 0 18px;
        gap: 8px;
    }

    .icon-triangle {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -48%);
        color: #97A1A9;
        font-size: 12px;

        &::before {
            transform: scale(0.7);
            display: block;
        }
    }

    .cassava_select_options_view {
        position: absolute;
        top: calc(100% + 3px);
        left: 0;
        width: 100%;
        max-height: 350px;
        overflow-y: auto;
        background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
        border-radius: 10px;
        z-index: 199;
        padding: 10px;
        gap: 10px;
        display: flex;
        flex-direction: column;

        .cassava_select_options_item {
            height: 28px;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #fff;
            user-select: none;
            cursor: pointer;
            border-radius: 10px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            display: flex;
            gap: 8px;

            &.selectd {
                background: #444f40;
            }

            &:hover {
                background: #677761;
            }
        }
    }
}

.cassava_select_view_align-left {
    .cassava_select_value {
        justify-content: flex-start;
    }

    .cassava_select_options_view {
        .cassava_select_options_item {
            justify-content: flex-start;
            padding-left: 12px;
        }
    }
}

@media screen and (max-width: 750px) {
    .cassava_select_view {
        min-width: 100px;
        .cassava_select_value {
            font-size: 12px;
        }
    }
}
</style>