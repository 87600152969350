<script lang="ts" setup>
import Image from '@/components/common/Image/Image.vue';
import icon_coin from '@/assets/images/goldCoinNew.png';
import { ref, toRefs, PropType, computed } from 'vue';
import { IQuestCardData } from '@/api/taskCenter';
import { ENDS_IN, STARTS_IN, STATUS_ENDED, STATUS_ONGOING, STATUS_SCHEDULED, ENDED } from '../Task.text';
import { ICountItem, countDownFormat, getDisplayStatusName } from '../TaskCenter.help';
import { formatDate } from '@/utils/common'
import { useStore } from 'vuex';
const loaded = ref(false);
const statusText = ref('')
const emits = defineEmits(['updateCount'])
const store = useStore()
const IsMobile = computed(() => store.state.global.isMobile)

const props = defineProps({
    item: {
        type: Object as PropType<IQuestCardData>,
        default: {}
    },
    countItem: {
        type: Object as PropType<ICountItem>,
        default: {}
    }
})
const { item, countItem } = toRefs(props)

if (item.value.status === STATUS_SCHEDULED) {
    statusText.value = STARTS_IN
} else if (item.value.status === STATUS_ONGOING) {
    statusText.value = ENDS_IN
} else if (item.value.status === STATUS_ENDED) {
    statusText.value = ENDED
}

if ((item.value.status === STATUS_SCHEDULED || item.value.status === STATUS_ONGOING) && Number(item.value.end_time) > 0) {
    const obj: ICountItem = {status: '', id: 0, count: 0 }
    obj.status = item.value.status
    obj.id = item.value.quest_id
    // 倒计时时间
    obj.count = item.value.remaining
    emits('updateCount', obj)
}

</script>

<template>
    <template v-if="!IsMobile">
        <div class="task_card_view relative">
            <div class="task_card_comunity relative">
                <img :src="item.community.logo_image_url" alt="" class="task_card_comunity_logo">
                <span class="task_card_comunity_name">{{ item.community.name }}</span>
            </div>
            <div class="task_card_img">
                <Image :src="item.thumbnail_url" @on-loaded="loaded = true"
                    class="task_card_img_accset" :is-cover="true"></Image>
                <div class="task_card_img_info" v-show="loaded" :class="[(item.status === STATUS_ONGOING || item.status === STATUS_SCHEDULED) && item.end_time ? '' : 'col']">
                    <div class="task_card_img_info_status">
                        <div class="task_card_img_info_title">Status</div>
                        <div class="task_card_img_info_main">{{ getDisplayStatusName(item.status) }}</div>
                    </div>
                    <div class="task_card_img_info_time" v-if="(item.status === STATUS_ONGOING || item.status === STATUS_SCHEDULED) && item.end_time && countItem.count">
                        <div class="task_card_img_info_title">{{ statusText }}</div>
                        <div class="task_card_img_info_main">{{ countDownFormat(countItem.count) }}</div>
                    </div>
                </div>
            </div>
            <div class="task_card_name">{{ item.name }}</div>
            <div class="task_card_rangetime" :class="item.status">
            From <span class="task_card_start_time"> {{ formatDate(item.start_time) }} </span>
                <span class="task_card_rangetime_end" v-if="item.end_time"> to <span class="task_card_end_time">{{ formatDate(item.end_time) }}</span></span>
            </div>
            <div class="task_card_tags">
                <div class="task_card_tags_item" v-for="(it, idx) in item.rewards">
                    <img :src="it.icon_url" v-if="it.icon_url" class="tagIcon">
                    {{ it.display_name }}
                </div>
            </div>
        </div>
    </template>

    <template v-else>
        <div class="task_card_view">
            <div class="task_card_img_info" v-show="loaded">
                <div class="task_card_img_info_status">
                    <div class="task_card_img_info_title">Status</div>
                    <div class="task_card_img_info_main">{{ getDisplayStatusName(item.status) }}</div>
                </div>
                <div class="task_card_img_info_time" v-if="(item.status === STATUS_ONGOING || item.status === STATUS_SCHEDULED) && item.end_time && countItem.count">
                    <div class="task_card_img_info_title">{{ statusText }}</div>
                    <div class="task_card_img_info_main">{{ countDownFormat(countItem.count) }}</div>
                </div>
            </div>
            <div class="task_card_content flex-vertical-center">
                <Image 
                    :src="item.thumbnail_url" 
                    @on-loaded="loaded = true"
                    class="task_card_img_accset" 
                    :is-cover="true" 
                    :is-small="true"
                />
                <div class="task_card_info">
                    <div class="task_card_name">{{ item.name }}</div>
                    <div class="task_card_comunity">
                        <img :src="item.community.logo_image_url" alt="" class="task_card_comunity_logo">
                        <span class="task_card_comunity_name">{{ item.community.name }}</span>
                    </div>
                    <div class="task_card_tags">
                        <div class="task_card_tags_item" v-for="(it, idx) in item.rewards">
                            <img :src="it.icon_url" v-if="it.icon_url" class="tagIcon">
                            {{ it.display_name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="task_card_rangetime" :class="item.status">
                From 
                <span class="task_card_start_time"> {{ formatDate(item.start_time) }} </span>
                <span class="task_card_rangetime_end" v-if="item.end_time"> to <span class="task_card_end_time">{{ formatDate(item.end_time) }}</span></span>
            </div>
        </div>
    </template>

</template>

<style lang="scss" scoped>
.task_card_view {
    background: #1E211E;
    border-radius: 10px;
    padding: 16px 16px 24px;
    width: 308px;
    height: 488px;
    transition: background 0.1s ease-in-out;
    cursor: pointer;

    &:hover {
        background: #303530;
    }

    .task_card_comunity {
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: hidden;
        .task_card_comunity_logo {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            object-fit: cover;
            flex-shrink: 0;
        }

        .task_card_comunity_name {
            font-weight: 400;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #97A1A9;
        }
    }

    .task_card_img {
        background: #303530;
        border-radius: 10px;
        width: 276px;
        height: 276px;
        overflow: hidden;
        position: relative;
        margin: 12px auto 16px;

        .task_card_img_accset {
            width: 100%;
            height: 100%;
        }

        .task_card_img_info {
            height: 40px;
            background: #01995EF5;
            position: absolute;
            top: 0;
            right: 0;
            opacity: 0.9;
            border-radius: 0 10px 0 10px;
            display: flex;
            gap: 15px;
            justify-content: space-between;
            padding: 0 16px;
            &.col {
              height: 28px;
              padding: 0 4px;
              .task_card_img_info_status {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .task_card_img_info_title {
                  margin-right: 4px;
                }
              }
            }
        }

        .task_card_img_info_status,
        .task_card_img_info_time {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 8px;
            color: #fff;
            gap: 2px;
        }

        .task_card_img_info_title {
            font-weight: 400;
            font-size: 10px;
            color: rgba(255, 255, 255, 0.6);
        }

        .task_card_img_info_main {
            font-weight: 700;
            font-size: 12px;
            color: #FFFFFF;
        }
    }

    .task_card_name {
        font-weight: 700;
        font-size: 16px;
        color: #fff;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; // 控制多行的行数
        -webkit-box-orient: vertical;
    }

    .task_card_rangetime {
        color: #97A1A9;
        font-size: 14px;
        margin-top: 16px;
        &.ONGOING {
          .task_card_end_time {
            color: #fff;
          }
        }
        &.SCHEDULED {
          .task_card_end_time, .task_card_start_time {
            color: #fff;
          }
        }
    }

    .task_card_tags {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        margin-top: 34px;
        overflow: hidden;
        overflow-x: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        width: calc(100% - 32px);
        // padding-left: 16px;
        overflow: hidden;
        overflow-x: auto;
        .task_card_tags_item {
            flex-shrink: 0;
            padding: 0 24px;
            height: 34px;
            display: flex;
            background: #01995E;
            border-radius: 20px;
            font-weight: 700;
            font-size: 16px;
            color: #00340F;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #fff;
            .tagIcon {

            }
        }
    }
}
.tagIcon {
    height: 24px;
    width: 24px;
}

// 手机端
@media screen and (max-width: 750px) {
    .task_card_view {
        width: 9.146667rem /* 343/37.5 */;
        height: 4.533333rem /* 170/37.5 */;
        background: #1E211E;
        border-radius: 12px;
        overflow: hidden;
        padding: 0;
        .task_card_img_info {
            height: .64rem /* 24/37.5 */;
            background: #01995E;
            padding-left: .426667rem /* 16/37.5 */;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .task_card_img_info_status, .task_card_img_info_time {
                display: flex;
                align-items: center;
                height: 100%;
                
                .task_card_img_info_title {
                    font-size: .266667rem /* 10/37.5 */;
                    line-height: .32rem /* 12/37.5 */;
                    color: rgba(255, 255, 255, 0.6);
                    margin-right: .213333rem /* 8/37.5 */;
                }
                .task_card_img_info_main {
                    font-weight: 700;
                    font-size: .32rem /* 12/37.5 */;
                    line-height: .373333rem /* 14/37.5 */;
                    color: #FFFFFF;
                }
            }
            .task_card_img_info_time {
                padding-right: 1.066667rem /* 40/37.5 */;
            }
        }
        .task_card_content {
            padding: .32rem /* 12/37.5 */;
            .task_card_img_accset {
                width: 2.56rem /* 96/37.5 */;
                height: 2.56rem /* 96/37.5 */;
                margin-right: .32rem /* 12/37.5 */;
                overflow: hidden;
                border-radius: 12px;
                flex-shrink: 0;
            }
            .task_card_info {
                flex-grow: 1;
                width: 5.626667rem /* 211/37.5 */;
                .task_card_name {
                    word-break: break-all;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: .213333rem /* 8/37.5 */;
                    height: .8rem /* 30/37.5 */;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    /*控制在3行*/
                    -webkit-line-clamp:2;
                    -webkit-box-orient:vertical;
                }
            }
            .task_card_tags {
                position: static;
                margin-top: .213333rem /* 8/37.5 */;
                transform: translateX(0);
                justify-content: flex-start;
                gap: .213333rem /* 8/37.5 */;
                width: 100%;
                .task_card_tags_item {
                    padding: 0 .426667rem /* 16/37.5 */;
                    font-size: .32rem /* 12/37.5 */;
                    line-height: .373333rem /* 14/37.5 */;
                }
                .tagIcon{
                    width: .64rem /* 24/37.5 */;
                    height: .64rem /* 24/37.5 */;
                }
            }
            .task_card_comunity {
                .task_card_comunity_logo {
                    width: .533333rem /* 20/37.5 */;
                    height: .533333rem /* 20/37.5 */;
                }
            }
        }
        .task_card_rangetime {
            margin-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .task_card_rangetime_end {
                margin-left: .106667rem /* 4/37.5 */;
            }
        }
    }
}
</style>