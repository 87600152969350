import instance from '@/utils/request'
import { ILoginForm, IRegiterForm, IThirdpartyForm } from '../types/views/login';
import { BASE_RESPONSE } from './types';

// 登录
export const goLogin = (data: ILoginForm, ctoken: string) => {
  return instance({
    url: "v1/account/login",
    method: "POST",
    data,
    headers: {
      'x-captcha-token': ctoken
    }
  })
}

export const loginThirdparty = (data: { code: string, result: string, referral_code?: string }) => {
  return instance({
    url: "v1/account/login/thirdparty",
    method: "POST",
    data,
  })
}
// # unipass 登录
export const loginApi = (data) => {
  return instance({
    url: "v1/account/login/unipass",
    method: "POST",
    data
  })
}

// # unipass has_init init功能
export const loginInit = (data) => {
  return instance({
    url: "/v1/account/init_and_login/unipass",
    method: 'POST',
    data
  })
}

// 刷新token
export const refreshToken = () => {
  return instance({
    url: 'v1/account/token/refresh',
    method: 'POST',
    data: {}
  })
}

// 检查邮箱是否可注册
export const checkAccout = (data: any) => {
  return instance({
    url: 'v1/account/check',
    method: 'POST',
    data
  })
}

// 发送验证码
export const sendVerifyCode = (data: any) => {
  return instance({
    url: 'v1/verify_code/send',
    method: 'POST',
    data
  })
}

// 校验验证码
export const checkVerifyCode = (data: any) => {
  return instance({
    url: 'v1/verify_code/check',
    method: 'POST',
    data
  })
}

// 注册
export const goRegister = (data: IRegiterForm) => {
  return instance({
    url: 'v1/account/register',
    method: 'POST',
    data
  })
}

// 忘记密码 重置密码
export const resetPassword = (data: any) => {
  return instance({
    url: 'v1/account/password/retrieve',
    method: 'POST',
    data
  })
}

// 校验旧密码
export const checkOldPassword = (data: any) => {
  return instance({
    url: 'v1/account/password/verify',
    method: 'POST',
    data
  })
}

// 修改密码
export const changePassword = (data: any) => {
  return instance({
    url: 'v1/account/password/reset',
    method: 'POST',
    data
  })
}

// 登出
export const goLogout = (data: any) => {
  return instance({
    url: 'v1/account/logout',
    method: 'POST',
    data
  })
}

// 获取用户信息
export const getUserProfile = () => {
  return instance({
    url: 'v1/account/user_info',
    method: 'GET',
  })
}

// 更新用户信息
export const updateUserProfile = (data) => {
  return instance({
    url: 'v1/account/user_info/update',
    method: 'POST',
    data
  })
}

// 更新邮箱
export const updateUserEmail = (data: any) => {
  return instance({
    url: 'v1/account/user_info/email/update',
    method: 'POST',
    data
  })
}

// 绑定钱包
export const bindWallet = (data: any) => {
  console.log('bindWallet data: ', data);
  return instance({
    url: 'v1/account/wallet/link',
    method: 'POST',
    data
  })
}
export const bindWallet2 = (data: any) => {
  return instance({
    url: 'v1/account/wallet/link2',
    method: 'POST',
    data
  })
}

// 解绑钱包
export const disconnectWallet = (data: { address: string, provider_name: string }) => {
  return instance({
    url: 'v1/account/wallet/unlink',
    method: 'POST',
    data
  })
}

export const disconnectWallet2 = (data: { address: string, chain: string }) => {
  return instance({
    url: 'v1/account/wallet/unlink2',
    method: 'POST',
    data
  })
}
export type IProviderCode = 'METAMASK' | 'WALLETCONNECT' | 'PHANTOM' | 'SOLFLARE' | 'OKX' | 'PARTICLE' | 'GATEIO'

export enum ProviderCodeEnum {
  METAMASK = 'METAMASK',
  WALLETCONNECT = 'WALLETCONNECT',
  PHANTOM = 'PHANTOM',
  SOLFLARE = 'SOLFLARE',
  OKX = 'OKX',
  PARTICLE = 'PARTICLE',
  GATEIO = 'GATEIO'
}

export interface IWalletProvider {
  description: string
  name: string
  network: number
  provider_code: IProviderCode
  options: {}
  chain: 'EVM_COMPATIBLE' | 'SOLANA'
}
export interface IWalletInfo {
  bound: boolean
  provider_name: string
  wallet_address: string
  providers: IWalletProvider[]
}
export interface IBindSocialMediaUserInfo {
  avatar: string
  platform_id: number,
  user_id: string
  username: string
}
export interface ISocialMediaInfo {
  app_authorize_url: string
  app_name: string
  authorize_url: string
  black_icon_url: string
  platform_code: string
  platform_name: string
  user_info: IBindSocialMediaUserInfo | null
  white_icon_url: string
}

export interface ILinksInfo {
  scialmedias: ISocialMediaInfo[]
  wallet: IWalletInfo
}

// 获取钱包数据与社交媒体数据
export const getLinksInfo = (): Promise<BASE_RESPONSE<ILinksInfo>> => {
  return instance({
    url: 'v1/account/links',
    method: 'GET',
  })
}

export interface IlinkedWallets {

}

export interface ILinksInfo2 {
  social_medias: ISocialMediaInfo[]
  wallets: {
    linked_wallets: IWalletInfo[],
    providers: IWalletProvider[]
  }
}


export const getLinksInfo2 = (): Promise<BASE_RESPONSE<ILinksInfo2>> => {
  return instance({
    url: 'v1/account/links2',
    method: 'GET',
  })
}

// 社交媒体授权后 code 发送给后端，获取社交媒体的用户信息
export const putCode = (data) => {
  return instance({
    url: `v1/account/smp/link`,
    method: 'POST',
    data
  })
}

export const bindTwitterOAuth1 = (data) => {
  return instance({
    url: `v1/twitter_oauth1_link`,
    method: "post",
    data
  })
}

// telegram绑定
export const linkTelegram = (data: {
  id: string
  first_name: string
  last_name: string
  photo_url: string
  auth_date: string
  hash: string
  state: string
}) => {
  return instance({
    url: `v1/account/telegram/link`,
    method: 'POST',
    data
  })
}

// 解除绑定
export const disConnect = (data) => {
  return instance({
    url: `v1/account/smp/unlink`,
    method: 'POST',
    data
  })
}

export const myFollow = (params) => {
  return instance({
    url: `v1/community/following`,
    method: 'GET',
    params
  })
}


export interface INewbieTaskInfoRes {
  quest_id: number
  reward: number
}
// 获取新手任务信息
export const getNewbieTaskInfo = (): Promise<BASE_RESPONSE<INewbieTaskInfoRes>> => {
  return instance({
    url: `v1/newbie_task/info`,
    method: 'GET',
  })
}


export const getTwitterOauth1Url = (): Promise<BASE_RESPONSE<{ url: string }>> => {
  return instance({
    url: `v1/twitter_oauth1_url`,
    method: 'GET'
  })
}

export const deleteAccount = (data) => {
  return instance({
    url: `v1/account/destroyme`,
    method: 'POST',
    data
  })
}

export interface ISolanaLogin {
  address: string
  signature: string
  nonce: string
  timestamp: string
  provider_name: string
  provider_code: string
  chain: string
}
export const solanaLogin = (data: ISolanaLogin) => {
  return instance({
    url: `v1/account/login/solana`,
    method: 'POST',
    data
  })
}