import { ActionContext } from 'vuex';
import { ILoginForm, IThirdpartyForm } from '@/types/views/login'
import { AllState } from '@/store';
import { goLogin, goLogout, getUserProfile, loginThirdparty, goRegister, solanaLogin, type ISolanaLogin } from '@/api/user';
import { storageToken, storageTokenEx, storageUserProfile, storageGlobalStatic, storageNewbieTaskId } from '@/utils/storage';
import { set } from 'vue-gtag';

// userProfile interface
export interface IUserProfile {
  name: string,
  email: string,
  avatar: string,
  id: string,
  accountId: string | number,
  unipass_address: string,
  phone: string,
  country_code: string,
  claimed_newbie_task: boolean
  newbie_task_id?: number
  account_wallet_address: string
  user_bind_info: Record<string, boolean>
  referral_info: IUserProfileReferralInfo
  default_avatar_id: number
}
export interface IUserProfileReferralInfo {
  "referral_link": string,
  "referral_code": string,
  "invitees": number,
  "share_text": string,
  "share_image_url": string
}

export interface IToken {
  token: string,
  expire_at?: number
  tokenExpire: number
}

// user interface
export interface IUserState extends IToken {
  userProfile: IUserProfile
}


const whiteList = [
  'token:refresh',
  'account:login',
  'account:check',
]

const state: IUserState = {
  userProfile: {
    name: '', // 用户名
    email: '', // 用户邮箱
    avatar: '', // 头像地址
    id: '', // id
    accountId: '',
    unipass_address: "",
    phone: "",
    country_code: "",
    claimed_newbie_task: true,
    account_wallet_address: "",
    user_bind_info: {},
    default_avatar_id: 0,
    referral_info: {} as any
  },
  token: '',
  tokenExpire: 0, // token过期时间
}


export default {
  namespaced: true, // 该模块就成为命名空间模块了
  // state,
  state: state,
  mutations: {
    // 设置用户信息
    setInfo(state: IUserState, info: any) {
      const { nick_name, email, avatar, id, account_id, unipass_address, phone, country_code, claimed_newbie_task, account_wallet_address, user_bind_info, referral_info, default_avatar_id } = info
      set({ user_id: account_id })

      storageUserProfile.set(info)
      state.userProfile = {
        name: nick_name,
        email, avatar, id, accountId: account_id,
        unipass_address, phone, country_code,
        claimed_newbie_task, account_wallet_address,
        user_bind_info,
        referral_info,
        default_avatar_id,
      }
    },
    // 设置token
    setToken(state: IUserState, tokenInfo: any) {
      const [token, tokenExpire] = tokenInfo
      storageToken.set(token)
      storageTokenEx.set(tokenExpire)
      state.token = token;
      state.tokenExpire = tokenExpire;
    },
    // 清除登录token
    clearState(state: IUserState) {
      const staticConfig = storageGlobalStatic.get()
      // BUG clearAll会清除其他的localstorage
      // storage.clearAll()

      // 清除所有的localstorage 保存静态文件配置文件
      storageGlobalStatic.set(staticConfig)
      storageToken.remove();
      storageTokenEx.remove();
      storageUserProfile.remove();
      state.token = '';
      state.tokenExpire = 0;
    },
    setNewbieTaskStatus(state: IUserState, status: boolean) {
      state.userProfile.claimed_newbie_task = status
    },
    setNewbieTaskId(state: IUserState, id: number) {
      state.userProfile.newbie_task_id = id
      storageNewbieTaskId.set(id)
      if (!id) storageNewbieTaskId.remove()
    },
    setAvatar(state: IUserState, avatar: string) {
      state.userProfile.avatar = avatar
    }
  },
  actions: {
    // 获取token
    getToken(context: ActionContext<IUserState, AllState>, url: string) {

      // 判断白名单 white list
      if (whiteList.indexOf(url!) > -1) {
        return Promise.resolve(null);
      }

      const tokenExpire = storageTokenEx.get()
      // const tokenExpire = context.state.tokenExpire

      // 判断token 有没有过期 或者即将过期
      const currentUnix = parseInt(new Date().getTime() / 1000 + '')

      if (!tokenExpire) {
        return Promise.resolve(null);
      }

      // token 即将过期或已过期
      if (tokenExpire - currentUnix < 1000) {
        /*
        return new Promise<string>(((resolve, reject) => {
          refreshToken().then((res: any) => {
            context.commit('setToken', res.token, res.expire_at)
            resolve(res.token);
          }).catch((err: any) => {
            console.error(err);
            context.commit('clearState');
            reject(err);
          })
        }));
        */
        return Promise.resolve(null);
      } else {
        const token = context.state.token || storageToken.get()
        return Promise.resolve(token);
      }
    },
    // 登录
    loginAction(context: ActionContext<IUserState, AllState>, { params, ctoken }: { params: ILoginForm, ctoken: string }) {
      return new Promise((resolve, reject) => {
        goLogin(params, ctoken).then((e: any) => {
          const data = e.data
          context.commit('setToken', [data.data.token, data.data.expire_at]);
          context.commit('setInfo', data.data)
          resolve(data)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    loginThirdpartyAction(context: ActionContext<IUserState, AllState>, params: IThirdpartyForm) {
      return new Promise((resolve, reject) => {
        loginThirdparty(params).then((e: any) => {
          const data = e.data
          context.commit('setToken', [data.data.token, data.data.expire_at]);
          context.commit('setInfo', data.data)
          resolve(data)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    loginSolanaAction(context: ActionContext<IUserState, AllState>, params: ISolanaLogin) {
      return new Promise((resolve, reject) => {
        solanaLogin(params).then((e: any) => {
          const data = e.data
          context.commit('setToken', [data.data.token, data.data.expire_at]);
          context.commit('setInfo', data.data)
          resolve(data)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    registerAction(context: ActionContext<IUserState, AllState>, params: any) {
      return new Promise((resolve, reject) => {
        goRegister(params).then((e: any) => {
          const data = e.data
          context.commit('setToken', [data.data.token, data.data.expire_at]);
          context.commit('setInfo', data.data)
          resolve(data)
        }).catch((err: any) => {
          reject(err)
        })
      })
    },
    // 获取用户信息
    getUserProfileAction(context: ActionContext<IUserState, AllState>) {
      const currentUnix = parseInt(new Date().getTime() / 1000 + '')
      const tokenExpire = storageTokenEx.get()
      return new Promise((resolve, reject) => {
        if (context.state.token && tokenExpire - currentUnix > 1000) {
          getUserProfile().then((e: any) => {
            const info = e.data.data;
            context.commit('setInfo', info);
            resolve(e);
          }).catch((err: any) => {
            reject(err);
          })
        } else {
          reject()
        }
      })
    },

    // 退出登录
    logoutAction(context: ActionContext<IUserState, AllState>) {
      return new Promise((resolve, reject) => {
        goLogout({}).then((e: any) => {
          context.commit('clearState');
          resolve(e)
        }).catch((err: any) => {
          context.commit('clearState');
          console.error(err);
          reject(err)
        })
      })
    },
  },
}