<script lang="ts" setup>
import { ref } from 'vue';
const inputEle = ref(null);
defineProps(['modelValue'])
const $emit = defineEmits(['update:modelValue', 'search'])
const value = ref("")
function emitSearch() {
    $emit("search", inputEle.value.value);
}
</script>

<template>
    <div class="cassava_input_view">
        <input type="text" class="cassava_input_main" v-bind="$attrs" v-model="value" @keydown.enter="emitSearch"
            ref="inputEle" @input="(event: any) => $emit('update:modelValue', event.target.value)" maxlength="50">
        <div class="search_icon iconfont icon-search" @click="emitSearch" :class="{ 'disable': !value }">
        </div>
    </div>
</template>

<style lang="scss" scoped>
.cassava_input_view {
    position: relative;
    background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
    border-radius: 8px;
    overflow: hidden;
    padding: 1px;
    height: 40px;

    .cassava_input_main {
        width: calc(100% - 35px);
        height: 38px;
        border-radius: 8px 0 0 8px;
        border: 1px solid #83F05F;
        outline: none;
        font-size: 12px;
        color: #fff;
        padding: 12px;
        background: transparent;
        border-right: 1px solid transparent;

        &::placeholder {
            color: #969b95;
        }
    }

    .search_icon {
        position: absolute;
        height: 38px;
        width: 38px;
        right: 1px;
        top: 1px;
        border: 1px solid #83F05F;
        border-radius: 8px;
        text-align: center;
        line-height: 36px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;

        &.disable {
            color: #b1b1b1;
            // cursor: not-allowed;
        }
    }
}
</style>