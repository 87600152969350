<script lang="ts" setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
import Header from "@/components/layout/Header.vue";
import HeaderMobile from "@/components/layout/HeaderMobile.vue";
import Footer from "@/components/layout/Footer.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import InviteBox from "@/components/layout/InviteBox.vue";
import LoadingPage from "@/components/layout/LoadingPage.vue";
import store from "@/store";
import { useRoute } from "vue-router";
import { IsMobile, myThrottle, isCassavaWebView } from './utils/common';
import { isInWechatBrowser } from "./utils/common";

const $route = useRoute();
const resizeFn = () => {
    store.commit('setClientType', IsMobile())
}

resizeFn()

window.addEventListener('resize', myThrottle(resizeFn, 500))


</script>

<template>
  <Header v-if="!store.state.global.isMobile" />
  <HeaderMobile v-if="store.state.global.isMobile && !isCassavaWebView()" />
  <!-- <transition name="slide-left" mode="out-in">
    
  </transition> -->
  <div class="mainWrap" :class="$route.meta.hideHeaderPadding || isCassavaWebView() ? '' : 'app_body'">
    <router-view :key="store.state.user.token"></router-view>
  </div>
  <Sidebar />

  <Footer v-if="$route.path !== '/'" />
  <Teleport to="body">
    <!-- 切换页面的loading效果 -->
    <loading-page v-if="store.state.global.routerChangeLoading" />
    <div class="inWechatBrowser" v-if="isInWechatBrowser()" @touchmove.prevent>
      <img src="@/assets/images/inWechatBrowser.png" alt="" class="inWechatBrowserTips">
    </div>
    <InviteBox v-if="store.state.global.showInviteBox"></InviteBox>
  </Teleport>
</template>

<style lang="scss">
.grecaptcha-badge {
  right: -2000px !important;
}

.inWechatBrowser {
  position: fixed;
  user-select: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .8);
  z-index: 9999;
}

.inWechatBrowserTips {
  width: 70vw;
  position: absolute;
  top: 3%;
  right: 0;
}

.slide-left-enter {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-enter-active {
  transition: all 0.5s ease;
}

.slide-left-leave-to {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.slide-left-leave-active {
  transition: all 0.5s ease;
}

.app_body {
  padding-top: 1.173333rem
    /* 44/37.5 */
  ;
}

a {
  color: rgba(158, 237, 115, 1);
}

@media screen and (min-width: 750px) {
  .app_body {
    padding-top: 90px;
  }

  .mainWrap {
    min-height: calc(100vh - 100px);
  }
}
</style>
