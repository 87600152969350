<script lang='ts' setup>
import Loading from '@/components/common/Loading/Loading.vue'
import { toRefs } from 'vue';
const emits = defineEmits(['click']);
const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    transparent: {
        type: Boolean,
        default: false
    },
    black: {
        type: Boolean,
        default: false
    }
});
const { disabled, loading, transparent, black } = toRefs(props)

const handleClick = (e: any) => {
    if (loading.value) return;
    emits('click', e);
};
</script>
<template>
    <div class="btn-wrap"
        :class="{
            'black': black
        }"
    >
        <button class="btn" 
            :class="[
                {
                    'btn-disabled': disabled,
                    'btn-transparent': transparent,
                    'btn-black': black
                }
            ]"
            :disabled="disabled"
            @click="handleClick"
        >
            <div class="loadingWrap" v-if="loading">
                <Loading :small="true" :dark="true"></Loading>
            </div>
            <span v-else><slot ></slot></span>
        </button>
    </div>
</template>
<style lang='scss' scoped>
.btn-wrap {
    width: 170px;
    height: 40px;
    padding: 1px;
    background-image: linear-gradient(to right, #66E7C7, #B2BB89, #F5735C);
    border-radius: 20px;
    font-size: 14px;
    color: #000000;
    &.black {
        background-image: linear-gradient(to bottom, #939393, #1F1F1F);
    }
}
.btn {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    border: none;
    outline: none;
    border-radius: 20px;
    cursor: pointer;
    background-color: transparent;
    &.btn-disabled {
        // opacity: 0.8;
        color: #000000;
        cursor: default;
    }
    &:hover {
        opacity: 0.9;
    }
    &.btn-transparent {
        background-color: #000;
        span {
            background: linear-gradient(to right, #66E7C7, #B2BB89, #F5735C) !important;
            -webkit-background-clip: text !important;
            background-clip: text !important;
            color: transparent !important;
        }
    }
    &.btn-black {
        background-color: #000;
        span {
            color: #fff;
        }
    }
    .loadingWrap {

    }
}

</style>