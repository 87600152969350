<template>
  <div class="loadWrap flex-center">
    <!-- 加载更多 -->
    <Button 
      class="more" 
      @click="emits('load')" 
      :transparent="true" 
      :loading="loading" 
      :plain="true"
      v-if="hasMore"
    >More<i class='iconfont icon-right'></i></Button>
    <!-- 加载结束 -->
    <div class="loadEnd" v-if="!hasMore && pageSize > 1 && isShowLoaded">Loaded</div>
  </div>
</template>
<script lang='ts' setup>
import Button from '@/components/common/Button/Button.vue'
import { toRefs } from 'vue';
const emits = defineEmits(['load'])
const props = defineProps({
  loading: Boolean,
  hasMore: Boolean,
  pageSize: Number, // 第几页
  isShowLoaded: Boolean, // 是否显示loaded
})
const { loading, hasMore, pageSize, isShowLoaded} = toRefs(props)
</script>
<style lang='scss' scoped>
.loadWrap {
  .more {
    width: 120px;
    height: 40px;
    border: 1px solid #383838;
    border-radius: 8px;
    color: #C1C1C1;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
    .iconfont {
        font-size: .266667rem /* 10/37.5 */;
        display: none;
        transform: rotate(90deg);
        margin-left: 5px;
    }
  }
  .loadEnd {
    font-size: 14px;
    font-weight: 700;
    color: #C1C1C1;
  }
}

</style>