import { ref, type Ref } from "vue";
import { utils } from 'ethers';
import { UniPassPopupSDK } from "@unipasswallet/popup-sdk"
import { type UPAccount as Account } from '@unipasswallet/popup-types';
import $store from "@/store/index";

export interface I_EIP712_DATA_TYPE {
    EIP712Domain: { name: string, type: string }[];
    [name: string]: { name: string, type: string }[];
}
export interface I_EIP712_DATE {
    domain: {
        name?: string;
        version?: string;
        chainId?: number;
        verifyingContract?: string;
        salt?: ArrayBuffer;
    },
    primaryType: keyof I_EIP712_DATA_TYPE,
    types: I_EIP712_DATA_TYPE,
    message: Record<string, unknown>
}

let upWallet: Wallet | undefined;
export function getWallet(): Wallet {
    if (!upWallet) upWallet = new Wallet();
    return upWallet;
}
class Wallet {
    static env: "test" | "prod" = import.meta.env.MODE === 'development' ? "test" : "prod";
    private wallet: UniPassPopupSDK;
    public isLogin: Ref<boolean> = ref(false);
    public account: Account;
    constructor() {
        const CDN_URL = import.meta.env.VITE_APP_CDN_URL
        this.wallet = new UniPassPopupSDK({
            env: Wallet.env,
            chainType: "bsc",
            // chainType: "eth",
            storageType: "localStorage",
            appSettings: {
                appName: "Cassava",
                appIcon: `${CDN_URL}/public/images/logo.png`,
                theme: "cassava" as any,
            },
        });
    }

    async login() {
        return new Promise(async (res, rej) => {
            try {
                this.account = await this.wallet.login({
                    email: true,
                    connectType: "email",
                    authorize: true,
                });
                this.isLogin.value = true;
                res(this.account);
            } catch (err) {
                rej(err);
            }
        })
    }

    getProvider() {
        return this.wallet.getProvider();
    }
    logout() {
        this.isLogin.value = false;
        return this.wallet.logout();
    }

    sign(message: string) {
        return this.wallet.signMessage(message, {
            onAuthChain: true,
            isEIP191Prefix: true,
        });
    }

    signTypedData(eip712Data: I_EIP712_DATE) {
        return this.wallet.signTypedData(eip712Data);
    }

    async preCheck(): Promise<boolean> {
        if (!this.isLogin.value) {
            await this.login();
            return false;
        }
        return true;
    }
    async mint(
        tokenAddress: string,
    ): Promise<{ txHash: string }> {
        return new Promise(async (res, rej) => {
            try {
                await this.preCheck();
                const { address } = this.account;
                const data = new utils.Interface(['function mintTo(address recipient)']).encodeFunctionData("mintTo", [address]);
                const txHash = await this.wallet.sendTransaction({
                    from: address,
                    to: tokenAddress,
                    value: "0x0",
                    data,
                });
                if (await this.getProvider().getTransaction(txHash)) res({ txHash });
                else rej(txHash);
            } catch (err) {
                rej(err)
            }
        })
    }
    // # 如果unipass 有指定错误，可以在这里处理
    async errorFilter(err) {
        if (err?.message === 'user is not login') {
            await this.logout();
            await $store.dispatch('user/logoutAction')
            return false;
        }
        return false;
    }
}