import { MOBILE_BACKGROUND_COLOR } from '@/helper/global.text'
import { RouteRecordRaw } from 'vue-router'

export const APP_PAGE: RouteRecordRaw[] = [
    {
        path: '/app_following',
        name: 'AppFollowing',
        component: () => import('/src/views/app/appFollowing.vue'),
        meta: {
            title: 'My Followings',
            analytics_view: 'my_following_view',
            needManuallyCloseLoading: true,
            is_app_analysis: true, // 是否需要app上报
        }
    },
    {
        path: '/app_checkin',
        name: 'AppCheckin',
        component: () => import('/src/views/app/appCheckin.vue'),
        meta: {
            title: 'Check in',
            analytics_view: 'check_in_view',
            needManuallyCloseLoading: true,
            is_app_analysis: true, // 是否需要app上报
        }
    },
    {
        path: '/app_guide',
        name: 'AppGuide',
        component: () => import('/src/views/app/appGuide.vue'),
        meta: {
            title: 'User Guide',
            analytics_view: "user_guide_view",
            needManuallyCloseLoading: true,
            hideHeaderPadding: true
        }
    }, {
        path: "/app_bind_phone",
        name: "AppBindPhone",
        component: () => import('/src/views/common/BindPhone.vue'),
        meta: {
            title: "Bind Phone",
            analytics_view: "app_bind_phone",
            hideHeaderPadding: true,
            setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
        }
    }, {
        path: "/app_reward_redemption",
        name: "AppRewardRedemption", 
        component: () => import("/src/views/app/AppRewardRedemption.vue"),
        meta: {
            title: "Reward Redemption",
            analytics_view: "app_reward_redemption",
            hideHeaderPadding: true,
            needManuallyCloseLoading: true,
        }
    },
    {
        path: '/quiz',
        redirect: '/quiz/category',
        children: [
            {
                path: 'category',
                name: 'QuizCategory',
                component: () => import('/src/views/app/Quiz/category/Category.vue'),
                meta: {
                    title: 'Quiz to Earn',
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    needManuallyCloseLoading: true,
                    analytics_view: 'quiz_topic_view',
                    is_app_analysis: true, // 是否需要app上报
                },
            },
            {
                path: 'article/:code?',
                name: 'QuizArticle',
                component: () => import('/src/views/app/Quiz/article/Article.vue'),
                meta: {
                    title: 'Quiz to Earn',
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    needManuallyCloseLoading: true,
                    analytics_view: 'quiz_article_view',
                    is_app_analysis: true, // 是否需要app上报
                },
            },
            {
                path: 'answer/:code?', // 答题页
                name: 'QuizAnswer',
                component: () => import('/src/views/app/Quiz/answer/Answer.vue'),
                meta: {
                    title: 'Quiz to Earn',
                    needManuallyCloseLoading: true,
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'quiz_answer_view',
                    is_app_analysis: true, // 是否需要app上报
                    hideHeaderPadding: true
                },
            },
            {
                path: 'result/:status', // 答题页
                name: 'QuizResult',
                component: () => import('/src/views/app/Quiz/result/Result.vue'),
                meta: {
                    title: 'Quiz to Earn',
                    needManuallyCloseLoading: true,
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'quiz_result_view',
                    is_app_analysis: true, // 是否需要app上报
                },
            },
            {
                path: 'leaderboard/:status', // 答题页
                name: 'QuizLeaderboard',
                component: () => import('/src/views/app/Quiz/leaderboard/Leaderboard.vue'),
                meta: {
                    title: 'Quiz to Earn',
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'quiz_leaderboard_view',
                    needManuallyCloseLoading: true,
                    is_app_analysis: true, // 是否需要app上报
                    hideHeaderPadding: true
                },
            },
            {
                path: 'daily', // 答题页
                name: 'DailyQuiz',
                component: () => import('/src/views/app/DailyQuiz/DailyQuiz.vue'),
                meta: {
                    title: 'Quiz to Earn',
                    isRedirect: true, // 是否允许登录后重新跳转回当前页面
                    analytics_view: 'Questions_list_page_view',
                    needManuallyCloseLoading: true,
                    is_app_analysis: true, // 是否需要app上报
                    hideHeaderPadding: true
                },
            },
        ]
    },
    {
        path: "/app/backpack",
        name: "AppBackpack", 
        component: () => import("/src/views/app/MysteryBox/Backpack/Backpack.vue"),
        meta: {
            title: "Mystery Box",
            analytics_view: "app_mysteryBox_histories_view",
            hideHeaderPadding: true,
            needManuallyCloseLoading: true,
        }
    },
    {
        path: "/app/invite_challenge",
        name: "AppInviteChallenge", 
        component: () => import("/src/views/app/InviteChallenge/InviteChallenge.vue"),
        meta: {
            title: "",
            analytics_view: "7daychallenge_view",
            hideHeaderPadding: true,
            needManuallyCloseLoading: true,
        }
    },
    {
        path: '/app/check_ins',
        name: 'AppContinuousCheckIns',
        component: () => import('/src/views/app/CoCheckIns/CoCheckIns.vue'),
        meta: {
            title: '',
            analytics_view: 'continuous_check_ins_view',
            needManuallyCloseLoading: true,
            hideHeaderPadding: true,
            is_app_analysis: true, // 是否需要app上报
        }
    }
]