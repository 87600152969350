import { RouteRecordRaw } from "vue-router";
import { IsMobile } from '../../utils/common';
const r: RouteRecordRaw[] = [
    {
        path: '/safari_of_cassava_network',
        name: 'Safari',
        component: () => import('/src/views/activities/safari/index.vue'),
        meta: {
            title: 'Safari Of Cassava Network',
            noNeedLogin: true,
            hideHeaderPadding: IsMobile(),
            mobileWhiteList: true,
        }
    }, {
        path: "/safari_of_cassava_network_rules",
        name: "SafariRules",
        component: () => import('/src/views/activities/safari/mobileRules.vue'),
        meta: {
            title: 'Rules',
            noNeedLogin: true,
            hideHeaderPadding: true,
            mobileWhiteList: true,
        }
    }
]
export default r