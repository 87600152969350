import { timeIsExists, timeLimit } from "@/utils/time";
import { unixFormat } from "./details/Details.help";
import { SHOW_STATUS_ENDEN, SHOW_STATUS_ONGOING, SHOW_STATUS_SCHEDULED, STATUS_ENDED, STATUS_ONGOING, STATUS_SCHEDULED } from "./Task.text";
import { upcaseFisrt } from "@/utils/common";

export const getFilters = (filter: any[], code?: string): any[] => {
  const arr = []
  filter.sort((a,b)=>{ return a.order - b.order}) // 升序

  filter.forEach(item => {
    if (code === 'platform_code') {
      arr.push({name: item.label, value: item.code as string, isClear: item.code === '' ? true : false, isDefault: item.is_default})
    } else {
      arr.push({label: item.label, value: item.code as string, isDefault: item.is_default})
    }
  })
  return arr
}

export interface ICountItem {
  id: number
  status: string
  count: number
}

export const countDownFormat = (count: number) => {
  const time = timeLimit(unixFormat(count), 2)
  const optionsText = ["Day", "Hour", "Minute", "Second"]
  return time.reduce((total: string, next: number, index: number) => total += timeIsExists(next, optionsText[index]), "");
}

// 获取显示的状态name
export const getDisplayStatusName = (status: string) => {
  if (status === STATUS_ONGOING) {
    return SHOW_STATUS_ONGOING
  } else if (status === STATUS_SCHEDULED) {
    return SHOW_STATUS_SCHEDULED
  } else if (status === STATUS_ENDED) {
    return SHOW_STATUS_ENDEN
  } else {
    return upcaseFisrt(status)
  }
} 