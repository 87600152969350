import { MOBILE_BACKGROUND_COLOR } from '@/helper/global.text'
import { RouteRecordRaw } from 'vue-router'

export const RULES_PAGE: RouteRecordRaw[] = [
    {
        path: '/rules',
        children: [
            {
                path: "mystery_box",
                name: "MysteryBoxRules",
                component: () => import('/src/views/app/Rules/MysteryBoxRules.vue'),
                meta: {
                    title: 'Rules',
                    hideHeaderPadding: true,
                    mobileWhiteList: true,
                    analytics_view: "unbox_rules_view",
                },
            },
            {
                path: "mining",
                name: "MiningRules",
                component: () => import('/src/views/app/Rules/MiningRules.vue'),
                meta: {
                    title: "Rules",
                    hideHeaderPadding: true,
                    mobileWhiteList: true,
                    analytics_view: "mining_rules_view",
                }
            },
            {
                path: 'invite_challenge',
                name: 'InviteChallengeRules',
                component: () => import('/src/views/app/Rules/InviteChallengeRules.vue'),
                meta: {
                    title: 'Rules',
                    hideHeaderPadding: true,
                    mobileWhiteList: true,
                    analytics_view: "invite_challenge_rules_view",
                }
            },
            {
                path: 'leaderboard',
                name: 'CassavaLeaderboardRules',
                component: () => import('/src/views/cassavaLeaderboard/CassavaLeaderboardRules.vue'),
                meta: {
                    title: 'Cassava Leaderboard Rules',
                    hideHeaderPadding: true,
                    mobileWhiteList: true,
                    analytics_view: "cassava_leaderboard_rules_view",
                    setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
                }
            }
        ]
    }
]