
import { getAvatarComponents } from '@/api/avatar';
import { getFormatName, strTransform } from '@/utils/common';
import { ActionContext, GetterTree } from 'vuex';
import { AllState } from '..';
import { IsMobile } from '../../utils/common';

export interface IComponentsCommon {
  type: string
  count: number
}

export interface IComponents {
  component_types: string,
  components: IComponentsCommon[],
  id: number,
  name: string,
  placeholders: IComponentsCommon[]
}
export interface IAvatarState {
  baseUrl: string,
  imgExt: string,
  currentCategoryId: number,
  categories: IComponents[]
  name: string
  avatarSort: string[]
  displayName: string,
  displayStory: string
  avatarUrl: string
  avatarId: number
}

const state: IAvatarState = {
  baseUrl: '', // 图片地址
  imgExt: 'png', // 图片后缀 
  currentCategoryId: 0, // 当前选择的种族id,
  categories: [], // 脸谱组件所有数据
  name: '', // 当前选择的种族name
  avatarSort: [], // 图片叠加的顺序
  displayName: '', // 展示的name
  displayStory: '', // 展示的story
  avatarUrl: '',
  avatarId: 0
}


export default {
  namespaced: true, // 该模块就成为命名空间模块了
  // state,
  state,
  mutations: {
    setAvatarInfo(state: IAvatarState, info: any) {
      state.baseUrl = info.baseUrl
      state.imgExt = info.imageExt
      state.categories = info.categories
    },
    setSelectInfo(state: IAvatarState, info: any) {
      state.currentCategoryId = info.id,
      state.name = info.name
      state.avatarSort = info.avatarSort
    },
    setCurrentCategoryId(state: IAvatarState, id: number) {
      state.currentCategoryId = id
    },
    setImageInfo(state: IAvatarState, info: any) {
      state.baseUrl = info.baseUrl
      state.imgExt = info.imageExt
      state.avatarUrl = info.avatarUrl
      state.avatarId = info.avatarId
    },
    setDisplayShow(state: IAvatarState, info: any) {
      state.displayName = info.displayName
      state.displayStory = info.displayStory
    }
  },
  actions: {
    getCategoriesAction(context: ActionContext<IAvatarState, AllState>, id?: number) {
      return new Promise((resolve, reject) => {
        if (!context.state.categories.length || !context.state.baseUrl) {
          getAvatarComponents().then((e: any) => {
            const categories = e.data.data.categories
            let realId = categories[0].id
            let realName = categories[0].name
            let realAvatarSort = strTransform(categories[0].component_types, true)
            let displayName = categories[0].display_name
            let displayStory = categories[0].display_story
  
            if (id) {
              realId = id
              const findCategory = categories.find((i: any) => i.id === id)
              realName = findCategory.name
              realAvatarSort = strTransform(findCategory.component_types, true)
              displayName = findCategory.display_name
              displayStory = findCategory.display_story
            }
            context.commit('setSelectInfo', {id: realId, name: realName, avatarSort: realAvatarSort})
            context.commit('setAvatarInfo', {categories,  baseUrl: e.data.data.base_url, imageExt: e.data.data.image_ext})
            context.commit('setDisplayShow', {displayName:  displayName, displayStory: displayStory})
            resolve(categories);
          }).catch((err: any) => {
            reject(err);
          })
        } else {
          resolve(context.state.categories)
        }
      })
    }
  },
  getters: {
    // 获取种族 预览图片
    getCategoryPre: (state: IAvatarState) => (name?: string) => {
      const realName = getFormatName(name || state.name)
      return `${state.baseUrl}/category/${realName}/preview.${state.imgExt}`
    },
    // 获取种族背景图
    getCategoryPoster: (state: IAvatarState) => (name?: string) => {
      const realName = getFormatName(name || state.name)
      const posterName = IsMobile() ? 'poster_mobile' : 'poster_pc'
      return `${state.baseUrl}/category/${realName}/${posterName}.${state.imgExt}`
    },
    // 获取种族 占位图片
    getCategoryPlaceholder: (state: IAvatarState) => (name?: string) => {
      const realName = getFormatName(name || state.name)
      return `${state.baseUrl}/category/${realName}/placeholder.${state.imgExt}`
    },
    // 获取组件图片
    getTypeComponent: (state: IAvatarState, getters: GetterTree<IAvatarState, void>) => (type: string, id: string, name?: string, ) => {
      const realName = getFormatName(name || state.name)
      return `${state.baseUrl}/category/${realName}/component/${type}/${id}.${state.imgExt}`
    },
    // 获取组件占位图片
    getTypePlaceholder: (state: IAvatarState, getters: GetterTree<IAvatarState, void>) => (type: string, id: string, name?: string) => {
      const realName = getFormatName(name || state.name)

      return `${state.baseUrl}/category/${realName}/placeholder/${type}/${id}.${state.imgExt}`
    },
    // 获取组件的zIndex
    getZIndexByType: (state: IAvatarState) => (type: string) => {
      if (state.avatarSort) {
        let zIndex = state.avatarSort.indexOf(type)
        zIndex = zIndex > 0 ? zIndex + 3 : zIndex
        return zIndex
      } else {
        const currentCategory = state.categories.find(o => o.id === state.currentCategoryId) as IComponents
        const avatarSort = strTransform(currentCategory.component_types, true)
        state.avatarSort = avatarSort
        let zIndex = avatarSort.indexOf(type)
        zIndex = zIndex > 0 ? zIndex + 3 : zIndex
        return zIndex
      }
      
    }
  }
}