import instance from '@/utils/request'
import { type BASE_RESPONSE, type BASE_PAGING_RESPONSE } from './types';
export interface BANNER_ITEM {
    "title": string,
    "description": string,
    "image_url": string,
    "link_url": string,
    "link_open_mode": {
        "mode": "blank" | "self"
    },
    "buttons": {
        "title": string,
        "style": "primary" | "secondary",
        "link_url": string,
        "link_open_mode": {
            "mode": "blank" | "self"
        }
    }[]
}
export function getBanner(bannerType: 'community.banner' | 'main_page.banner' | 'task_center.banner' | 'mysterybox.banner'): Promise<BASE_PAGING_RESPONSE<BANNER_ITEM>> {
    return instance({
        url: 'v1/banner/web',
        method: 'GET',
        params: {
            name: bannerType
        }
    })
}
// # 获取新邀请用户的奖励数据
export function getBoardingUrl() {
    return instance({
        url: 'v1/accounts/referral/rewards',
        method: 'GET'
    })
}


export function getRedemptionOverview() {
    return instance({
        url: 'v1/competitions/redemptions/overview',
        method: 'GET',
    })
}

export function redemptions(data){
    return instance({
        url: 'v1/competitions/redemptions',
        method: 'POST',
        data
    })
}