import { getWallet } from "./unipass";
import { loginApi } from "@/api/user";
import $store from "@/store/index";
import $router from "@/router/index"
import { ready } from 'eft-bridge';
import Message from "@/components/common/Message/Message";
import { gtagEvent } from "./global.method";
import { generateNonce } from "@/utils/walletUtils";
import { storageLastLoginType, storageUserProfile } from "@/utils/storage";


const wallet = getWallet();
function loading(flag: boolean) {
    $store.commit('globalLoading', flag);
}
export async function login() {
    return new Promise((resolve) => {
        loading(true);
        $store.commit('user/clearState')
        window.sessionStorage.clear();
        ready(async ({ bridge, isApp }) => {
            try {
                if (isApp)
                    await appLogin(bridge);
                else
                    await webLogin();
                resolve(void 0);
            } finally {
                loading(false)
            }
        });
    })
}
async function appLogin(bridge) {
    try {
        const res = await bridge.call({ "opt": "login" });
        console.log('bridge login res: ', res);
        if (res.body.success === 1 || res.body.success === true) {
            window.location.reload();
        }
    } catch (err) {
        console.log('appLogin err', err);
    }
}
async function webLogin() {
    gtagEvent('login')
    $router.push({
        name: "EmailLogin"
    })
    // try {
    //     gtagEvent('login')
    //     const walletLoginData = await wallet.login();
    //     await sendWebLoginApi(walletLoginData)
    // } catch (err) {
    //     await wallet.errorFilter(err)
    //     // TODO unipass 点击x 或者取消登录时，会报错；
    // }
}
export async function sendWebLoginApi(walletLoginData, cb?) {
    try {
        const res = await loginApi(walletLoginData);
        if (res) {
            const data = res.data
            if (!data.data.has_init) signup(walletLoginData);
            else setStoreLoginData(data);
            typeof cb === "function" && cb(data);
        }
    } catch (err) {
        if (err?.data?.code === 'THE_ACCOUNT_HAS_BEEN_CLOSED') {
            Message({
                text: err?.data?.msg,
                type: 'error'
            })
            return;
        }
        if (err?.response?.data?.code === 'INVALID_UNIPASS_SIGNATURE' || err?.data?.code  === 'UNIPASS_NEW_USER_NOT_ALLOWED') {
            setTimeout(() => {
                window.localStorage.clear();
                window.sessionStorage.clear();
                window.location.replace("/");
            }, 3000);
            return;
        }
    }
}
export async function refreshToken() {
    const lastLoginType = storageLastLoginType.get();
    if (lastLoginType !== storageLastLoginType.enum.unipass) {
        $router.push({
            name: 'EmailLogin',
        })
        return;
    }
    try {
        $store.commit("globalLoading", true);
        const { host, origin, pathname } = window.location;
        const provider = await wallet.getProvider();
        const network = await provider.getNetwork();
        const chainId = network.chainId;
        const nonce = generateNonce();
        const currentDate = new Date();
        const now = currentDate.toISOString();
        const userProfile = storageUserProfile.get();
        // 过期一天
        const expire = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24).toISOString();
        const message = `${host} wants you to sign in with your Ethereum account:\n${userProfile.unipass_address}\n\nemail: ${userProfile.email}\n\nURI: ${origin}${pathname}\nVersion: 1\nChain ID: ${chainId}\nNonce: ${nonce}\nIssued At: ${now}\nExpiration Time: ${expire}`
        const walletLoginData = {
            address: userProfile.unipass_address,
            email: userProfile.email,
            message: message,
            signature: ''
        }
        walletLoginData.signature = await wallet.sign(walletLoginData.message);
        await sendWebLoginApi(walletLoginData, (data) => {
            if (data?.data?.token) {
                Message({ text: 'Login successful', type: 'success', delay: 2000 })
                storageLastLoginType.set(storageLastLoginType.enum.unipass);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                Message({ text: 'Login failed please try again', type: 'error', delay: 2000 })
                setTimeout(() => {
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.location.replace("/");
                }, 2000);
            }
        });
    } catch (err) {
        console.log('err: ', err);
        await wallet.errorFilter(err)
        // TODO 参数错误，或者取消登录时，会报错； 因为只有可能是token失效，所以要后退。
        $store.commit('user/clearState')
        window.localStorage.clear();
        //TODO why，清理状态，防止到首页，还是会有token
        setTimeout(() => {
            $router.replace('/')
        }, 1);
    } finally {
        $store.commit("globalLoading", false);
    }
}
function signup(walletLoginData: any) {
    $router.push({
        name: 'Signup',
    })
}

export async function logout() {
    loading(true);
    return new Promise(async (res) => {
        Promise.all([
            new Promise(async (res) => {
                await wallet.logout()
                res(void 0);
            }),
            new Promise(async (res) => {
                await $store.dispatch('user/logoutAction')
                res(void 0);
            }),
        ]).finally(() => {
            window.sessionStorage.clear();
            loading(false);
            res(void 0);
        })
    })
}

export function setStoreLoginData(data: any) {
    $store.commit('user/setToken', [data.data.token, data.data.expire_at]);
    $store.commit('user/setInfo', data.data)
}