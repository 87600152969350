export function shortenAddress(str = '') {
    return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

export function shorten(str: string, key?: any): string {
    if (!str) return str;
    let limit;
    if (typeof key === 'number') limit = key;
    if (key === 'symbol') limit = 6;
    if (key === 'name') limit = 64;
    if (key === 'choice') limit = 12;
    if (limit)
        return str.length > limit ? `${str.slice(0, limit).trim()}...` : str;
    return shortenAddress(str);
}

export const replaceAccountId = (accountId) => {
    const str = accountId.toString()
    const id = str.replace(str.substring(4, str.length - 4), '....')
    return 'Cassava ID #' + id
  }
  
export function shortenAddressSlice(str: string, start: number, end: number) {
    return `${str.slice(0, start)}...${str.slice(str.length - end)}`;
}