import { useBridge } from "eft-bridge"
import { initTerminal ,initErudaTerminal} from "./terminal";

async function init() {
    let { isApp, isInit } = useBridge();

    // import.meta.env.MODE === 'development' && await initErudaTerminal();
    
    if (isApp && isInit) {
        // import.meta.env.MODE === 'development' && await initTerminal();
        import.meta.env.MODE === 'development' && await initErudaTerminal();
        // initErudaTerminal();
    }
}

init();