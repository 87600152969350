export const PARTICIPATED_ROUTE = {
    path: 'myParticipated', //  我参与的任务
    name: 'MyParticipated',
    redirect: '/myPortal/myParticipated/myTasks',
    component: () => import('/src/views/myPortal/myParticipated/MyParticipated.vue'),
    meta: {
        title: 'Participated',
        showChildrenMenu: true,
        isBack: false,
        isHideMobile: true,
    },
    children: [
        {
            name: "MyTasks",
            path: 'myTasks',
            meta: {
                title: 'My Tasks',
                isBack: false,
                isRedirect: true, // 是否允许登录后重新跳转回当前页面
                analytics_view: 'my_tasks_view'
            },
            component: () => import('/src/views/myPortal/myParticipated/myTasks/index.vue'),
        },
        {
            name: "MyFollowings",
            path: 'myFollowings',
            meta: {
                title: 'My Followings',
                isBack: false,
                isRedirect: true, // 是否允许登录后重新跳转回当前页面
                analytics_view: 'my_following_view'
            },
            component: () => import('/src/views/myPortal/myParticipated/myFollowings/index.vue'),
        },
    ]
}