// 生成随机数 返回介于 min（包括）和 max（不包括）之间的随机数：

import { ref } from "vue";
import { useBridge } from "eft-bridge";
// 生成随机数 返回介于 min（包括）和 max（都包括）之间的随机数： Math.floor(Math.random() * (max - min)) + min;
export const createRandom = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min)) + min;
}

// 获取assets静态资源
export const getAssetsFile = (url: string) => {
  return new URL(`../assets/images/${url}`, import.meta.url).href
}

// 判断是否是移动端
export const IsMobile = () => {
  let plat = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return plat ? true : false
}

// 判断是Android 还是 ios
export const isAndroidOrIOS = () => {
  const u = navigator.userAgent
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    // android终端
    return 'android'
  } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    // ios终端
    return 'ios'
  } else {
    return ''
  }
}

// name转写 Queen Idia -> queen_idia
export const getFormatName = (name: string) => {
  if (!name) return ''
  const localeLowerCaseName = name.toLocaleLowerCase()
  return localeLowerCaseName.replace(/ /g, '_')
}

export const scrollAnimation = (currentY: number, targetY: number) => {
  // 获取当前位置方法
  // const currentY = document.documentElement.scrollTop || document.body.scrollTop
  // 计算需要移动的距离
  let needScrollTop = targetY - currentY
  let _currentY = currentY
  setTimeout(() => {
    // 一次调用滑动帧数，每次调用会不一样
    const dist = Math.ceil(needScrollTop / 10)
    _currentY += dist
    window.scrollTo(_currentY, currentY)
    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
    if (needScrollTop > 10 || needScrollTop < -10) {
      scrollAnimation(_currentY, targetY)
    } else {
      window.scrollTo(_currentY, targetY)
    }
  }, 1)
}

/**
* 获取浏览器版本号
* n 传为浏览器类型 或 版本号
* 传 'n' 返回浏览器类型
* 传 'v' 返回浏览器版本
* 传 '' 返回浏览器类型 + 浏览器版本 
*/

export const getBrowser = (n: any) => {
  var ua = navigator.userAgent.toLowerCase(),
    s,
    name = '',
    ver = 0;
  //探测浏览器
  (s = ua.match(/edge\/([\d.]+)/)) ? _set("ie", _toFixedVersion(s[1])) :
    (s = ua.match(/msie ([\d.]+)/)) ? _set("ie", _toFixedVersion(s[1])) :
      (s = ua.match(/firefox\/([\d.]+)/)) ? _set("firefox", _toFixedVersion(s[1])) :
        (s = ua.match(/chrome\/([\d.]+)/)) ? _set("chrome", _toFixedVersion(s[1])) :
          (s = ua.match(/opera.([\d.]+)/)) ? _set("opera", _toFixedVersion(s[1])) :
            (s = ua.match(/version\/([\d.]+).*safari/)) ? _set("safari", _toFixedVersion(s[1])) : 0;

  function _toFixedVersion(ver: any, floatLength?: any) {
    ver = ('' + ver).replace(/_/g, '.');
    floatLength = floatLength || 1;
    ver = String(ver).split('.');
    ver = ver[0] + '.' + (ver[1] || '0');
    ver = Number(ver).toFixed(floatLength);
    return ver;
  }
  function _set(bname: any, bver: any) {
    name = bname;
    ver = bver;
  }
  return (n == 'n' ? name : (n == 'v' ? ver : name + ver));
};

// 获取发送邮件的参数
export const getTemplateArgs = (code: string, email: string) => {
  let template_args = {}
  if (code === '1') { // 注册
    template_args = {
      email
    }
  } else if (code === '2') {
    template_args = {
      email,
      client_ip: '',
      client_time_rfc3339: new Date().toUTCString(),
      client_dev: getBrowser('')
    }
  } else if (code === '3' || code === '4') {
    template_args = {
      old_email: email,
      account_id: '',
      client_ip: '',
      client_time_rfc3339: new Date().toUTCString(),
    }
  }
  return template_args
}

// 字符串转数组 isReverse 是否需要翻转数组，默认为true
export const strTransform = (str: string, isReverse?: boolean) => {
  if (isReverse) {
    return str.split(',').reverse()
  }
  return str.split(',')
}

export type I_HOST_NAME = 'pc' | 'mobile';
export function getHostName(): I_HOST_NAME {
  return IsMobile() ? 'mobile' : 'pc';
}

// 整合story格式，后端返回的是带有\\n的字符串
export const formatStory = (story: string) => {
  const storyArr = story.split('\\n\\n')
  let str = ``
  storyArr.forEach((item) => {
    if (item) str += `<div>${item}</div>`
  })
  return str
}
// 获取url中参数
export const getUrlParams = (url: string) => {
  // 通过 ? 分割获取后面的参数字符串
  if (url.indexOf('?') < 0) return {}
  let urlStr = url.split('?')[1]
  urlStr = urlStr.split('#')[0]
  // 创建空对象存储参数
  let obj = {};
  // 再通过 & 将每一个参数单独分割出来
  let paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    let arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1];
  }
  return obj
}

// 判断是否是从 Cassava App打开的webview
export const isCassavaWebView = () => {
  const { isApp } = useBridge();
  return isApp;
  // const u = navigator.userAgent
  // if (u.indexOf('Cassava') > -1) return true
  // return false
}

/* 节流 */
export const myThrottle = (fn: () => void, delay: number | undefined) => {
  const isThtottle = ref(true)
  return () => {
    if (!isThtottle.value) return
    isThtottle.value = false
    setTimeout(() => {
      fn()
      isThtottle.value = true
    }, delay)
  }
};

// 数字千位分隔符
export const formatNumber = (num: number | string, bit: number = 0) => {
  if (!num) return num;
  return FloatToFixed(num, bit).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // ↓不支持截断多余0 ，如果有问题，可以临时替换之前的 2023/05/06
  // return parseFloat(num as string).toFixed(bit).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // ↓不支持整数 ，如果有问题，可以临时替换之前的 2023/01/19
  // return parseFloat(num as string).toFixed(bit).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

// 获取数据类型
export const getType = (x) => {
  const type = Object.prototype.toString.call(x) // '[object String]'
  const sapceIndex = type.indexOf(' ') // 找到空格的索引
  const resType = type.slice(sapceIndex + 1, -1) // ‘String'
  return resType.toLowerCase() // 转小写 'string '
}

// 判断对象是否为空
export const isEmptyObj = (obj: object) => {
  // 如果不是对象，则返回false
  if (getType(obj) !== 'object') return false
  return Object.keys(obj).length > 0
}

// Merging multiple objects; It's shallow copy;
export function merge(...args: Record<string, any>[]) {
  return Object.assign({}, ...args);
}

export function formateNumberWithK(num: string | number) {
  if (isNaN(Number(num))) return "";
  const numInt = parseInt(num as string) / 1000;
  if (numInt < 1) return `${num}`;
  else return `${formatNumber(numInt, 1)}K`;
}

export function formateNumberWithM(num: string | number) {
  if (isNaN(Number(num))) return "";
  const numInt = parseInt(num as string) / 1_000_000;
  if (numInt < 1) return `${formateNumberWithK(num)}`;
  else return `${formatNumber(numInt, 1)}M`;
}

export function formateNumberWithG(num: string | number) {
  if (isNaN(Number(num))) return "";
  const numInt = parseInt(num as string) / 1_000_000_000;
  if (numInt < 1) return formateNumberWithM(num)
  else return `${formatNumber(numInt, 1)}G`;
}

// rfc3339格式日期转标准格式日期
export function formateDateFromRfc3339(dateStr: string) {
  if (!dateStr) return "";

  var date = new Date(dateStr).toJSON();
  var newDate = new Date(+new Date(date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');

  return newDate
}

// 首字母大写
export const upcaseFisrt = (name: string) => {
  if (!name) return ''
  const lowerName = name.toLocaleLowerCase()
  return lowerName[0].toUpperCase() + lowerName.substring(1)
}

// 列表的数据
export const listDataFormat = (params: object = {}) => {
  return {
    offset: 0,
    limit: 0, // 条目数
    total: 0, // 总数
    list: [], // 数据
    hasMore: false, // 是否有更多数据
    ...params
  }
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

// 时间格式化
export function formatDate(unix, fmt: string = 'yyyy-MM-dd hh:mm') {
  let date = new Date(unix * 1000);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }

  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };

  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }

  return fmt;
}

export function formatDateUTC(unix, fmt: string = 'yyyy-MM-dd hh:mm') {
  let date = new Date(unix * 1000);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getUTCFullYear() + "").substr(4 - RegExp.$1.length));
  }

  let o = {
    "M+": date.getUTCMonth() + 1,
    "d+": date.getUTCDate(),
    "h+": date.getUTCHours(),
    "m+": date.getUTCMinutes(),
    "s+": date.getUTCSeconds()
  };

  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }

  return fmt;
}

// 原生APP打开某个页面需要的参数，是字典转成JSON再进行encode的字符串，
export const encodeURLSchemeParams = (params) => {
  return encodeURI(JSON.stringify(params))
}

// 下载文件
export const downloadFile = (url: string, fileName?: string) => {
  window.open(url, '_blank')
}
export const downloadFileByDom = (url: string, fileName?: string) => {
  const eleLink = document.createElement('a');
  eleLink.setAttribute('href', url);
  fileName && eleLink.setAttribute('download', fileName);
  eleLink.style.display = 'none';
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
}

export const downloadImage = (url: string, fileName?: string) => {
  let img = new Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  img.onload = function () {
    let canvas = document.createElement('canvas')
    let context = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    context.drawImage(img, 0, 0, img.width, img.height)
    let url = canvas.toDataURL('images/png')
    let a = document.createElement('a')
    let event = new MouseEvent('click')
    a.download = fileName || 'default.png'
    a.href = url
    a.dispatchEvent(event)
  }
  img.src = url + '?v=' + Date.now()
}

// webview loading
export const uploadWebviewLoading = (isShow: number) => {
  if (isCassavaWebView()) {
    const { bridge } = useBridge();
    console.log('bridge call updateLoadingView');
    bridge.call({ opt: 'updateLoadingView', body: { display: isShow } })
  }
}

// 对比两个版本号字符串大小
export function compareVersion(v1: string, v2: string): 0 | -1 | 1 {
  const v1l = v1.split('.').slice(0, 3);
  const v2l = v2.split('.').slice(0, 3);
  const len = Math.max(v1l.length, v2.length)

  while (v1l.length < len) {
    v1l.push('0')
  }
  while (v2.length < len) {
    v2l.push('0')
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1l[i])
    const num2 = parseInt(v2l[i])

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }

  return 0
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// 将n转换为toFixed格式的字符串,截断多余.000，如果n不是数字，返回isNaNReturn
export function FloatToFixed(n: string | number, fix: number, isNaNReturn: any = 0) {
  n = returnNumber(n, isNaNReturn);
  if (n % 1 === 0) {
    return String(n)
  } else {
    return String(parseFloat(n.toFixed(fix)));
  }
}

// 将n转换为数字，如果n不是数字，返回isNaNReturn
export function returnNumber(n: any, isNaNReturn: number = 0): number {
  if (typeof n !== 'number') n = Number(n);
  if (isNaN(n)) {
    console.warn('returnNumber, n is NaN:', n);
    return isNaNReturn
  } else return n;
}

// 转换日期格式成 0:11 May 22nd
export function transformDate(timestamp: number): string {
  const _timestamp = timestamp.toString().length > 10 ? timestamp : timestamp * 1000;
  const date = new Date(_timestamp);
  const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May.", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];
  const minutes = date.getMinutes().toString().padStart(2, "0");
  // const hour = date.getHours() % 12 || 12;
  // const ampm = date.getHours() >= 12 ? 'pm' : 'am';
  const hour = date.getHours();
  const monthName = monthNames[date.getMonth()];
  const dayNumber = date.getDate();
  let daySuffix;

  switch (dayNumber) {
    case 1:
    case 21:
    case 31:
      daySuffix = "st";
      break;
    case 2:
    case 22:
      daySuffix = "nd";
      break;
    case 3:
    case 23:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }

  return `${hour}:${minutes} ${monthName} ${dayNumber}${daySuffix}`;
}


export function isInWechatBrowser() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}

export async function isInstalledExtension() {
  if (chrome) {

    return new Promise((resolve) => {
      let img = new Image()
      const src = 'chrome-extension://' + import.meta.env.VITE_CHROME_EXTENSION_ID + '/assets/logo48.png'
      img.src = src

      img.onload = () => {
        console.log('img onload');
        resolve(true)
      }
      img.onerror = () => {
        console.log('img onerror');
        resolve(false)
      }
    })
  }
}

export function isPhantomWebview() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('phantom') > -1
}

// phantom: User Agent: Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Phantom/ios/24.9.1.19301
// solflare User Agent: Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148
// okx: User Agent: Mozilla/5.0 (iPhone; CPU iPhone OS 16_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 OKEx/6.70.1 (iPhone;U;iOS 16.2;zh-CN/zh-CN) locale=zh-CN statusBarHeight/141 OKApp/(OKEx/6.70.1) brokerDomain/www.okx.com brokerId/0 jsbridge/1.1.0 theme/light

export function isOKXWalletWebview() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('okapp') > -1
}

export function isWalletWebview() {
  if (IsMobile()) {
      if (isPhantomWebview() || window.solflare || isOKXWalletWebview()) {
          return true
      }
  }

  return false
}