<template>
  <Modal title="Collection" :is-show-close="!IsMobile()" :is-custom="true" :is-show-bg="!IsMobile()" :modal-wrap-style="modalStyle">
    <template #content>
      <div class="collection">
        <div class="text">Support for items transfer is coming soon.</div>
        <div class="placeholderImg">
          <Image class="imgStyle" v-if="IsMobile()" :src="collectionModalImg"></Image>
          <Image class="imgStyle" v-else :src="collectionModalPc"></Image>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script lang='ts' setup>
import Modal from '@/components/common/Modal/Modal.vue';
import Image from '@/components/common/Image/Image.vue';
import { IsMobile } from '@/utils/common';
// import collectionModalImg from '@/assets/images/linshi/collectionModal.png'
import collectionModalImg from '@/assets/images/pc/collectionModal.png'
import collectionModalPc from '@/assets/images/pc/collectionModal.png'
import { onMounted } from 'vue';

const modalStyle = IsMobile() ? 'top: 200px;' : 'width:580px;height:390px;top: 400px;'

onMounted(() => {
  if (IsMobile()) document.body.style.overflow = 'auto'
})

</script>
<style lang='scss' scoped>
.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: rgba(255,255,255,.8);
  margin-bottom: .64rem /* 24/37.5 */;
}
@media screen and (min-width: 750px) {
  .text {
    font-size: 14px;
    // padding-left: 11px;
    margin-bottom: 24px;
    text-align: left;
    margin-top: 10px;
  }
  .imgStyle {
    width: 528px;
    height: 258px;
  }
}
</style>