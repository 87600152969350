import { RouteRecordRaw } from 'vue-router'

export const BUSINESS_PARTNER_ROUTERS: RouteRecordRaw[] = [
    {
        path: '/bp',
        children: [
            {
                path: "cubic",
                name: "cubic",
                component: () => import('/src/views/bp/cubic.vue'),
                meta: {
                    title: 'Cassava X Cubic',
                    hideHeaderPadding: true,
                    mobileWhiteList: true,
                    analytics_view: "bp_cubic_view",
                },
            },
        ]
    }
]