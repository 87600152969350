<script setup lang="ts">
import Button from '@/components/commonV2/ButtonWeb/Button.vue';
import Logo from '@/components/layout/common/Logo.vue';
import emitter from '@/utils/EventBus';
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { reactive, ref, watch, computed, onBeforeUnmount } from 'vue';
import { navList } from '@/utils/navList';
import { login } from '@/helper/account'

import TaskCenter from '@/views/taskCenter/TaskCenter.vue';
import Collection from '@/views/collection/Collection.vue';
import Message from '../common/Message/Message';
import { checkIn } from '@/api/taskCenter';
import { CHECK_IN_SUCCESS } from '@/helper/global.text';
import { getNewbieTaskInfo } from '@/api/user';
import { isInstalledExtension } from '@/utils/common';

const sidebarList = reactive(navList)

const store = useStore()
const router = useRouter()
const route = useRoute()
// 是否显示logo
const isShowLogo = ref(true)

const IsMobile = computed(() => store.state.global.isMobile)

// 当前url路径
let currentPath = ref('/')
// token
const token = computed(() => store.state.user.token)
// 用户信息
const userProfile = computed(() => store.state.user.userProfile)

// 注册路径
const registerPath = '/register';
// 新注册路径
const signupPath = '/signup'
const useRouterCurrent = reactive(router)
const isShowModal = ref(false)

// 应该显示那个弹窗
const showComponent = ref(false)
// 当前点击的组件名称 只用于coming soon的两个tab页面
const activeComponent = ref('')

const isShowExtension = ref(false)

watch(useRouterCurrent, (o) => {
    currentPath.value = o.currentRoute.path
})

isInstalledExtension().then((res: boolean) => {
    isShowExtension.value = !res
})

// sidebar 开关
const onSwitchClick = () => {
    isShowLogo.value = false
    emitter.emit('onOpenSwitch')
};

const onCloseSwitch = () => {
    isShowLogo.value = true
}

emitter.on('onCloseSwitch', onCloseSwitch)


// 去注册
const onGoRegister = () => {
    router.push({ path: registerPath })
};

// 显示modal
const onShowModal = (url: string) => {
    document.body.style.overflow = 'hidden'
    showComponent.value = url === '/collection' ? false : true
    activeComponent.value = url
    isShowModal.value = true
}

const onClose = () => {
    isShowModal.value = false
}

// 签到
const onAttendance = () => {
    checkIn({}).then(res => {
        Message({ type: 'success', text: CHECK_IN_SUCCESS })
    }).catch(err => {
        console.log(err);
    })
}

// go newbie task info
const onGoNewbieTaskPage = () => {
    store.commit('globalLoading', true);
    // get newbie task info
    getNewbieTaskInfo().then(res => {
        const newbieTaskInfo = res.data.data
        store.commit('user/setNewbieTaskId', newbieTaskInfo.quest_id)
        let url = router.resolve({ name: 'TaskDetails', params: { id: newbieTaskInfo.quest_id } })
        window.open(url.href, '_blank')
    }).finally(() => {
        store.commit('globalLoading', false);
    })
}


onBeforeUnmount(() => {
    emitter.off('onCloseSwitch', onCloseSwitch)
})
// # unipass login 
function unipassLogin() {
    login();
}

function inviteClick() {
    store.commit('setInviteBox', true);
}

</script>
<template>
    <div class="header" v-if="!IsMobile" :class="{ 'app_body': route.meta.hideHeaderPadding, 'opacity': route.meta.opacity}">
        <div class="center">
            <div class="left">
                <Logo v-show="isShowLogo" @switchClick="onSwitchClick" />
                <div class="leftPlaceholder">
                    <div class="routerListWrap">
                        <div class="routerList flex-center" v-for="(item, index) in sidebarList" :key="index">
                            <router-link active-class="active" v-if="item.location" class="routerEle"
                                :class="{ 'noClassActive': isShowModal, 'specialStyle': item.rightIcon }"
                                :to="item.url">{{ item.title }}</router-link>
                            <div class="routerEle" :class="{ 'active': isShowModal && activeComponent === item.url }" v-else
                                @click="onShowModal(item.url)">{{ item.title }}</div>
                            <i v-if="item.rightIcon" class='iconfont' :class="item.rightIcon"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right flex-center">
                <div class="extension" v-if="!IsMobile && userProfile?.referral_info?.referral_code" style="width: 160px;">
                    <a @click.stop="inviteClick" target="_blank">
                        <span>Invite Friends</span>
                    </a>
                </div>

                <div class="extension" v-if="!IsMobile && isShowExtension">
                    <a href="https://chromewebstore.google.com/detail/cassava-network/dfahgnafbddihjlebeimhkhldlbceggh?hl=zh-CN&utm_source=ext_sidebar" target="_blank">
                        <span>Download Extension</span>
                    </a>
                </div>

                <div class="btn" v-if="!token">
                    <Button  v-if="currentPath !== signupPath" class="loginBtn" @click="unipassLogin">Login / Sign up</Button>
                </div>

                <!-- login: username -->
                <div class="userOpration flex-center" v-else>
                    <div class="newbieTask" v-if="!userProfile.claimed_newbie_task" @click="onGoNewbieTaskPage">
                        <span>Welcome gift</span>
                        <i class='iconfont icon-right'></i>
                    </div>

                    <router-link to="/myPortal">
                        <div class="username">
                            <img :src="userProfile.avatar" alt="" v-if="userProfile.avatar">
                            <i class="usernameIcon" v-else></i>
                        </div>
                    </router-link>

                </div>
            </div>
        </div>
        
    </div>

    <!-- pc端 collection和taskCenter 弹窗 -->
    <component v-if="isShowModal" :is="showComponent ? TaskCenter : Collection" @close="onClose"></component>
</template>



<style lang="scss" scoped>
.header {
    padding: 0 0.453333rem
        /* 17/37.5 */
    ;
    height: 1.173333rem
        /* 44/37.5 */
    ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    background-color: #080908;
    font-family: 'Poppins';
    &.app_body {
        background: transparent;
    }

    &.opacity {
        background-color: rgba(8, 9, 8, 0.8);
    }
}

.routerListWrap {
    display: none;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginBtn {
    padding: 0.213333rem
        /* 8/37.5 */
        0.426667rem
        /* 16/37.5 */
    ;
    margin-right: 0.213333rem
        /* 8/37.5 */
    ;
}

.signBtn {
    padding: 0.213333rem 0.32rem ;
}

.userOpration {

    .attendance,
    .myWallet {
        width: .853333rem ;
        height: .853333rem;
        margin-right: .48rem;
        padding: .053333rem;
        cursor: pointer;

        .attendanceWrap,
        .myWalletWrap {
            width: 100%;
            height: 100%;
            border: 1px solid transparent;
            border-radius: .16rem;
        }

        .iconfont {
            color: #fff;
            font-size: .64rem;
        }

        &:hover,
        &.active {
            background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
            border-radius: .213333rem;

            .attendanceWrap,
            .myWalletWrap {
                border-color: #83F05F;
            }

            .iconfont {
                color: #AFFF95;
            }
        }

        .hoverText {
            display: none;
        }
    }
}

.extension {
    width: 184px;
    height: 40px;
    border: 1px solid #66E7C7;
    border-radius: 20px;
    font-size: 14px;
    line-height: 16px;
    color: #66E7C7;
    cursor: pointer;
    margin-right: 20px;
    font-weight: 700;

    a {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #66E7C7;
        gap: 6px;
    }

    .icon-zhenhuichongtubiaozhizuo-kuozhan- {
        font-size: 19px;
    }
}

.username {
    display: flex;
    align-items: center;
    justify-content: center;

    .usernameIcon {
        width: 0.746667rem;
        height: 0.746667rem;
        margin-right: 0.16rem;
        background: url('@/assets/images/mobile/userIcon.png') no-repeat center center / 100% 100%;
    }

    font-weight: 600;
    font-size: 0.4rem;
    line-height: 0.48rem;
    letter-spacing: -0.3px;
    color: #ffffff;

    img {
        width: 0.746667rem;
        height: 0.746667rem;
        margin-right: 0.16rem;
        border-radius: 50%;
    }
}

:deep(.popover-box .toolTipClass) {
    padding: 0;
    background-color: transparent;

    .popover-arrow {
        display: none;
    }

    .popover-box-content {
        overflow: visible;
    }
}

@media screen and (min-width: 750px) {
    .header {
        height: 90px;
        padding: 0 40px;
        

        .slidebarSwitch {
            display: none;
        }
    }
    .center {
        width: 1280px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .left {
        display: flex;
        align-items: center;
    }

    .routerListWrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;

        .routerList {
            margin-left: 20px;
            position: relative;
            color: rgba(255, 255, 255, .8);
            font-size: 14px;
            line-height: 14px;
            cursor: pointer;

            .routerEle {
                color: rgba(255, 255, 255, .8);

                &.noClassActive {
                    &.active {
                        color: rgba(255, 255, 255, .8);

                        &::after {
                            display: none;
                        }
                    }
                }

                &.active {
                    color: #66E7C7;

                    // &::after {
                    //     content: '';
                    //     display: block;
                    //     width: 24px;
                    //     height: 4px;
                    //     background: #66E7C7;
                    //     box-shadow: 0px 10px 20px rgba(1, 153, 94, 0.203475);
                    //     border-radius: 2px;
                    //     position: absolute;
                    //     bottom: -7px;
                    //     left: 50%;
                    //     transform: translateX(-50%);
                    // }
                }

                &:hover {
                    color: #66E7C7;
                }

                &.specialStyle {
                    color: #fff;
                    font-weight: 600;

                    &.active {
                        color: #66E7C7;
                    }
                }
            }

            .iconfont {
                display: block;
                font-size: 21px;
                color: #66E7C7;
                margin-left: 3px;
            }
        }
    }

    .loginBtn {
        padding: 9px 16px;
        margin-right: 16px;
    }

    .signBtn {
        padding: 9px 12px;
    }

    .userOpration {

        .attendance,
        .myWallet {
            width: 32px;
            height: 32px;
            margin-right: 18px;
            padding: 2px;

            .attendanceWrap,
            .myWalletWrap {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }

            &:hover {
                border-radius: 8px;

                .hoverText {
                    display: block;
                }
            }

            .hoverText {
                position: absolute;
                top: -14px;
                left: 50%;
                transform: translateX(-50%);
                color: #BFFD9F;
                font-size: 12px;
                line-height: 12px;
                white-space: nowrap;
                display: none;
            }

            .iconfont {
                font-size: 24px;
            }
        }

        .newbieTask {
            width: 145px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 178, 124, 0.1);
            font-size: 14px;
            color: rgba(255, 203, 71, 1);
            border-radius: 20px;
            margin-right: 18px;
            cursor: pointer;

            .iconfont {
                font-size: 12px;
                margin-left: 8px;
            }

            &:hover {
                background-color: rgba(255, 178, 124, 0.2);
            }
        }
    }

    .username {
        font-size: 15px;
        line-height: 18px;
        cursor: pointer;

        .usernameIcon,
        img {
            width: 28px;
            height: 28px;
            margin-right: 6px;
        }

        img {
            border-radius: 50%;
            overflow: hidden;
        }
    }
}
</style>