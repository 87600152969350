<script lang='ts' setup>
import { reactive, computed, ref, onMounted } from 'vue';
import { navList } from '@/utils/navList';
import Button from '@/components/commonV2/ButtonWeb/Button.vue';
import { useStore } from 'vuex';
import { login } from '@/helper/account'


const sidebarList = reactive(navList)
const store = useStore()
// token
const token = computed(() => store.state.user.token)
// 用户信息
const userProfile = computed(() => store.state.user.userProfile)

// 当前url路径
let currentPath = ref('/')

// 新注册路径
const signupPath = '/signup'

const isShowMenuList = ref(false)

// # unipass login 
function unipassLogin() {
    onShowMenuList()
    login();
}

function onShowMenuList() {
    isShowMenuList.value = !isShowMenuList.value
}

onMounted(() => {
    document.body.addEventListener('click', function() {
        isShowMenuList.value = false
    })
})
</script>
<template>
    <div class="header-mobile-wrap">
        <div class='header-mobile'>
            <div class="menuList">
                <div class="logo"></div>
            </div>

            <div class="menu-icon flex-center" @click.stop="onShowMenuList"><i class='iconfont icon-menu'></i></div>
        </div>

        <Transition name="header">
            <div class="dropdown"  v-if="isShowMenuList">
                <div class="items " @click.stop>
                    <div class="menuList">
                        <div class="logo"></div>
                    </div>
                    <div class="routerList flex-center" v-for="(item, index) in sidebarList" :key="index">
                        <router-link active-class="active" v-if="item.location" class="routerEle"
                            :class="{'specialStyle': item.rightIcon }"
                            :to="item.url" @click="onShowMenuList">{{ item.title }}</router-link>
                    </div>
                    <div class="header-operate">
                        <div class="btn" v-if="!token">
                            <Button  v-if="currentPath !== signupPath" class="loginBtn" @click="unipassLogin">Login / Sign up</Button>
                        </div>
                        <router-link to="/myPortal" v-else @click="onShowMenuList">
                            <div class="username">
                                <img :src="userProfile.avatar" alt="" v-if="userProfile.avatar">
                                <i class="usernameIcon" v-else></i>
                                <span>{{ userProfile.name }}</span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<style lang='scss' scoped>
.header-mobile-wrap {
    height: 1.173333rem /* 44/37.5 */;
    .logo {
        width: 3.786667rem /* 142/37.5 */;
        height: .826667rem /* 31/37.5 */;
        background: url('@/assets/images/logo-header.png') no-repeat center center / 100% 100%;
    }
    .header-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 1.173333rem /* 44/37.5 */;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 .666667rem /* 25/37.5 */;
        z-index: 9;
        background-color: rgb(22, 22, 22);
        .menu-icon {
            width: .853333rem /* 32/37.5 */;
            height: .853333rem /* 32/37.5 */;
            .iconfont {
                font-size: .8rem /* 30/37.5 */;
            }
        }
    }
    .dropdown {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }
.items {
    position: absolute;
    height: 12rem;
    width: 100%;
    left: 0;
    background: linear-gradient(to right, #232222, #363434);
    // background-color: #000;
    z-index: 9999;
    border-radius: 0px 0px 20px 20px;
    padding-top: 0.7rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    .routerEle { 
        height: 1.333333rem /* 50/37.5 */;
        line-height: 1.333333rem /* 50/37.5 */;
        color: #fff;
        font-size: .533333rem /* 20/37.5 */;
        font-weight: 400;
        &.active {
            color: #66E7C7;
        }
    }
    .menuList {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
    }
}
.header-operate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    .username {
        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
        .usernameIcon {
            width: 0.746667rem;
            height: 0.746667rem;
            margin-right: 0.16rem;
            background: url('@/assets/images/mobile/userIcon.png') no-repeat center center / 100% 100%;
        }
        span {
            margin-left: 10px;
            color: #66E7C7;
        }
    }
}
}

.header-enter-active, .header-leave-active {
    transition: all .3s;
}
.header-enter-from, .header-leave-to {
    transform: translateY(-100%);
}
.header-enter-to, .header-leave-from {
    transform: translateY(0);
}

</style>