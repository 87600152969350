
// # 任务相关
export const TASK_MANAGEMENT_ROUTE = {
    path: ':id/tasks', // 社区管理
    name: 'TaskManagement',
    component: () => import('/src/views/myPortal/task/taskManagement.vue'),
    meta: {
        title: 'Task Management',
        keepAlive: true,
        isBack: true,
        analytics_view: 'task_management_view'
    },
    children: [
        {
            path: "create/:qid?",
            name: "CreateTask",
            component: () => import('/src/views/myPortal/task/createTask/index.vue'),
            meta: {
                title: 'Create Task',
                ignoreScroll: true,
                isBack: true,
                analytics_view: 'create_task_view'
            }
        }, {
            path: "rewardsManage/:qid?",
            name: "RewardsManage",
            component: () => import('/src/views/myPortal/task/rewardsManage/index.vue'),
            meta: {
                title: 'Rewards Manage',
                keepAlive: true,
                isBack: false,
                analytics_view: 'rewards_manage_view'
            }
        }, {
            path: "batchApproval/:qid/:tid/:type",
            name: "BatchApproval",
            component: () => import("/src/views/myPortal/task/batchApproval/index.vue"),
            meta: {
                title: "View and Approval",
                isBack: true,
                analytics_view: 'approval_manage_view'
            }
        }
    ]
}
// # 社区路由
export const COMMUNITIES_ROUTE = {
    path: 'communities', // 社区管理
    name: 'CommunitiesManagement',
    component: () => import('/src/views/myPortal/communitiesManagement/CommunitiesManagement.vue'),
    meta: {
        title: 'Manage My Communities',
        keepAlive: true,
        isBack: false,
        isHideMobile: true,
        analytics_view: 'manage_my_communityies_view'
    },
    children: [
        {
            path: 'create', // 社区管理 创建社区
            name: 'CreateCommunity',
            component: () => import('/src/views/myPortal/communitiesManagement/create/Create.vue'),
            meta: {
                title: 'Create Community',
                keepAlive: false,
                isBack: true,
                analytics_view: 'create_community_view'
            },
        },
        {
            path: 'edit/:id', // 社区管理 编辑社区
            name: 'EditCommunity',
            component: () => import('/src/views/myPortal/communitiesManagement/create/Create.vue'),
            meta: {
                title: 'Edit Community',
                keepAlive: false,
                isBack: true,
                analytics_view: 'edit_community_view'
            },
        },
        {
            path: 'view/:id', // 社区管理 浏览社区
            name: 'ViewCommunity',
            component: () => import('/src/views/myPortal/communitiesManagement/create/Create.vue'),
            meta: {
                title: 'View Community',
                keepAlive: false,
                isBack: true,
                analytics_view: 'view_community_view'
            },
        },
        {
            path: 'admin/:id', // 社区管理员
            name: 'Admin',
            component: () => import('/src/views/myPortal/communitiesManagement/admin/Admin.vue'),
            meta: {
                title: 'Community Admins',
                keepAlive: false,
                isBack: true,
                analytics_view: 'admins_community_view'
            }
        },
        TASK_MANAGEMENT_ROUTE
    ]
}

