<template>
  <div class='layoutList' v-if="!loading">
    <div class="lists">
      <slot></slot>
    </div>
    <!-- 加载更多 -->
    <LoadMore 
      :loading="moreLoading" 
      :has-more="list.hasMore" 
      @load="onLoadMore" 
      :pageSize="list.offset" 
      :is-show-loaded="false"
      class="loadBtn"
    />

    <!-- 无数据 -->
    <ListEmpty v-if="!list.list.length" :withLogin="withLogin">Uh, there’s nothing here.</ListEmpty>
  </div>

  <!-- loading -->
  <LoadingWrap v-else />
</template>
<script lang='ts' setup>
import LoadMore from './LoadMore.vue'
import ListEmpty from './ListEmpty.vue'
import LoadingWrap from './LoadingWrap.vue'
import { ref, toRefs } from 'vue';
const props = defineProps({
  list: {
    type: Object,
    default: {}
  },
  withLogin: {
    type: Boolean,
    default: true,
  },
  loading: Boolean,
  moreLoading: Boolean
})
const { list, loading, moreLoading } = toRefs(props)

const emits = defineEmits(['loadMore'])

const onLoadMore = () => {
  emits('loadMore')
}

</script>
<style lang='scss' scoped>
.loadBtn {
  margin-top: 40px;
}
</style>