import { createStore } from 'vuex'
import user, { IUserState } from './modules/user'
import global, { IGlobalState } from './modules/global'
import avatar, { IAvatarState } from './modules/avatar'
import community, { ICommunityState } from './modules/community'


export interface AllState {
  user: IUserState,
  global: IGlobalState
  avatar: IAvatarState
  community: ICommunityState
}


const store = createStore<AllState>({
  modules: {
    global,
    user,
    avatar,
    community
  }
})

export default store