import Message from '@/components/common/Message/Message';
import { ItabItem } from '@/components/common/Tab/Tab';
import { storageQuestTaskClickTimes } from '@/utils/storage';
import store from '@/store';
import { getCommunityDetail } from '@/api/community';
import { locationAppPage } from '@/utils/appURLScheme';
import useBridge from 'eft-bridge';
import router from '@/router';
const { isApp } = useBridge()

// cassava id 脱敏处理 只显示前四位与后四位
export const replaceAccountId = (accountId) => {
    const str = accountId.toString()
    const id = str.replace(str.substring(4, str.length - 4), '....')
    return 'Cassava ID #' + id
}

// 时间倒计时处理
export const unixFormat = (unix: number): [number, number, number, number] => {
    var days = Math.floor(unix / 86400); // 天  24*60*60*1000 
    var hours = Math.floor(unix / 3600) - 24 * days;    // 小时 60*60 总小时数-过去的小时数=现在的小时数 
    var minutes = Math.floor(unix % 3600 / 60); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
    var seconds = Math.floor(unix % 60);  // 以60秒为一整份 取余 剩下秒数

    days = days < 0 ? 0 : days
    hours = hours < 0 ? 0 : hours
    minutes = minutes < 0 ? 0 : minutes
    seconds = seconds < 0 ? 0 : seconds

    return [days, hours, minutes, seconds]
}

export interface ICountDownItem {
    taskId: number
    count: number
}

// 手机端 tablist
export const tabList: ItabItem[] = [
    {
        label: 'Description',
        value: 1
    },
    {
        label: 'Winners',
        value: 2
    },
    {
        label: 'Participants',
        value: 3
    }
]

// replace reward method
export const replaceRewardMethod = (rewardMethod: string) => {
    if (!rewardMethod) return ''
    return rewardMethod.replace(/_/g, ' ');
}

export const checkTwitterClickTimes = (taskId: number, platformCode: string) => {
    if (platformCode === 'TWITTER') {
        const clickTimes = storageQuestTaskClickTimes.get()
        if (clickTimes) {
            const findResult = clickTimes.find(o => o.taskId === taskId)
            if (findResult) {
                findResult.times += 1
                if (findResult.times >= 4) {
                    Message({ text: 'Please re-connect your Twitter and try again.', type: 'warn' });
                    findResult.times = 0
                }
                storageQuestTaskClickTimes.set(clickTimes)
            } else {
                clickTimes.push({taskId, times: 1})
                storageQuestTaskClickTimes.set(clickTimes)
            }
        } else {
            storageQuestTaskClickTimes.set([{taskId, times: 1}])
        }
    }
} 

// 任务详情页跳转社区详情页
export const onGoToCommunityHomepage = async (id: number) => {
    store.commit('globalLoading', true);
    if (isApp) {
        store.commit('globalLoading', false);
        window.location.href = locationAppPage("communityDetail", { id });
        return
    }
    try {
        const res = await getCommunityDetail({ id })
        const { data } = res.data;
        if (data.id) {
            router.push({ name: 'CommunityDetail', params: { name: data.community_url_path } })
        }
    } finally {
        store.commit('globalLoading', false);
    }
}