<template>
  <Transition name="down">
    <div class="my-message" :style="style[type]" v-show="isShow">
      <!-- 上面绑定的是样式 -->
      <!-- 不同提示图标会变 -->
      <i class="iconfont" :class="[style[type].icon]"></i>
      <span class="text">{{ text }}</span>
    </div>
  </Transition>
</template>
<script lang="ts">
import { onMounted, ref } from 'vue';

export default {
  name: 'myMessage',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      // warn 警告  error 错误  success 成功
      default: 'warn',
    },
  },
  setup() {
    // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
    const style = {
      default: {
        icon: '',
        color: '#fff',
        backgroundColor: 'rgba(53, 53, 53, 0.8)',
        borderColor: 'rgba(53, 53, 53, 0.8)',
      },
      warn: {
        icon: 'icon-tips',
        color: '#fff',
        backgroundColor: 'rgba(53, 53, 53, 0.8)',
        borderColor: 'rgba(53, 53, 53, 0.8)',
      },
      error: {
        icon: 'icon-failed',
        color: '#fff',
        backgroundColor: 'rgba(53, 53, 53, 0.8)',
        borderColor: 'rgba(53, 53, 53, 0.8)',
        // fontSize: '14px'
      },
      success: {
        icon: 'icon-success',
        color: '#fff',
        backgroundColor: 'rgba(53, 53, 53, 0.8)',
        borderColor: 'rgba(53, 53, 53, 0.8)',
      },
    };
    // 控制动画
    const isShow = ref(false);
    // 组件模板渲染成功后触发
    onMounted(() => {
      isShow.value = true;
    });
    return { style, isShow };
  },
};
</script>
<style scoped lang="scss">
.down {
  &-enter {
    &-from {
      transform: translate3d(0, -75px, 0);
      opacity: 0;
    }
    &-active {
      transition: all 0.5s;
    }
    &-to {
      transform: none;
      opacity: 1;
    }
  }
}
.my-message {
  min-width: 9.146667rem /* 343/37.5 */;
  height: 1.706667rem /* 64/37.5 */;
  position: fixed;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // top: 1.6rem /* 60/37.5 */;
  border: 1px solid rgba(37, 41, 38, 0.9);
  background: rgba(37, 41, 38, 0.9);
  box-shadow: 0px 10px 20px rgba(1, 153, 94, 0.099588);
  border-radius: 0.266667rem /* 10/37.5 */;
  color: #999;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-left: .16rem /* 6/37.5 */;
  padding-left: .426667rem /* 16/37.5 */;
  padding-right: .426667rem /* 16/37.5 */;
  font-size: .426667rem /* 16/37.5 */;
  text-align: center;
  i {
    margin-right: .32rem /* 12/37.5 */;
    vertical-align: middle;
    font-size: .32rem /* 12/37.5 */;
    flex-shrink: 0;
    // &.icon-successful {
    //   background: url('@/assets/images/icon-success-toast.png') no-repeat center center / 100% 100%;
    // }
    // &.icon-failed {
    //   background: url('@/assets/images/icon-failed.png') no-repeat center center / 100% 100%;
    // }
    &.icon-tips {
      width: .373333rem /* 14/37.5 */;
      height: .373333rem /* 14/37.5 */;
      background: url('@/assets/images/icon-warning-toast.svg') no-repeat center center / 100% 100%;
    }
  }
  .text {
    display: block;
    text-align: left;
    // width: calc(100% - .8rem /* 30/37.5 */);
  }
}
@media screen and (min-width: 750px) {
  .my-message {
    min-width: 343px;
    height: 64px;
    // margin-left: -171.5px;
    
    top: 105px;
    transform: translateX(-50%);
    border-radius: 10px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 16px;
    flex-wrap: nowrap;
    i {
      margin-right: 12px;
      vertical-align: middle;
      
      &.icon-tips {
        width: 14px;
        height: 14px;
      }
    }
    .text {
      // width: calc(100% - 35px);
    }
  }
}
</style>