import { MOBILE_BACKGROUND_COLOR } from "@/helper/global.text"
import { COMMUNITIES_ROUTE } from "./communities.router"
import { PARTICIPATED_ROUTE } from "./participated.router"
import { type RouteRecordRaw } from "vue-router"
import { IsMobile } from '../../utils/common';

export const MY_PROTAL_ROUTES: RouteRecordRaw = {
    path: '/myPortal',
    name: 'MyPortal',
    component: () => import('/src/views/myPortal/MyPortal.vue'),
    meta: {
        title: 'My Portal',
        isBack: false,
        analytics_view: 'myPortal_view',
        hideHeaderPadding: IsMobile(),
        setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
    },
    // redirect: '/myPortal/communities',
    children: [
        COMMUNITIES_ROUTE,
        PARTICIPATED_ROUTE,
        {
            path: 'assetsManagement', //  资产管理
            name: 'AssetsManagement',
            component: () => import('/src/views/wallet/Wallet.vue'),
            meta: {
                title: 'Manage My Assets',
                isBack: false,
                analytics_view: 'credits_view',
                setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
            },
            children: [
                {
                    path: 'OATs', // 社区管理 创建社区
                    name: 'OATs',
                    component: () => import('/src/views/wallet/children/myOATsPage.vue'),
                    meta: {
                        title: 'My OATs',
                        isBack: true,
                        analytics_view: 'my_oats_view',
                        setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
                    },
                },
            ]
        },
        {
            path: 'verification', //  设置
            name: 'Verification',
            component: () => import('/src/views/myPortal/verification/verification.vue'),
            meta: {
                title: 'Verification',
                isBack: false,
                analytics_view: 'setting_view',
                setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
            }
        },
        {
            path: 'referral', //  设置
            name: 'Referral',
            component: () => import('/src/views/myPortal/referral/referral.vue'),
            meta: {
                title: 'Referral',
                isBack: false,
                analytics_view: 'my_portal_referral',
                setBodyBg: MOBILE_BACKGROUND_COLOR, // 需要手动设置body背景色
            }
        },
    ]
}