
export function generateNonce(nonceLength = 17) {
    let nonce = '';
    const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < nonceLength; i++) {
        nonce += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
    }
    return nonce;
}

export function mkSolanaSignMessage(publicKey: string) {
    const now: Date = new Date();
    const currentDateTime = `${Math.floor(now.getTime() / 1000)}`;
    const nonce = generateNonce(24)
    const statement = `I accept the Cassava Terms of Service: https://www.cassava.network/Cassava-Term-Of-Use.html`
    const msg = `https://cassava.network wants you to sign in with your Solana account:\n${publicKey}\n\n${statement}\n\nURI: https://cassava.network\nVersion: 1\nNonce: ${nonce}\nIssued At: ${currentDateTime}`


    return [msg, nonce, currentDateTime]
}