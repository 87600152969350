import instance from '@/utils/request'
import { BASE_PAGING_RESPONSE, BASE_RESPONSE, REQUEST_LIST_BASIC_PARAMS } from './types'
import { type AxiosRequestConfig } from 'axios'

//  签到
export const checkIn = (data) => {
  return instance({
    url: 'v1/account/checkin',
    method: 'POST',
    data
  })
}

// 绑定钱包
export const bindWallet = (data) => {
  return instance({
    url: 'v1/wallet/addresses',
    method: 'POST',
    data
  })
}

// 获取社交平台数据
export const getSociaInfo = () => {
  return instance({
    url: 'v1/oauth/providers',
    method: 'GET',
  })
}

// 通过code获取社交平台用户信息
export const getSociaUserInfoByCode = () => {

}

// 解除绑定
export const unbindSocia = (cid) => {
  return instance({
    url: `v1/sociamedia/connections/${cid}`,
    method: 'DELETE',
  })
}

// 获取任务筛选列表
export interface IFilterItem {
  label: string
  code: string | number
  order: number
  is_default: boolean
  is_all: boolean
  child: null | IFilterBasic[]
}
export interface IFilterBasic {
  type: string
  category: string
  label: string
  code: string
  items: IFilterItem[]
}
export interface IGetFiltersData {
  filters: IFilterBasic[]
}

// 获取Quest筛选列表
export function getQuestsFilters(): Promise<BASE_RESPONSE<IGetFiltersData>> {
  return instance({
    url: 'v1/quests/filters',
    method: 'GET',
  })
}

export interface IGetListParams {
  community_id?: number | string
  recommended?: boolean
  status?: string
  name?: string
  order_by?: string
  platform_code?: string
  offset?: number
  limit?: number
}
export interface ICommunityInfo {
  id: number,
  name: string,
  logo_image_url: string
}

export interface IRewardsList {
  display_name: string,
  icon_url: string,
  reward_category: string
}
export interface IOATReward {
  display_name: string
  image_url: string
  network_icon_url: string
  network_name: string
}
export interface ICBReward {
  amount: number
  icon_url: string
}
export interface IQuestCardData {
  claim_deadline: number
  quest_id: number
  name: string
  community: ICommunityInfo
  description: string
  start_time: number
  end_time: number
  remaining: number
  thumbnail_url: string
  reward_category: string
  reward_amount: number
  tasks?
  task_platforms: string[]
  number_of_winners: number
  number_of_rewards: number
  number_of_participants: number
  reward_method: string
  status: string
  published_at: string
  rewards: IRewardsList[]
  reward_detail: {
    reward_total_num: number
    reward_claimed_num: number
    oat_reward: IOATReward
    cb_reward: ICBReward
  },
  luck_has_drew: boolean
}
// 获取任务列表
export function getQuestsList(data: IGetListParams): Promise<BASE_PAGING_RESPONSE<IQuestCardData>> {
  return instance({
    url: 'v1/quests',
    method: 'GET',
    params: data
  })
}

export interface IQuesetDetailParams {
  quest_id: string
}

// 获取任务详情
export function getQuestDetail(data: IQuesetDetailParams, config: AxiosRequestConfig ={}): Promise<BASE_RESPONSE<IQuestCardData>> {
  return instance({
    url: 'v1/quests/detail',
    method: 'GET',
    params: data,
    ...config
  })
}

export interface ITaskTemplateParams {
  platform_code?: string
}
export interface ITemplateMeta {
  fields: {
    label: string
    code: string
    type: string
    value: string
    placeholder: string
    url: string
  }[]
}
interface ITemplatePlatfrom {
  plateform_name: string
  icon_url: string
  plateform_code: string
}
export interface ITaskTemplate {
  template_id: number
  task_type: string
  server_verification: boolean
  name: string
  base_rewards: number
  meta: ITemplateMeta
  platform: ITemplatePlatfrom
}
export interface ITaskTemplateData {
  templates: ITaskTemplate[]
}
// 获取Task模版
export function getTaskTemplate(data: ITaskTemplateParams): Promise<BASE_RESPONSE<IQuestCardData>> {
  return instance({
    url: 'v1/quests/task_templates',
    method: 'GET',
    params: data
  })
}

export interface IWinnersData {
  account_id: number
  avatar_url: string
  name: string
}
export interface IQuestGetListParams extends REQUEST_LIST_BASIC_PARAMS {
  quest_id: string
}
// 获取获胜者
export function getWinners(data: IQuestGetListParams): Promise<BASE_PAGING_RESPONSE<IWinnersData>> {
  return instance({
    url: 'v1/quests/winners',
    method: 'GET',
    params: data
  })
}

// 获取参与者
export function getParticipants(data: IQuestGetListParams): Promise<BASE_PAGING_RESPONSE<IWinnersData>> {
  return instance({
    url: 'v1/quests/participants',
    method: 'GET',
    params: data
  })
}

export interface IPlatforms {
  code: string
  name: string
  linked: string
  jump_to: string
  app_jump_to: string
}
export interface IUserEligibilityData {
  is_eligible: boolean
  has_avatar: boolean
  social_media_platforms: IPlatforms[]
}
// 获取用户是否有资格参与任务
export function getUserEligibility(data: IQuesetDetailParams): Promise<BASE_RESPONSE<IUserEligibilityData>> {
  return instance({
    url: 'v1/quests/account/eligibility',
    method: 'GET',
    params: data
  })
}

export interface IExtra {
  label: string
  type: string
  value: string
}
export interface IUserJoinTask {
  task_id: number
  user_task_id: number
  status: string
  review_status: string
  extra: IExtra
}
export interface IQestStatus {
  claimed: boolean
  completed: boolean
  claimable: boolean
}
export interface IUserJoinTasksData {
  tasks: IUserJoinTask[]
  quest: IQestStatus
}
// 获取用户已参与的tasks
export function getUserJoinTasks(data: IQuesetDetailParams): Promise<BASE_RESPONSE<IUserJoinTasksData>> {
  return instance({
    url: 'v1/quests/userquest',
    method: 'GET',
    params: data
  })
}

export interface IVerifyTaskParams {
  task_id: number
  quest_id: number
}
export interface IVerifyTaskData {
  ok: boolean
  review_status: string
  status: string
}
// 校验用户已参与的task
export function verifyTask(data: IVerifyTaskParams): Promise<BASE_RESPONSE<IVerifyTaskData>> {
  return instance({
    url: 'v1/quests/tasks/verification',
    method: 'POST',
    data
  })
}

// 获取邀请关注社区的邀请码
export interface InviteUserFollowingCodeData {
  referral_code: string
  referral_url: string
}
export function getInviteUserFollowingCode(data: IVerifyTaskParams): Promise<BASE_RESPONSE<InviteUserFollowingCodeData>> {
  return instance({
    url: 'v1/quest/following/referal_code',
    method: 'POST',
    data
  })
}

// 提交task
export interface ISubmitTaskParams extends IVerifyTaskParams {
  url: string
}
export function submitTask(data: ISubmitTaskParams) {
  return instance({
    url: 'v1/quest/tasks/submit',
    method: 'POST',
    data
  })
}

interface IReceiveRewardsData extends IVerifyTaskData {
  oat_resp_record: {
    share_doc: string
    txn_url: string
    msg: string
  }
}
// 领取奖励
export function receiveRewards(data: IQuesetDetailParams): Promise<BASE_RESPONSE<IReceiveRewardsData>> {
  return instance({
    url: 'v1/quests/claim_rewards',
    method: 'GET',
    params: data
  })
}

export interface ICoCheckInsDailyItem {
  day: number
  is_checked_in: boolean
  is_today: boolean
  reward_stamina: number
  reward_cb: number
  reward_usdt: string
}

export interface ICoCheckInsRewards {
  reward_stamina: number
  reward_cb: number
  reward_usdt: string
}

export interface ICumulativeConfig extends ICoCheckInsRewards {
  day: number
}


export interface INextConfig extends ICoCheckInsRewards {
  more_day: number
} 
export interface ICoCheckInsResp {
  daily_streak: ICoCheckInsDailyItem[]
  cumulative_checked_in: {
    config: ICumulativeConfig[],
    next_day_config: INextConfig,
    checked_in_day: number
  }
  is_consecutive: boolean
}

// 连续签到
export function continuousCheckIns(): Promise<BASE_RESPONSE<ICoCheckInsResp>> {
  return instance({
    url: 'v1/user/checkin/info',
    method: 'GET',
    params: {}
  })
}