import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import 'normalize.css/normalize.css'
// import 'amfe-flexible'
import './utils/flexible.js'
import router from './router'
import VueGtag from "vue-gtag";
import store from '@/store'
import VueLazyload from 'vue-lazyload'
import "./helper/bridge"
import "solana-wallets-vue/styles.css";
import SolanaWallets from "solana-wallets-vue";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

// import { sentryInit } from '@/sentry'
const app = createApp(App);
// import gloablMethods from './helper/global.method'



import {
    SolflareWalletAdapter,
    PhantomWalletAdapter,
} from "@solana/wallet-adapter-wallets";

const network = import.meta.env.MODE === 'development' ? WalletAdapterNetwork.Devnet : WalletAdapterNetwork.Mainnet;

const walletOptions = {
    wallets: [
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter({ network:  network }),
    ],
    autoConnect: true,
    network: network,
};
app.use(SolanaWallets, walletOptions);

// 使用vuex
app.use(store)

// 使用路由
app.use(router)
app.use(VueLazyload)

// sentryInit(app, router)
app.use(VueGtag, {
    config: { id: import.meta.env.VITE_APP_GA_CODE },
    // @ts-ignore
    pageTrackerTemplate(to) {
        return {
            page_title: to.meta.analytics_view,
            page_path: to.path,
            page_location: window.location.href
        }
    }
}, router);

app.mount('#app');

// router.isReady().then(() => app.mount('#app'))

