export const DEFAULT_CONFIG = {
    speed: 1000,
    thresholdTime: 1500,
    autoplay: {
        autoplay: true,
        delay: 8000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
    },
    pagination: {
        clickable: true,
        progressbarOpposite: true,
    },
    "slides-per-view": 1,
    "initial-slide": 0,
    "space-between": 0
}

export interface BANNER_ITEM {
    "title": string,
    "description": string,
    "image_url": string,
    "link_url": string,
    "link_open_mode": {
        "mode": "blank" | "self"
    },
    "buttons": {
        "title": string,
        "style": "primary" | "secondary",
        "link_url": string,
        "link_open_mode": {
            "mode": "blank" | "self"
        }
    }[]
}