<script lang="ts" setup>
import { useStore } from "vuex"
import { useRouter } from "vue-router";
import Button from '@/components/common/Button/Button.vue'
const $store = useStore();
defineProps<{
    withLogin?: boolean,
}>()
</script>

<template>
    <div class="list_empty">
        <div class="list_empty_center">
            <img src="@/assets/images/empty.png" alt="" class="empty_img">
            <div class="empty_text">
                <slot></slot>
            </div>
            <router-link to="/login" v-if="!$store.state.user.token && withLogin">
                <Button :plain="true" class="list_empty_login" >Login / Sign up</Button>
            </router-link>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.list_empty {
    height: 408px;
    background: #1E211E;
    border-radius: 10px;
    position: relative;

    .list_empty_center {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .empty_img {
        width: 50px;
        height: 50px;
        display: block;
        margin: 0 auto;
    }

    .empty_text {
        margin-top: 12px;
        // font-family: 'GT Flexa';
        font-size: 18px;
        color: #97A1A9;
    }

    .list_empty_login {
        margin: 34px auto 0;
        cursor: pointer;
    }
}
</style>