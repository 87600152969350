<template>
  <button
    class="btn"
    :class="[
      'btn-' + type,
      'btn-' + size,
      {
        'btn-plain': plain,
        'is-radius': radius,
        'btn-disabled': disabled,
        'btn-transparent': transparent,
      },
    ]"
    @click="handleClick"
    :disabled="disabled"
  >
    <!-- <i v-if="icon" :class="`one-icon-${icon}`"></i> -->
    <div class="loadingWrap" v-if="loading">
      <Loading :dark="!plain" :small="true"></Loading>
    </div>
    <slot v-else></slot>
  </button>
</template>

<script lang="ts" setup>
import Loading from '../Loading/Loading.vue'
import { toRefs } from 'vue';
const porps = defineProps({
  type: {
    type: String,
    default: 'default',
  },
  transparent:{
    type: Boolean,
    default: false,
  },
  plain: {
    type: Boolean,
    default: false,
  },
  radius: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'small',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  }
});

const { loading } = toRefs(porps)

const emits = defineEmits(['click']);

const handleClick = (e: any) => {
  if (loading.value) return;
  emits('click', e);
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block; /*变为行内块：*/
  line-height: 1;
  white-space: nowrap; /*规定段落中的文本不进行换行：*/
  cursor: pointer;
  border: 1px solid #83f05f;
  color: #00340f;
  background-color: #83f05f;
  -webkit-appearance: none; /*去除谷歌浏览器自带按钮样式：*/
  text-align: center;
  box-sizing: border-box; /*内边距和边框放入宽高内*/
  outline: none; /*去除按钮自带边框*/
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  //禁止元素的文字被选中
  -moz-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  padding: .24rem /* 9/37.5 */ .48rem /* 18/37.5 */;
  font-size: .373333rem /* 14/37.5 */;
  border-radius: .266667rem /* 10/37.5 */;
  font-weight: 700;
  // &:focus
  &:hover {
    color: #00340f;
    border-color: #9fff7f;
    background-color: #9fff7f;
  }
  &.btn-plain {
    background-color: #161616;
    border-color: #83f05f;
    color: #83f05f;
    &:hover {
      background-color: #22341c;
    }
  }
  &.btn-transparent {
    background-color: transparent;
    border-color: #83f05f;
    color: #83f05f;
    &:hover {
      background-color: #22341c;
    }
  }
  &.btn-disabled {
    background-color: #d7d7d7;
    border-color: #d7d7d7;
    color: #fff;
    cursor: default;
  }
  &.btn-large {
    width: 100%;
    margin-top: .906667rem /* 34/37.5 */;
    height: 1.28rem /* 48/37.5 */;
  }
  .loadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 750px) {
  .btn {
    padding: 9px 16px;
    font-size: 14px;
    border-radius: 10px;
    &.btn-large {
      width: 100%;
      margin-top: 32px;
      height: 48px;
    }
  }
}
</style>