export const navList = [
  {
    icon: 'homeIcon', // nav icon
    title: 'Home', // title
    tip: false, // 是否有其他提示
    url: '/', // 地址
    location: true, // 是否可跳转 pc用
  },
  {
    icon: 'taskIcon',
    title: 'Rewards',
    tip: false,
    url: '/task',
    location: true, // 是否可跳转 pc用
    rightIcon: '', // pc header是否有其他icon
  },
  // {
  //   icon: 'CommunitiesIcon',
  //   title: 'Communities',
  //   tip: false,
  //   url: '/communities',
  //   location: true, // 是否可跳转 pc用
  // },
  // {
  //   icon: '',
  //   title: 'Safari',
  //   tip: false,
  //   url: '/safari_of_cassava_network',
  //   location: true, // 是否可跳转 pc用
  //   rightIcon: '', // pc header是否有其他icon
  // },
  {
    icon: 'myAvatrsIcon',
    title: 'Avatars',
    tip: false,
    url: '/myAvatar',
    location: true, // 是否可跳转 pc用
    rightIcon: '', // pc header是否有其他icon
  },
  {
    icon: '', // nav icon
    title: 'Leaderboard', // title
    tip: false, // 是否有其他提示
    url: '/cassava_leaderboard', // 地址
    location: true, // 是否可跳转 pc用
  },
   {
    icon: "",
    title: "Dashboard",
    tip: false,
    url: "/dashboard",
    location: true,
  }
  // {
  //   icon: 'collectionIcon',
  //   title: 'Collection',
  //   tip: false,
  //   url: '/collection',
  //   location: false, // 是否可跳转 pc用
  //   rightIcon: '', // pc header是否有其他icon
  // },

]