import { App } from 'vue';
import store from '../store';
import storage from 'store';
import router from '../router';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Message from '@/components/common/Message/Message';
import { getUrlParams, isCassavaWebView, isAndroidOrIOS } from '@/utils/common';
import { USER_NOT_PERMISSION } from '@/helper/global.text';
import { refreshToken } from '@/helper/account'
import { login } from '@/helper/account'
import { storageChannel } from './storage';
// 创建axios实例

let instance: any = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_URL,
    timeout: 30000,
});
let UNAUTHORIZED_IS_RUN = false;
const DEFAULT_MSG = "Oops, something went wrong. Please try again later."
/**
 * @desc: 异常拦截处理器
 * @param { Object } error 错误信息
 */
const errorHandler = async (
    error: AxiosError<any>
): Promise<AxiosError> => {
    console.error('response,', error);
    const { response } = error;
    if (response) {
        const code = response?.data?.code?.toLocaleUpperCase();
        if (code === 'RECORD_NOT_FOUND') {
            return Promise.reject(error);
        }
        if (code === 'ACCOUNT_OR_PASSWORD_IS_INCORRECT') {
            //
            Message({ text: 'password is incorrect', type: 'error' });
            return Promise.reject(error);
        }
        if (code === 'UNAUTHORIZED') {
            if (UNAUTHORIZED_IS_RUN) return Promise.reject(error);
            UNAUTHORIZED_IS_RUN = true;
            // # 如果token失效，如果是cassava的webview，直接登录，否则刷新token
            Message({
                text: 'The token has expired, Please re-sign or log in again',
                type: 'error',
            });
            store.commit('user/clearState');
            if (isCassavaWebView()) {
                login();
            } else {
                await refreshToken()
            }
            UNAUTHORIZED_IS_RUN = false;
            return Promise.reject(error);
            // router.push({ path: '/login' });
            // // localStorage.clear();
            // store.commit('user/clearState');
            // Message({
            //     text: 'The token has expired, please log in again',
            //     type: 'error',
            // });
            // return Promise.reject(error);
        }
        // 如果已经签过到了
        if (code === 'ALREADY_CHECKIN_TODAY') {
            Message({ text: response?.data?.msg || DEFAULT_MSG, type: 'warn' });
            return Promise.reject(error);
        }
        if (code === 'NOT_ENOUGH_BALANCE') {
            return Promise.reject(error);
        }
        if (code === 'ALREAD_EXISTS') {
            // 社区校验重名
            return Promise.reject(error);
        }
        //  多管理员添加时 用户不存在 或者用户已存在
        if (code === 'NOT_FOUND' || code === 'ADMIN_ALREADY_EXISTS' || code === 'CANNOT_CLOSE_ACCOUNT_WITH_RELATEDS') {
            return Promise.reject(error);
        }
        if (code === 'FORBIDDEN') {
            store.commit('community/setRefresh', true)
            router.back()
            Message({ text: USER_NOT_PERMISSION, type: 'error' });
            return Promise.reject(error);
        }
        // 奖励已全部被领光
        if (code === "NO_MORE_REWARDS") {
            return Promise.reject(error);
        }
        Message({ text: response?.data?.msg || DEFAULT_MSG, type: 'error' });
    }
    return Promise.reject(error);
};

function getParamsDev() {
    const channel = storageChannel.get()
    if (channel) return channel
    return isCassavaWebView() ? isAndroidOrIOS() : 'web'; // 请求的设备，如android、ios
}

/**
 * @desc: 请求发送前拦截
 * @param { Object } config 配置参数
 */
instance.interceptors.request.use(
    (
        config: AxiosRequestConfig
    ): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
        return new Promise((resolve, reject) => {
            const unixTime = parseInt(new Date().getTime() / 1000 + '');
            config = {
                ...config,
                headers: {
                    'Content-Type': 'application/json',
                    'x-cassava-ver': '1.0', // 请求的api版本
                    'x-cassava-dev': getParamsDev(),
                    'x-cassava-ts': unixTime, // 请求发起的unix time
                    'x-cassava-language': 'en', // 请求需要的语言。
                    ...config.headers,
                },
            };

            addUrlCode(config);
            addGoogleCaptchCode(config);
            store.dispatch('user/getToken', config.url).then((token) => {
                if (token !== null) {
                    config.headers!['x-cassava-token'] = token;
                } else {
                    // TODO unipass configpage
                    store.commit('user/clearState');
                }
                resolve(config);
            });
        });
    },
    errorHandler
);

/**
 * @desc: 服务端响应后拦截
 * @param { Object } response 返回的数据
 */
instance.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
        if (response.data.code === 'OK' || response.data.code === 'ACCEPTED') {
            return response;
        } else {
            Message({ text: response?.data?.msg || DEFAULT_MSG, type: 'error' });
            return Promise.reject(response);
        }
    },
    errorHandler
);

export default instance;

/**
 * 轮询
 * @param fn 轮询函数<Promise>
 * @param time 间隔时间<number,秒>
 * @param maxCount 最大轮询次数<number>
 * @return promise 返回此次轮询的Promise, resolve=>轮询成功返回fn结果，reject=>1.达到最大轮询次数(返回最后一次err)2.手动取消轮询(返回字符串`stop`)
 * @return stop 用于手动取消轮询
 * @return state 轮询状态 "pending" | "stop" | "finish"
 * @return maxCount 最大轮询次数<number>
 * @return time  间隔时间<number,秒>
 */
export function polling(fn, time = 1000, maxCount = 10) {
    if (typeof fn !== 'function') return;
    const _polling: {
        promise: Promise<any>;
        stop: () => void;
        state: "pending" | "stop" | "finish";
        maxCount: number;
        time: number;
    } = {
        promise: null,
        stop: null,
        state: "pending",
        maxCount,
        time,
    }
    _polling.promise = new Promise(polling_run);
    _polling.stop = () => {
        _polling.state = "stop";
    }
    function polling_run(resolve, reject) {
        setTimeout(() => {
            _polling.maxCount--;
            fn().then(res => {
                _polling.state = "finish";
                resolve(res);
            }).catch(err => {
                if (_polling.maxCount > 0 && _polling.state === 'pending') polling_run(resolve, reject);
                else {
                    reject(err);
                    _polling.state = "finish";
                };
            })
        }, _polling.time);
    }
    return _polling;
}

function addUrlCode(config) {
    const cot = getUrlParams(window.location.search);
    config.headers['x-cassava-commuRefCode'] = cot['code'] || '';
}
// 为config增加google captcha;
function addGoogleCaptchCode(config) {
    const token = window.sessionStorage.getItem('x-captcha-token') || "";
    if (token) {
        config.headers['x-captcha-token'] = token;
    }
}