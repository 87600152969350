import { RouteRecordRaw } from 'vue-router'

export const ADS_PAGE: RouteRecordRaw[] = [
    {
        path: '/cassava_for_africans',
        name: 'CassavaForAfricans',
        component: () => import('/src/views/adsPage/adsLandingPage/index.vue'),
        meta: {
            title: 'Cassava for Africans',
            hideHeaderPadding: true,
        }
    }, {
        path: '/cassava_for_africans_download',
        name: 'CassavaForAfricansDownload',
        component: () => import('/src/views/adsPage/adsDownload/index.vue'),
        meta: {
            title: 'Cassava for Africans',
            hideHeaderPadding: true,
        }
    }, {
        path: '/cassava_for_africans_rules',
        name: "CassavaForAfricansRules",
        component: () => import('/src/views/adsPage/adsLandingPageRules/index.vue'),
        meta: {
            title: 'Cassava for Africans',
            hideHeaderPadding: true,
        }
    }
]