<script lang="ts" setup>
import { watchEffect, ref, toRaw, type Ref } from 'vue';

interface TAB_ITEM {
    name: string,
    isClear?: boolean,
    code?: string,
    onClick?: (TAB_ITEM) => void,
}
type LOCAL_TAB_ITEM = TAB_ITEM & { isActived: boolean };
// ! 如果 single=true ，onChange返回为item，否则返回 item[];
const $props = defineProps<{
    tabList: Array<TAB_ITEM>,
    ignoreClearData?: boolean,
    single?: boolean,
    defaultCode?: any
}>()
const $emit = defineEmits(['onChange', 'onClear'])
const localData: Ref<LOCAL_TAB_ITEM[]> = ref([]);
let cacheChangeDataJSON: string;
let clearIndex = -1;
watchEffect(() => {
    localData.value = $props.tabList.map((item, index) => {
        if (item.isClear) clearIndex = index;
        let itemIsAct = $props.defaultCode ? item.code === $props.defaultCode : !!item.isClear
        return {
            ...item,
            isActived: itemIsAct
        }
    });
})
function tabClickSingle(item: LOCAL_TAB_ITEM) {
    if (item.isClear) $emit('onClear');
    let key = item.code ? 'code' : 'name';
    localData.value.forEach((dataItem) => {
        if (dataItem[key] === item[key]) dataItem.isActived = true;
        else dataItem.isActived = false;
    })
    $emit('onChange', toRaw(item));
}
function tabClick(item: LOCAL_TAB_ITEM) {
    // # 如果是单选的话
    if ($props.single) return tabClickSingle(item);
    // # 原始数据
    const originLocalData = toRaw(localData.value)
    // # 如果点击的清除，重置状态
    if (item.isClear) {
        $emit('onClear')
        localData.value.forEach((item) => item.isActived = !!item.isClear)
    } else {
        localData.value[clearIndex].isActived = false;
        item.isActived = !item.isActived;
    }
    // # 获取改变的数据
    const changeData = originLocalData.filter((item) => item.isActived);
    // # 如果没有改变的数据，就默认选中清除
    if (changeData.length === 0) {
        localData.value[clearIndex].isActived = true;
        $props.ignoreClearData && changeData.push(originLocalData[clearIndex]);
    }
    // # 如果改变的数据和缓存的数据一样，就不触发事件
    const changeDataJSON = JSON.stringify(changeData)
    if (changeDataJSON === cacheChangeDataJSON) return;
    else {
        $emit('onChange', changeData);
        cacheChangeDataJSON = changeDataJSON;
    }
    // # 如果有点击事件，就执行点击事件;
    if (item.onClick) item.onClick(item)
}
</script>

<template>
    <div>
        <div class="filterTab_view">
            <div v-for="(item, index) in localData" :key="index" @click="tabClick(item)"
                :class="{ 'active': item.isActived }" class="filterTab_item">{{ item.name }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.filterTab_view {
    color: #fff;
    display: flex;
    gap: 8px;
}

.filterTab_item {
    text-align: center;
    min-width: 80px;
    height: 40px;
    line-height: 40px;
    padding: 0 18px;
    background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
    border-radius: 8px;
    color: #97A1A9;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    &.active {
        color: #83F05F;
        font-weight: 700;
        position: relative;

        &::before {
            content: " ";
            position: absolute;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border: 1px solid #83F05F;
            border-radius: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media screen and (max-width: 750px) {
    .filterTab_item {
        font-size: 12px;
        padding: 0 15px;
        min-width: 54px;
        white-space: nowrap;
    }
}
</style>