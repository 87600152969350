import { storageGlobalStatic } from "@/utils/storage"
import { ActionContext } from "vuex"
import { AllState } from ".."

// cdn 的地址
const CDN_URL = import.meta.env.VITE_APP_CDN_URL
const JSON_NAME = 'static_resources_meta.json'

export interface IStaticResources {
  mystery_box_poster_fmt: string, // 盲盒poster
  clothing_jersey_image_fmt: string // 盲盒球衣地址规则
}
export interface IGlobalState {
  routerChangeLoading: boolean // 路由切换时loading
  isMobile: boolean
  static_resources_meta: IStaticResources, // 静态资源管理 
  showInviteBox: boolean
}


const state: IGlobalState = {
  routerChangeLoading: false,
  isMobile: false,
  showInviteBox: false,
  static_resources_meta: {
    mystery_box_poster_fmt: '',
    clothing_jersey_image_fmt: ''
  }
}

export default {
  state,
  mutations: {
    setInviteBox(state: IGlobalState, val: boolean) {
      state.showInviteBox = val
    },
    globalLoading(state: IGlobalState, val: boolean) {
      state.routerChangeLoading = val
    },
    setClientType(state: IGlobalState, val: boolean) {
      state.isMobile = val
    },
    setStaticResourcesMeta(state: IGlobalState, fmt: IStaticResources) {
      state.static_resources_meta = fmt
    }
  },
  actions: {
    // 获取静态资源管理json
    getStaticResourcesMeta(context: ActionContext<IGlobalState, AllState>) {
      return new Promise((resolve, reject) => {
        // 查看缓存中 是否有StaticResourcesMeta
        const meta = storageGlobalStatic.get()
        if (meta) {
          context.commit('setStaticResourcesMeta', meta)
          resolve(meta)
          return
        }

        fetch(`${CDN_URL}/${JSON_NAME}`).then(res => res.json()).then(res => {

          const staticConfig = {
            mystery_box_poster_fmt: CDN_URL + res.mystery_box_poster_fmt,
            clothing_jersey_image_fmt: CDN_URL + res.clothing_jersey_image_fmt
          }
          context.commit('setStaticResourcesMeta', staticConfig)
          // 保存到storage 中
          storageGlobalStatic.set(staticConfig)
          resolve(staticConfig)
        }).catch(err => {
          reject(err)
          console.log(err);
        })
      })
    }
  },
  getter: {
    getClothImg: (state: IGlobalState) => (name?: string) => {

    }
  }
}