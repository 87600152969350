export const STATUS_ONGOING = 'ONGOING'
export const STATUS_SCHEDULED = 'SCHEDULED' // 未开启的 预定
export const STATUS_DRAFT = 'DRAFT' // 草稿箱
export const STATUS_ENDED = 'ENDED'
export const STATUS_SUSPENDED = 'SUSPENDED' // 暂停

export const ENDS_IN = 'Ends in'
export const STARTS_IN = 'Starts in'
export const ENDED= 'Ended'
export const LUCKY_DRAW_START_IN = `Lucky Draw in`
export const LUCKY_DRAW_EXPIRE_IN = `Rewards expire in` // 抽奖模式下 被抽中 未领取 倒计时显示的字段


// 显示的状态文案
export const SHOW_STATUS_ONGOING = 'Ongoing'
export const SHOW_STATUS_SCHEDULED = 'Upcoming'
export const SHOW_STATUS_ENDEN = 'Ended'

// 领奖方式
export const REWARD_METHOD_FCFS = `FCFS`
export const REWARD_METHOD_LUCKY = `LUCKY_DRAW`

