<template>
  <div class='footer' v-if="!IsMobile">
    <slot></slot>
    <div class="swpier_footer_share_view">
        <img src="@/assets/images/links/twitter.svg" alt="" class="swpier_footer_share_icon c1"
            @click="share('twitter')">
        <img src="@/assets/images/links/telegram.svg" alt="" class="swpier_footer_share_icon c2"
            @click="share('telegram')">
        <img src="@/assets/images/links/discord.svg" alt="" class="swpier_footer_share_icon c1"
            @click="share('discord')">
        <img src="@/assets/images/links/linktree.svg" alt="" class="swpier_footer_share_icon c2"
            @click="share('linktree')">
    </div>
    <div class="swpier_footer_copyright">© 2024 CASSAVA LLC</div>
  </div>
</template>
<script lang='ts' setup>
import share_url from "@/utils/share_url"
import { computed } from "vue";
import { useStore } from 'vuex'
const store = useStore()
const IsMobile = computed(() => store.state.global.isMobile)
const share = (name) => {
    window.open(share_url[name], "_blank");
}
</script>
<style lang='scss' scoped>
.footer {
  width: 100%;
  min-height: 100px;
  padding-bottom: 10px;
  background: url("@/assets/images/pc/homePage/swiper_footer.png") no-repeat;
  background-size: cover;
  background-color: #1E1E1E;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 9;
  .swpier_footer_share_view {
      width: 330px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 24px 24px 16px;

      .swpier_footer_share_icon {
          cursor: pointer;

          .c1 {
              width: 20px;
              height: 20px;
          }

          .c2 {
              width: 24px;
              height: 24px;
          }
      }
  }

  .swpier_footer_copyright {
      font-weight: 300;
      font-size: 12px;
      color: #fff;
      opacity: 0.8;
  }
}
</style>