<template>
  <transition name="sidebar">
    <div class="sidebarWrap" v-if="isActive" @click="isActive = false; emitter.emit('onCloseSwitch')">
      <div class="sidebar" @click.stop="">
        <div class="header">
          <Logo @switchClick="onSwitchClick" :type="'close'" />
        </div>
        <div class="menu">
          <div class="menuWrap">
            <router-link 
              :to="item.url" 
              active-class="active" 
              class="menuList" 
              v-for="(item, index) in sidebarList" 
              :key="index"
              @click.native="onClickRouter"
            >
              <div class="menuContent">
                <i class="icon" :class="item.icon"></i>
                <span>{{item.title}}</span>
              </div>
              <div class="menuTip" v-if="item.tip"><span>Coming soon</span></div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="share_block">
        <!-- <div class="share_block_col"> -->
          <img src="@/assets/images/links/twitter.svg" alt="" class="share_icon c1"
              @click="share('twitter')">
          <img src="@/assets/images/links/telegram.svg" alt="" class="share_icon c2"
              @click="share('telegram')">
        <!-- </div> -->
        <!-- <div class="share_block_col"> -->
          <img src="@/assets/images/links/discord.svg" alt="" class="share_icon c1"
              @click="share('discord')">
          <img src="@/assets/images/links/linktree.svg" alt="" class="share_icon c2"
              @click="share('linktree')">
        <!-- </div> -->
      </div>
      <div class="copyright">© 2023 CASSAVA LLC</div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import Logo from '@/components/layout/common/Logo.vue';
import { ref, onBeforeUnmount, reactive, watch } from 'vue';
import emitter from '@/utils/EventBus';
import { navList } from '@/utils/navList';
import share_url from "@/utils/share_url"

const isActive = ref(false);

const sidebarList = reactive(navList)

// 关闭侧边栏事件
const onSwitchClick = () => {
  isActive.value = false;
  emitter.emit('onCloseSwitch')
};
const emitCallback = () => {
  isActive.value = true;
};
// 打开侧边栏
emitter.on('onOpenSwitch', emitCallback);

onBeforeUnmount(() => {
  // 解除自定义事件，防止内存泄漏
  console.log('sidebar onBeforeUnmount');
  emitter.off('onOpenSwitch', emitCallback);
});

const onClickRouter = () => {
  isActive.value = false;
  emitter.emit('onCloseSwitch')
}
const share = (name) => {
    window.open(share_url[name], "_blank");
}

// watch(
//   isActive,
//   ()=>document.body.style.overflow = isActive.value ? 'hidden' : 'auto'
// )
</script>

<style lang="scss" scoped>
.copyright{
  position: absolute;
  bottom: 1.066667rem;
  left: 0.853333rem;
  font-weight: 300;
  font-size: 0.32rem;
  // font-family: 'Inter';
  color: #FFFFFF;
  opacity: 0.8;
}
.share_block{
  width:7.466667rem;
  position: absolute;
  bottom: 2.4rem;
  padding: 0 .833333rem;
  display: flex;
  justify-content: space-between;
  .share_icon {
      cursor: pointer;
      .c1 {
          width: 20px;
          height: 20px;
      }

      .c2 {
          width: 24px;
          height: 24px;
      }
  }
}
.sidebarWrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.5s;
  z-index: 9999;
}
.sidebar {
  width: 7.466667rem /* 280/37.5 */;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  
  .header {
    padding: 0 0.453333rem /* 17/37.5 */;
    height: 1.173333rem /* 44/37.5 */;
    display: flex;
    align-items: center;
  }
  &.active {
    left: 0;
  }
}
.menuWrap {
  padding: 1.6rem /* 60/37.5 */ .426667rem /* 16/37.5 */ .426667rem /* 16/37.5 */;
}
.menuList {
  color: #fff;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding-left: .426667rem /* 16/37.5 */;
  font-weight: 700;
  font-size: .426667rem /* 16/37.5 */;
  border-radius: .213333rem /* 8/37.5 */;
  margin-bottom: .32rem /* 12/37.5 */;
  &.active {
    background-color: rgba(131, 240, 95, .16);
    color: #83F05F;
  }
  .menuContent {
    display: flex;
    align-items: center;
    span {
      display: block;
      line-height: .426667rem /* 16/37.5 */;
    }
    .icon {
      width: .533333rem /* 20/37.5 */;
      height: .533333rem /* 20/37.5 */;
      margin-right: 8px;
      &.homeIcon {
        background: url('@/assets/images/mobile/homeIcon.png') no-repeat center center / 100% 100%;
      }
      &.myAvatrsIcon {
        background: url('@/assets/images/mobile/myAvatrsIcon.png') no-repeat center center / 100% 100%;
      }
      &.collectionIcon {
        background: url('@/assets/images/mobile/collectionIcon.png') no-repeat center center / 100% 100%;
      }
      &.taskIcon {
        background: url('@/assets/images/mobile/taskIcon.png') no-repeat center center / 100% 100%;
      }
      &.FIFAIcon {
        background: url('@/assets/images/mobile/FIFAIcon.png') no-repeat center center / 100% 100%;
      }
    }
  }
  .menuTip {
    width: 2.24rem /* 84/37.5 */;
    height: .426667rem /* 16/37.5 */;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D3FFC4;
    border-radius: .266667rem /* 10/37.5 */;
    letter-spacing: -0.5px;
    span {
      font-size: .533333rem /* 20/37.5 */;
      transform: scale(0.5);
      color: #01995E;
      white-space: nowrap;
    }
  }
}

.sidebar-enter-from,.sidebar-leave-to {
  opacity: 0;
  left: -7.52rem /* 282/37.5 */;
}
.sidebar-enter-active,.sidebar-leave-active {
  transition: all 0.5s ease-out;
}
.sidebar-enter-to,.sidebar-leave-from {
  opacity: 1;
  left: 0;
}

</style>