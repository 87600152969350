<script lang="ts" setup>
import Modal from '@/components/common/Modal/Modal.vue';
import Button from '@/components/common/Button/Button.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import Image from '@/components/common/Image/Image.vue';
import copyText from '../../utils/copy';
import { shortenAddressSlice } from '@/utils/auth';

import Message from '@/components/common/Message/Message';
const $store = useStore()
const referral_info = computed(() => $store.state.user.userProfile.referral_info || {});
function copy(text: string) {
    copyText(text, () => {
        Message({
            text: 'Copy Success',
            type: 'success'
        })
    })
}

function close() {
    $store.commit('setInviteBox', false);
}

</script>

<template>
    <Modal isCustom title="Share to" @close="close">
        <template #content>
            <div class="inviteBox">
                <Image :src="referral_info.share_image_url" bgc="#161616" class="inviteBox_img"></Image>
                <div class="inviteBoxOutput">
                    <div class="inputWrapInvite" @click="copy(referral_info.referral_link)">
                        <div class="formLabelInvite">Referral Link</div>
                        <div class="formControlInvite">{{ shortenAddressSlice(referral_info.referral_link, 16, 8) }}</div>
                        <span class="iconfont icon-copy"></span>
                    </div>
                    <div class="inputWrapInvite" @click="copy(referral_info.referral_code)">
                        <div class="formLabelInvite">Referral Code</div>
                        <div class="formControlInvite">{{ referral_info.referral_code }}</div>
                        <span class="iconfont icon-copy"></span>
                    </div>
                </div>
                <a class="downloadBtn" :href="referral_info.share_image_url" target="_blank"
                    :download="`${referral_info.referral_code}_referral.png`">
                    <Button size="large"><span class="iconfont icon-download"></span>Save</Button>
                </a>
            </div>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.inviteBox {
    padding: 12px 0;
    width: 820px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .downloadBtn {
        width: 390px;
        position: absolute;
        bottom: 0;
        right: 0;
        .icon-download{
            font-size: 13px;
            margin-right: 10px;
        }
    }

    .inviteBox_img {
        width: 390px;
        height: 526px;
        border-radius: 8px;
        overflow: hidden;
    }

    .inviteBoxOutput {
        width: 390px;

        .inputWrapInvite {
            position: relative;
            background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
            border-radius: 10px;
            border: 2px solid transparent;
            display: flex;
            height: 48px;
            line-height: 48px;
            border-radius: 8px;
            background: linear-gradient(180deg, #2F4228 0%, #2D2D2D 100%);
            border: 1px solid #83F05F;
            border-radius: 8px;
            margin-bottom: 24px;
            cursor: pointer;
        }

        .icon-copy {
            position: absolute;
            right: 6px;
            top: 0px;
            width: 16px;
            height: 16px;
        }

        .formLabelInvite {
            position: absolute;
            top: 0;
            left: 20px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.5);
        }

        .formControlInvite {
            position: absolute;
            right: 26px;
            top: 50%;
            transform: translateY(-50%);
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap;
            padding: 0;
            font-size: 14px;
            text-align: right;
            width: 250px;
            color: #fff;

            &::-webkit-input-placeholder {
                /* 使用webkit内核的浏览器 */
                color: rgba(255, 255, 255, 0.5);
            }

            &:-moz-placeholder {
                /* Firefox版本4-18 */
                color: rgba(255, 255, 255, 0.5);
            }

            &::-moz-placeholder {
                /* Firefox版本19+ */
                color: rgba(255, 255, 255, 0.5);
            }

            &:-ms-input-placeholder {
                /* IE浏览器 */
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .inviteBox {
        width: 100%;
        flex-direction: column;
        .inviteBox_img {
            width: 80%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 16px;;
        }
        .inviteBoxOutput {
            width: 100%;
            .inputWrapInvite {
                margin-bottom: 16px;
            }
        }
        .downloadBtn {
            width: 100%;
            position: static;
            .btn-large {
                margin-top: 0;
            }
        }
    }
}
</style>