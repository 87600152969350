<template>
  <Teleport to="body">
    <div class='modal' :class="{ 'modalFixed': isShowBg }" v-bind="$attrs">
      <div class="modalWrap" :style="modalWrapStyle">
        <div class="header">
          <div class="title"><span v-if="title">{{ title }}</span></div>
          <div class="close" @click="onClose('icon')" v-if="isShowClose"></div>
        </div>

        <!-- 如果是默认弹窗 -->
        <div class="content" v-if="!isCustom">
          <div class="contentWrap">
            <div class="modalTip" :class="type"><i class='iconTip'></i></div>
            <div class="contentH1" v-if="contentH1" v-html="contentH1"></div>
            <div class="contentH2" v-if="contentH2" v-html="contentH2"></div>
          </div>
        </div>

        <!-- 如果是定制化弹窗 -->
        <div class="content" v-if="isCustom">
          <slot name="content"></slot>
        </div>

        <div class="footer" v-if="isExit || isConfirm">
          <Button :plain="true" v-if="isExit" class="exitBtn" @click="onClose('btn')">{{ exitText }}</Button>
          <Button v-if="isConfirm" class="confirmBtn" :style="btnStyle" @click="onConfirm" :loading="confirmLoading"
            :disabled="confirmDisabled">
            <i :class="confirmBtnIcon" class="confirmIcon iconfont " v-if="confirmBtnIcon" />
            {{ confirmText }}
          </Button>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<script lang='ts' setup>
import { gtagEvent } from '@/helper/global.method';
import Button from '../Button/Button.vue'
import { onBeforeUnmount } from 'vue';
const { isShowBg, isCustom, title, isExit, exitText, isConfirm, confirmText, modalWrapStyle, closeHandle, type, btnStyle, confirmBtnIcon, gtagType } = defineProps({
  isShowBg: { // 是否显示背景
    type: Boolean,
    default: true
  },
  isCustom: { // 是否自定义content
    type: Boolean,
    default: false
  },
  title: { // title
    type: String,
    default: ''
  },
  isExit: { // 是否显示退出按钮
    type: Boolean,
    default: false
  },
  exitText: { // 退出按钮显示文案
    type: String,
    default: 'Exit'
  },
  isConfirm: { // 是否显示确定按钮
    type: Boolean,
    default: false
  },
  confirmText: { // 确定按钮显示文案
    type: String,
    default: 'Continue'
  },
  modalWrapStyle: { // 自定义style样式
    type: String,
    default: ''
  },
  contentH1: { // 自定义 内容1
    type: String,
    default: ''
  },
  contentH2: { // 自定义 内容2
    type: String,
    default: ''
  },
  isShowClose: { // 是否显示close按钮
    type: Boolean,
    default: true
  },
  closeHandle: {
    type: String,
    default: 'close'
  },
  type: {
    type: String,
    default: 'warning'
  },
  confirmLoading: {
    type: Boolean,
    default: false
  },
  confirmDisabled: {
    type: Boolean,
    default: false
  },
  btnStyle: {
    type: String,
    default: ''
  },
  confirmBtnIcon: {
    type: String,
    default: ''
  },
  gtagType: { // 埋点类型
    type: String,
    default: ''
  }
})

// 让整个页面不可滑动
document.body.style.overflow = 'hidden'

const emits = defineEmits(['close', 'confirm'])

const onClose = (type: 'icon' | 'btn') => {
  emits('close', type)
  document.body.style.overflow = 'auto'
}

const onConfirm = () => {
  document.body.style.overflow = 'auto'
  gtagEvent(gtagType)
  emits('confirm')
}

onBeforeUnmount(() => {
  console.log('modal before unmount');
  document.body.style.overflow = 'auto'
})
</script>
<style lang='scss' scoped>
.modal {

  &.modalFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 1024;
  }

  .modalWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 9.146667rem
      /* 343/37.5 */
    ;
    min-height: 2.133333rem
      /* 80/37.5 */
    ;
    padding: .426667rem
      /* 16/37.5 */
    ;
    background: rgba(37, 41, 38, .9);
    border-radius: 8px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #FFFFFF;
    }

    .close {
      width: 20px;
      height: 20px;
      background: url('@/assets/images/icon-close.png') no-repeat center center / 100% 100%;
      cursor: pointer;

      &:hover {
        background: url('@/assets/images/icon-close-hover.png') no-repeat center center / 100% 100%;
      }
    }

    .btn {
      width: 3.733333rem
        /* 140/37.5 */
      ;
      height: 1.066667rem
        /* 40/37.5 */
      ;
    }

    .footer {
      display: flex;
      justify-content: space-around;
      gap: 16px;
    }

    .exitBtn {
      //   margin-right: .4rem /* 15/37.5 */;
      background-color: transparent;
    }

    .confirmIcon {
      margin-right: 4px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
  }

  .contentWrap {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .contentH1 {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 12px;
    }

    .contentH2 {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: rgba(255, 255, 255, .5);
    }
  }

  .modalTip {
    width: 100px;
    height: 100px;
    background-color: rgba(255, 158, 0, .1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .iconTip {
      width: 40px;
      height: 40px;
      display: flex;
      background: url('@/assets/images/icon-tips.png') no-repeat center center / 100% 100%;
    }

    &.success {
      background-color: rgba(0, 154, 95, .1);

      .iconTip {
        background: url('@/assets/images/icon-successful.png') no-repeat center center / 100% 100%;
      }
    }

    &.failed {
      background-color: rgba(255, 92, 92, .1);

      .iconTip {
        background: url('@/assets/images/icon-failed.png') no-repeat center center / 100% 100%;
      }
    }
  }
}

@media screen and (min-width: 750px) {
  .modal {
    .modalWrap {
      min-width: 100px;
      min-height: 80px;
      padding: 24px;
      width: unset;
      .btn {
        width: 140px;
        height: 40px;
      }

      .exitBtn {
        // margin-right: 15px;
      }
    }

  }
}</style>