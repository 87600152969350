import { event, pageview } from 'vue-gtag'
import $router from '@/router'
import { getUrlParams, isCassavaWebView } from '@/utils/common';
import { useBridge } from 'eft-bridge'
// 埋点分析
export const gtagEvent = (type: string, param?: {}) => {
    if (!type) return

    const viewName = $router.currentRoute.value.meta.analytics_view
    const { bridge } = useBridge()
    const params = {
        screen_name: viewName,
        item_type: type,
        ...param
    }
    if (isCassavaWebView()) {
        bridge.call({ opt: 'logEvent', body: { eventName: 'click_item', parameters: params } })
    } else {
        event('item_click', params)
    }
}

// 埋点分析 banner
export const gtagEventBanner = (type: string, index: number, id?: number, name?: string, link?: string) => {
    if (!type) return

    const viewName = $router.currentRoute.value.meta.analytics_view
    event('click_banner_item', {
        screen_name: viewName,
        item_type: type,
        item_detail_link: link, // banner连接
        index: index,    // 点击的索引
        item_id: id ? id.toString() : undefined, // banner id
        item_name: name, // item的标题名称
    })
}

// 埋点分析 list点击
export const gtagEventItem = (type: string, index: number, id: number) => {
    if (!type) return

    const viewName = $router.currentRoute.value.meta.analytics_view
    const { bridge } = useBridge()
    const params = {
        screen_name: viewName,
        item_type: type,
        index: index,    // 点击的索引
        item_id: id.toString(), // banner id
    }
    if (isCassavaWebView()) {
        bridge.call({ opt: 'logEvent', body: { eventName: 'click_list_item', parameters: params } })
    } else {
        event('click_list_item', params)
    }
}

// 埋点分析 搜索
export const gtagSearch = (type: string, term: string) => {
    if (!type) return

    const viewName = $router.currentRoute.value.meta.analytics_view
    event('search', {
        screen_name: viewName,
        search_type: type,
        search_term: term
    })
}

export const gtagAds = (type: string, remark = "") => {
    if (!type) return
    const params = getUrlParams(window.location.search);
    const channel_code = params['channel_code'] || "";
    const viewName = $router.currentRoute.value.meta.analytics_view
    event('ads_event', {
        screen_name: viewName,
        item_type: type,
        channel_code,
        remark
    })
}

export const gtagPageView = async (name: string) => {
    const { bridge, isApp } = useBridge()
    if (isApp) {
        await bridge.call({ opt: 'logEvent', body: { eventName: 'screen_view', parameters: { screen_name: name } } })
        return
    }
    pageview({
        page_title: name,
    })
}


export const goToEarnMoreCB = () => {
    $router.replace('/quiz/daily?competition_id=1');
}