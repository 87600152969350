import { createRouter, createWebHashHistory } from 'vue-router'
import { constantRouterMap } from './basic.router'
import { permission } from './permission'
import { IsMobile } from '../utils/common';
import { MY_PROTAL_ROUTES } from "./myProtal/index"
import { APP_PAGE } from "./app.router"
import { MOBILE_BACKGROUND_COLOR } from '@/helper/global.text';
import ACTIVITIES_PAGE from "./activities/index"
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        ...constantRouterMap,
        {
            path: '/changePassword', // 修改密码
            // name: 'ChangePassword',
            children: [
                {
                    path: '',
                    name: 'ChangePassword',
                    component: () => import('/src/views/user/password/change/ChangePassword.vue'),
                    meta: {
                        title: 'Change Login Password',
                        analytics_view: 'change_login_password_view', // 谷歌分析上传页面的名称
                    },
                },
                {
                    path: 'confirm', // 修改密码 确认密码
                    name: 'ChangeLoginPassword',
                    component: () => import('/src/views/user/password/change/ChangeLoginPassword.vue'),
                    meta: {
                        title: 'Change Login Password',
                        analytics_view: 'change_login_password_confirm_view',
                    }
                }
            ]
        },
        {
            path: '/changeEmail', // 修改邮箱
            name: 'ChangeEmail',
            component: () => import('/src/views/user/email/ChangeEmail.vue'),
            meta: {
                title: 'Reset Email Address',
                analytics_view: 'reset_email_view'
            }
        },
        {
            path: '/setNewEmail', // 设置新邮箱
            name: 'SetNewEmail',
            component: () => import('/src/views/user/email/SetNewEmail.vue'),
            meta: {
                title: 'Set New Email Address',
                analytics_view: 'set_new_email_view'
            }
        },
        {
            path: '/selectCategory',
            name: 'SelectCategory',
            component: () => import('/src/views/avatars/selectCategory/SelectCategory.vue'),
            meta: {
                title: 'Select Category',
                hideHeaderPadding: !IsMobile(),
                isRedirect: true, // 是否允许登录后重新跳转回当前页面
                analytics_view: 'choose_avatar_type_view'
            }
        },
        {
            path: '/selectComponents/:id',
            name: 'SelectComponents',
            component: () => import('/src/views/avatars/selectComponents/SelectComponents.vue'),
            meta: {
                title: 'Select Components',
                isRedirect: true, // 是否允许登录后重新跳转回当前页面
                analytics_view: 'build_avatar_view',
                setBodyBg: MOBILE_BACKGROUND_COLOR
            }
        },
        {
            path: '/wallet',
            name: 'Wallet',
            component: () => import('/src/views/wallet/Wallet.vue'),
            meta: {
                title: 'My Cassava Wallet',
                isRedirect: true, // 是否允许登录后重新跳转回当前页面
                analytics_view: 'credits_view'
            }
        },
        {
            path: '/deleteAccount',
            name: 'DeleteAccount',
            component: () => import('/src/views/user/delete/DeleteAccount.vue'),
            meta: {
                title: 'Delete Account',
                isRedirect: false, // 是否允许登录后重新跳转回当前页面
                analytics_view: 'delete_account_view',
                setBodyBg: MOBILE_BACKGROUND_COLOR
            }
        },
        MY_PROTAL_ROUTES,
        ...APP_PAGE,
        ...ACTIVITIES_PAGE,
    ]
})

// 权限校验
permission(router)

export default router