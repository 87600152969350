import { STATUS_ENDED, STATUS_ONGOING, STATUS_SCHEDULED } from "../Task.text";

export const countInterval = (countDownArr, list) => {
  countDownArr.forEach(item => {
    item.count--
    if (item.count <= 0) { // 修改为 <= 0
      const result = list.find(o => o.quest_id === item.id)
      if (result.status === STATUS_SCHEDULED) {
        result.status = STATUS_ONGOING
        item.count = result.end_time - new Date().getTime() / 1000
      } else {
        result.status === STATUS_ENDED
      }
    }
  });
}