import { encodeURLSchemeParams } from './common';
export const locationAppPage = (pageName: string, params?: any) => {
  let url = appPage[pageName]
  if (!url) return 'Page not found'
  if (params) {
    const transformParams = encodeURLSchemeParams(params)
    url += `?query=${transformParams}`
  }
  return url
}

const appPage = {
  web: 'cassava://web/web',
  home: 'cassava://tabbar/home',
  accountCenter: 'cassava://tabbar/accountCenter',
  myAvatar: 'cassava://native/myAvatar',
  avatarType: 'cassava://native/avatarType',
  signup: 'cassava://native/signup',
  login: 'cassava://native/login',
  mysteryboxList: 'cassava://native/mysteryboxList',
  mysteryboxDetail: 'cassava://native/mysteryboxDetail',
  tasks: 'cassava://tabbar/tasks',
  communities: 'cassava://tabbar/communities',
  communityDetail: 'cassava://native/communityDetail',
  referral: 'cassava://referral/invite',
  referral$1page: 'cassava://native/referral',
  credits:`cassava://tabbar/credits?query=%7B%22needLogin%22:1%7D`,
  earning: `cassava://tabbar/earning`,
  bindWallet: `cassava://native/walletManage?query=%7B%22needLogin%22:1%7D`,
  mintAvatar: `cassava://native/myAvatarList?query=%7B%22needLogin%22:1%7D`,
  main: 'cassava://main/main',
  notificationAuth: 'cassava://event/notificationAuth'
}