import instance from '@/utils/request'
import { type BASE_RESPONSE, type BASE_PAGING_RESPONSE, REQUEST_LIST_BASIC_PARAMS } from './types';
import { IGetListParams } from './taskCenter';
interface GET_COMMUNITIES_PARAMS {
    name_prefix?: string;
    sort_key?: string;
    sort_order?: string;
    offset?: number;
    limit?: number;
    account_id?: number;
}
interface FOLLW_PARAMS {
    id: number;
    cancel: boolean;
}
interface DETAIL_PARAMS {
    id?: number;
    account_id?: number;
    name?: string;
    community_url?: string;
    community_url_path?: string;
}
export interface COMMUNITY_LIST_ITEM {
    community_url: string;
    community_url_path: string;
    followed: boolean;
    follower_count: number;
    follower_thumbnail_urls: string[] | null;
    id: number;
    logo_image_url: string;
    name: string;
    status: string;
    task_count: number;
}
export enum COMMUNITY_STATUS {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected"
}

export interface COMMUNITY_DETAIL_RES {
    id: number,
    status: COMMUNITY_STATUS,
    name: string,
    description: string,
    community_url: string,
    community_url_path: string,
    logo_image_url: string,
    banner_image_url: string,
    email: string,
    website_url: string,
    twitter_url: string,
    discord_url: string,
    telegram_url: string,
    created_at: string,
    follower_count: string,
    task_count: string,
    participant_count: string,
    follower_thumbnails_urls: string,
    followed: boolean,
    social_links: {
        name: string,
        link_url: string,
        app_icon_url: string,
        web_icon_url: string,
    }[],
    status_reason: string
}

export function follow(data: FOLLW_PARAMS) {
    return instance({
        url: 'v1/community/follow',
        method: 'POST',
        data
    })
}
export function getCommunities(data: GET_COMMUNITIES_PARAMS = {}): Promise<BASE_PAGING_RESPONSE<COMMUNITY_LIST_ITEM>> {
    return instance({
        url: 'v1/community/active',
        method: 'GET',
        params: data
    })
}
export function getCommunityDetail(data: DETAIL_PARAMS): Promise<BASE_RESPONSE<COMMUNITY_DETAIL_RES>> {
    return instance({
        url: 'v1/community/detail',
        method: 'GET',
        params: data
    })
}
// 创建社区时检查项目
export interface ICheckValidate {
    name?: string,
    community_url_path?: string
    id?: number
}
export function checkCommunityValidate(data: ICheckValidate) {
    return instance({
        url: 'v1/community/validate',
        method: 'POST',
        data
    })
}

// 创建社区
export interface ICreateCommunity {
    id?: number,
    name: string,
    description?: string,
    logo_image_url: string,
    banner_image_url?: string,
    email: string,
    email_verify_code: string,
    community_url_path: string,
    twitter_url: string,
    discord_url: string,
    telegram_url?: string,
    website_url?: string
}
export function createCommunity(data: ICreateCommunity) {
    return instance({
        url: 'v1/community/create',
        method: 'POST',
        data
    })
}
// 更新社区
export function updateCommunity(data: ICreateCommunity) {
    return instance({
        url: 'v1/community/edit',
        method: 'POST',
        data
    })
}

// 删除社区
export interface IDelete {
    id: number
}
export function deleteCommunity(data: IDelete) {
    return instance({
        url: 'v1/community/delete',
        method: 'POST',
        data
    })
}

// 获取我管理的社区列表
export interface IMineMangement {
    status_filter?: string,
    offset?: number,
    limit?: number
}
export function getMineMangementList(data: IMineMangement) {
    return instance({
        url: 'v1/community/mine',
        method: 'GET',
        params: data
    })
}

// 获取我创建的task
export function getMineTaskList(data: IGetListParams) {
    return instance({
        url: 'v1/my/quests',
        method: 'GET',
        params: data
    })
}

export interface IMyActivitiesData {
    name: string
    rewards: string
    date_time: number
    icon_url: string
    label: string
    id: number
    commutity_id: number
}

// 社区详情下我的活动列表 My Activities
export function getCommunityMyActivities(data: { community_id: string }): Promise<BASE_PAGING_RESPONSE<IMyActivitiesData>> {
    return instance({
        url: 'v1/my/activities',
        method: 'GET',
        params: data
    })
}

// 查询社区管理员
export interface ICommunityAdminItemsData {
    account_id: number
    nick_name: string
    avatar_url: string
    is_owner: boolean
}
export interface IGetCommunityAdminParams extends REQUEST_LIST_BASIC_PARAMS {
    community_id: number
}
export function getCommunityAdmins(data: IGetCommunityAdminParams): Promise<BASE_PAGING_RESPONSE<ICommunityAdminItemsData>> {
    return instance({
        url: 'v1/community/admins',
        method: 'GET',
        params: data
    })
}
// 删除社区管理员
export function deleteCommunityAdmin(data: { community_id: number, account_id: number}): Promise<BASE_RESPONSE<any>> {
    return instance({
        url: 'v1/community/admins/remove',
        method: 'POST',
        data
    })
}
// 添加社区管理员
export function addCommunityAdmin(data: {member: string, community_id: number}): Promise<BASE_RESPONSE<{item: ICommunityAdminItemsData}>> {
    return instance({
        url: 'v1/community/admins/add',
        method: 'POST',
        data
    })
}
// 检查是否有权限访问
export function checkCommunityAdmin(data: {community_id: number}): Promise<BASE_RESPONSE<any>> {
    return instance({
        url: 'v1/community/admins/check',
        method: 'POST',
        data
    })
}