<script lang="ts" setup>
// defineProps<{ seeMoreValue: string }>();
const $emit = defineEmits(['seeMoreClick']);
defineProps<{ showMore?: boolean }>()
function seeMoreClick() {
    seeMoreClick();
}
</script>

<template>
    <div class="homepage_title">
        <img src="@/assets/images/pc/homePage/icon_title.svg" alt="">
        <div class="homepage_title_text">
            <slot v-if="!$slots.title"></slot>
            <slot name="title"></slot>
        </div>
        <div class="homepage_title_fill">
            <slot name="fill"></slot>
        </div>
        <div class="homepage_title_right">
            <div class="title_right" @click="$emit('seeMoreClick')" v-if="showMore">
                See More<span class="iconfont icon-right"></span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.homepage_title {
    display: flex;
    align-items: center;
    gap: 10px;

    .homepage_title_text {
        font-family: 'secular-one';
        font-weight: 700;
        background: linear-gradient(270deg, #FFC78B 0%, #BEFF89 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 28px;
        line-height: 36px;
    }

    .homepage_title_fill {
        flex: 1;
    }

    .homepage_title_right {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        justify-self: flex-end;
    }
}

.title_right {
    color: #fff;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
    user-select: none;

    .icon-right {
        font-size: 12px;
    }
}

@media screen and (max-width: 750px) {
    .homepage_title {
        padding: 0 .426667rem /* 16/37.5 */;
        gap: 6px;
        img {
            width: 19px;
            height: 17px;
        }
        .homepage_title_text {
            font-size: 16px;
            line-height: 20px;
        }
    }
}
</style>