import { Router } from 'vue-router'
import { whiteList } from './basic.router'
import store from '@/store'
import { appRedirectionList, mobileWhiteList } from "./urlWhiteList";
import { storageChannel, storageRouterPath, storageToken, storageTokenEx } from '../utils/storage';
import { getUrlParams, scrollAnimation, IsMobile, isWalletWebview } from '../utils/common';
import { ready } from 'eft-bridge';
import { login } from '@/helper/account';
import { locationAppPage } from '@/utils/appURLScheme';
// import { useGtag } from "vue-gtag-next";
import { event, pageview } from 'vue-gtag';
import { uploadWebviewLoading } from '@/utils/common';
import { MOBILE_BACKGROUND_COLOR } from '@/helper/global.text';

// const { pageview } = useGtag()

async function interceptLogin({ to, from, next }): Promise<boolean> {
    if (to.name === "Login" || to.path === "/login") {
        login();
        return true;
    }
    return false;
}

async function injectAppToken(bridge, isApp) {
    if (isApp) {
        let res = await bridge.call({ "opt": "getUserInfo" })
        if (res?.body?.token) {
            storageToken.set(res.body.token);
            storageTokenEx.set(Date.now() + 1000 * 60 * 60 * 24);
        } else {
            storageToken.remove();
            storageTokenEx.remove();
        }
    }
}

async function interceptMobile({ to, from, next }, { isApp, bridge }) {
    if (IsMobile() && !isApp) {
        // 如果是手机端并且不是app端。则把背景置成黑色
        document.body.style.backgroundColor = MOBILE_BACKGROUND_COLOR
    }
    if (mobileWhiteList.includes(to.name) || to.meta.mobileWhiteList) return;

    if (appRedirectionList.includes(to.name)) {
        sessionStorage.setItem('__fromPath', JSON.stringify(to))
    }

    if (isApp && to.name === 'TaskDetails') {
        next({ name: 'TaskDetailsMobile', params: { id: to.params.id } })
    }
    if (isApp && to.path === '/') {
        bridge.call({ "opt": "closeWebController" });
        next({ name: 'LandingPage' });
    } // else if (!isApp && IsMobile() && import.meta.env.MODE === 'production') next({ name: 'LandingPage' });
}

export const permission = (router: Router) => {
    const loginPath = '/login'
    const defaultPath = '/'

    const params = getUrlParams(window.location.search)
    if (params['token']) {
        storageToken.set(params['token'])
        storageTokenEx.set(params['tokenExpire']);
    }
    if (params['channel']) storageChannel.set(params['channel'])

    router.beforeEach((to, from, next) => {
        // store.commit('globalLoading', true);
        if (IsMobile()) {
            document.body.style.backgroundColor = 'transparent'
            if (to.meta.setBodyBg) {
                document.body.style.backgroundColor = MOBILE_BACKGROUND_COLOR
            }
        }

        if (to.meta.clearLoginStatus) {
            localStorage.clear()
            sessionStorage.clear()
        }

        if (isWalletWebview()) {
            location.href = import.meta.env.VITE_DAPP_WEBSITE_URL
            return
        }

        if (to.name === `MyPortal`) {
            if (!IsMobile()) {
                next({ name: `CommunitiesManagement` })
            }
        }

        ready(async ({ bridge, isApp }) => {
            // # 如果是Login 则是true 否则是false；
            let isLogin = await interceptLogin({ to, from, next });
            // # 如果是app，直接请求token，并存储；
            await injectAppToken(bridge, isApp);
            // # 如果是移动端并且不是app，直接跳转到引导页面;
            await interceptMobile({ to, from, next }, { isApp, bridge });
            !isLogin && origin_logic();
        })

        function origin_logic() {

            const token = storageToken.get()

            //遍历meta改变title
            if (to.meta.title && typeof (to.meta?.title) === 'string') {
                document.title = to.meta.title;
            } else {
                // 如果不传title，默认制空。
                document.title = " ";
            }

            // 获取全局的静态资源管理 json
            store.dispatch('getStaticResourcesMeta')

            if (token) {
                if (to.path === loginPath) {
                    next({ path: defaultPath })
                } else {
                    // 说明 刷新了页面
                    if (!store.state.user.token) {
                        store.commit('user/setToken', [token, storageTokenEx.get()])
                        store.dispatch('user/getUserProfileAction')
                    }
                    next()
                }

            } else {
                const routerPath = router.currentRoute.value.path
                // 是否允许跳转到登录页后Redirect回这个地址
                if (router.currentRoute.value.meta.isRedirect) {
                    storageRouterPath.set(routerPath)
                } else {
                    storageRouterPath.remove()
                }
                if (whiteList.includes(to.name as string) || to.meta.noNeedLogin) {
                    next()
                } else {
                    next({ path: loginPath })
                }
            }
        }
    })

    router.afterEach((to, from, next) => {
        if (!to.meta.ignoreScroll) {
            window.scrollTo(0, 0)
        }
        const url = new URL(window.location.href);
        if (url.searchParams.has('token') || url.searchParams.has('tokenExpire')) {
            url.searchParams.delete('token');
            url.searchParams.delete('tokenExpire');
            window.history.replaceState({}, document.title, url.pathname + url.hash);
        }
        const analytics_view = to.meta.analytics_view || to.name
        if (analytics_view) {
            ready(async ({ bridge, isApp }) => {
                if (to.meta.is_app_analysis && isApp) {
                    await bridge.call({ opt: 'logEvent', body: { eventName: 'screen_view', parameters: { screen_name: analytics_view } } })
                    return
                }
                pageview({
                    page_title: analytics_view as string,
                })
            })
        }
        if (!to.meta.needManuallyCloseLoading) {
            uploadWebviewLoading(0)
        }

        // 如果是答题页面，修改app端导航栏标题
        if (to.path.indexOf('/quiz') > -1) {
            ready(async ({ bridge, isApp }) => {
                if (isApp) {
                    await bridge.call({ opt: "refreshNavigationBar", body: { title: "Quiz to Earn", } })
                    await bridge.call({ opt: "updateWebViewTopPosition",  body: { type: "top", } });
                }
            })
        }
        
        // store.commit('globalLoading', false)
    })
}